import styles from './mIndex.module.scss';
import VideoComponent from '../../components/VideoOnReady';

interface MPageProps {
  handleVideoLoaded: (videoNumber: number, player: any) => void;
}

const MDaoPage: React.FC<MPageProps> = ({ handleVideoLoaded }) => {
  return (
    <>
      <div className="only500">
        <div style={{ marginTop: '90px' }}>
          <VideoComponent
            key={3}
            src="/dao/mDao.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={3}
            className="basic-video-img"
          />
          <div
            style={{
              paddingBottom: '30px',
              borderBottomRightRadius: '30px',
              borderBottomLeftRadius: '30px',
              backgroundColor: 'var(--color-black)',
            }}
          />
        </div>
        <div
          className="basic-text-500"
          style={{
            marginTop: 'calc(50 * var(--vh) - 300px)',
            color: 'var(--color-white)',
          }}
        >
          <div className={styles.dao} style={{ lineHeight: '32px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              DA
            </span>
            <i className={styles.o}>O</i>
          </div>
          <div className={styles.learnAboutThis}>
            Learn about this Coolest govern
          </div>
          <b className={styles.hippocratDaoStrives}>
            Hippocrat DAO strives for a decentralized structure and autonomous
            organizations to foster the ecosystem's expansion.
          </b>
        </div>
      </div>
      <div className="layout500">
        <div className={styles.dao500}>
          <div className={styles.theGovernanceProcessContainer}>
            <span className={styles.theGovernanceProcessContainer1}>
              <p className={styles.theGovernance}>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  THE GOVERNAN
                </span>
                <i style={{ fontFamily: 'var(--font-bodoni-72)' }}>{`CE `}</i>
              </p>
              <p className={styles.process}>
                <i>PROCESS</i>
              </p>
            </span>
          </div>
          <div className={styles.dao500Item} />
          <div className={styles.dao500Inner} />
          <div className={styles.rectangleDiv} />
          <div className={styles.toSecureRegionalContainer}>
            <p className={styles.theGovernance}>
              To secure regional user pools and build ecosystem infrastructure.
            </p>
            <p className={styles.theGovernance}>&nbsp;</p>
          </div>
          <div className={styles.toVerifyTheContainer}>
            <p className={styles.theGovernance}>
              To verify the self-sustainability of ecosystems and address any
              vulnerabilities.
            </p>
            <p className={styles.theGovernance}>&nbsp;</p>
            <p className={styles.theGovernance}>&nbsp;</p>
            <p className={styles.theGovernance}>&nbsp;</p>
          </div>
          <div className={styles.toDisableUsageContainer}>
            <p className={styles.theGovernance}>
              To disable usage of centralized tokens, enforce adjustments in the
              user pool, and enable emergency use of DAO funds.
            </p>
            <p className={styles.theGovernance}>&nbsp;</p>
            <p className={styles.theGovernance}>&nbsp;</p>
          </div>
          <div className={styles.phase1Parent}>
            <b className={styles.phase1}>Phase 1</b>
            <div className={styles.groupChild} />
          </div>
          <div className={styles.phase2Parent}>
            <b className={styles.phase2}>Phase 2</b>
            <div className={styles.groupItem} />
          </div>
          <div className={styles.phase3Parent}>
            <b className={styles.phase2}>Phase 3</b>
            <div className={styles.groupItem} />
          </div>
          <div className={styles.exRecruitingDaoContainer}>
            <ul className={styles.exRecruitingDaoParticipati}>
              <li>
                Ex) Recruiting DAO participation members, establishing
                partnerships and investments for ecosystem infrastructure, and
                developing regional doctor and patient user pools.
              </li>
            </ul>
          </div>
          <div className={styles.exOneYearContainer}>
            <ul className={styles.exRecruitingDaoParticipati}>
              <li>
                Ex) One year following Phase 1, assess if the ecosystem can
                operate voluntarily without intervention from any centralized
                group within the DAO.
              </li>
            </ul>
          </div>
          <div className={styles.exRecruitingDaoContainer1}>
            <ul className={styles.exRecruitingDaoParticipati}>
              <li>
                Ex) Recruiting DAO participation members, establishing
                partnerships and investments for ecosystem infrastructure, and
                developing regional doctor and patient user pools.
              </li>
            </ul>
          </div>
          <div className={styles.asclepiosStance}>Asclepios Stance</div>
          <div className={styles.keironStance}>Keiron Stance</div>
          <div className={styles.hippocratesStance}>Hippocrates Stance</div>
          <div className={styles.dao500Child1} />
          <img
            className={styles.image71Icon}
            alt=""
            src="/dao/image-71@2x.png"
          />
          <img className={styles.vectorIcon} alt="" src="/dao/vector7.png" />
          <img className={styles.dao500Child2} alt="" src="/dao/vector7.png" />
          <img
            className={styles.image70Icon}
            alt=""
            src="/dao/image-70@2x.png"
          />
          <div
            className={styles.treasuryWg}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Treasury WG
          </div>
          <div
            className={styles.dao1}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            DAO
          </div>
          <div
            className={styles.techWg}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Tech WG
          </div>
          <div
            className={styles.policySig}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Policy SIG
          </div>
          <div
            className={styles.appsWg}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Apps WG
          </div>
          <div
            className={styles.compilanceSig}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Compilance SIG
          </div>
          <div className={styles.phase1Group}>
            <b className={styles.phase2}>Phase 1</b>
            <div className={styles.groupItem} />
          </div>
          <div
            className={styles.governanceProtocol}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            Governance Protocol
          </div>
          <div className={styles.dao500Child3}>
            <img
              className="basic-img"
              alt=""
              src="/dao/githubmarkwhite-1@2x.png"
            />
          </div>
          <div className={styles.dao500Child5}>
            <img className="basic-img" alt="" src="/dao/bitcoin-1@2x.png" />
          </div>

          <div
            className={styles.bitcoin}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Bitcoin
          </div>
          <div
            className={styles.github}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            GitHub
          </div>
          <div className={styles.phase2Group}>
            <b className={styles.phase2}>Phase 2</b>
            <div className={styles.groupItem} />
          </div>
          <div
            className={styles.softwareProtocol}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            Software Protocol
          </div>
          <div className={styles.phase3Group}>
            <b className={styles.phase2}>Phase 3</b>
            <div className={styles.groupItem} />
          </div>
          <div
            className={styles.informalDiscussion}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Informal discussion
          </div>
          <div
            className={styles.roughConsensus}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Rough consensus
          </div>
          <div
            className={styles.execute}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Execute
          </div>
        </div>
      </div>
      <div className="only360">
        <div style={{ marginTop: '90px' }}>
          <VideoComponent
            key={4}
            src="/dao/mDao.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={4}
            className="basic-video-img"
          />
          <div
            style={{
              paddingBottom: '30px',
              borderBottomRightRadius: '30px',
              borderBottomLeftRadius: '30px',
              backgroundColor: 'var(--color-black)',
            }}
          />
        </div>
        <div
          className="basic-text-360"
          style={{
            marginTop: 'calc(50 * var(--vh) - 280px)',
            textAlign: 'center',
            color: 'var(--color-white)',
          }}
        >
          <div className={styles.dao} style={{ lineHeight: '25px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              DA
            </span>
            <i className={styles.o}>O</i>
          </div>
          <div className={styles.learnAboutThis}>
            Learn about this Coolest govern
          </div>
          <b className={styles.hippocratDaoStrives}>
            Hippocrat DAO strives for a decentralized structure and autonomous
            organizations to foster the ecosystem's expansion.
          </b>
        </div>
      </div>
      <div className="layout360">
        <div className={styles.dao360}>
          <div className={styles.theGovernanceProcessContainer}>
            <span className={styles.theGovernanceProcessContainer1}>
              <p className={styles.theGovernance}>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  THE GOVERNAN
                </span>
                <i style={{ fontFamily: 'var(--font-bodoni-72)' }}>{`CE `}</i>
              </p>
              <p className={styles.process}>
                <i>PROCESS</i>
              </p>
            </span>
          </div>
          <div className={styles.dao360Item} />
          <div className={styles.dao360Inner} />
          <div className={styles.rectangleDiv} />
          <div className={styles.toSecureRegionalContainer}>
            <p className={styles.theGovernance}>
              To secure regional user pools and build ecosystem infrastructure.
            </p>
            <p className={styles.theGovernance}>&nbsp;</p>
          </div>
          <div className={styles.toVerifyTheContainer}>
            <p className={styles.theGovernance}>
              To verify the self-sustainability of ecosystems and address any
              vulnerabilities.
            </p>
            <p className={styles.theGovernance}>&nbsp;</p>
            <p className={styles.theGovernance}>&nbsp;</p>
            <p className={styles.theGovernance}>&nbsp;</p>
          </div>
          <div className={styles.toDisableUsageContainer}>
            <p className={styles.theGovernance}>
              To disable usage of centralized tokens, enforce adjustments in the
              user pool, and enable emergency use of DAO funds.
            </p>
            <p className={styles.theGovernance}>&nbsp;</p>
            <p className={styles.theGovernance}>&nbsp;</p>
          </div>
          <div className={styles.phase1Parent}>
            <b className={styles.phase1}>Phase 1</b>
            <div className={styles.groupChild} />
          </div>
          <div className={styles.phase1Group}>
            <b className={styles.phase11}>Phase 2</b>
            <div className={styles.groupItem} />
          </div>
          <div className={styles.phase1Container}>
            <b className={styles.phase11}>Phase 3</b>
            <div className={styles.groupItem} />
          </div>
          <div className={styles.exRecruitingDaoContainer}>
            <ul className={styles.exRecruitingDaoParticipati}>
              <li>
                Ex) Recruiting DAO participation members, establishing
                partnerships and investments for ecosystem infrastructure, and
                developing regional doctor and patient user pools.
              </li>
            </ul>
          </div>
          <div className={styles.exOneYearContainer}>
            <ul className={styles.exRecruitingDaoParticipati}>
              <li>
                Ex) One year following Phase 1, assess if the ecosystem can
                operate voluntarily without intervention from any centralized
                group within the DAO.
              </li>
            </ul>
          </div>
          <div className={styles.exRecruitingDaoContainer1}>
            <ul className={styles.exRecruitingDaoParticipati}>
              <li>
                Ex) Recruiting DAO participation members, establishing
                partnerships and investments for ecosystem infrastructure, and
                developing regional doctor and patient user pools.
              </li>
            </ul>
          </div>
          <div className={styles.asclepiosStance}>Asclepios Stance</div>
          <div className={styles.keironStance}>Keiron Stance</div>
          <div className={styles.hippocratesStance}>Hippocrates Stance</div>
          <div className={styles.dao360Child1} />
          <img
            className={styles.editdaoIcon}
            alt=""
            src="/dao/image-73@2x.png"
          />
          <img
            className={styles.editdaoIcon1}
            alt=""
            src="/dao/image-72@2x.png"
          />
          <img className={styles.vectorIcon} alt="" src="/dao/vector7.png" />
          <img className={styles.dao360Child2} alt="" src="/dao/vector7.png" />
          <div
            className={styles.treasuryWg}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Treasury WG
          </div>
          <div
            className={styles.dao1}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            DAO
          </div>
          <div
            className={styles.techWg}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Tech WG
          </div>
          <div
            className={styles.policySig}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Policy SIG
          </div>
          <div
            className={styles.appsWg}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Apps WG
          </div>
          <div
            className={styles.compilanceSig}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Compilance SIG
          </div>
          <div className={styles.groupDiv}>
            <b className={styles.phase11}>Phase 1</b>
            <div className={styles.groupItem} />
          </div>
          <div
            className={styles.governanceProtocol}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            Governance Protocol
          </div>

          <div className={styles.phase2Parent}>
            <b className={styles.phase11}>Phase 2</b>
            <div className={styles.groupItem} />
          </div>
          <div
            className={styles.softwareProtocol}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            Software Protocol
          </div>
          <div className={styles.phase3Parent}>
            <b className={styles.phase11}>Phase 3</b>
            <div className={styles.groupItem} />
          </div>
          <div
            className={styles.informalDiscussion}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Informal discussion
          </div>
          <div
            className={styles.roughConsensus}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Rough consensus
          </div>
          <div
            className={styles.execute}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Execute
          </div>
          <div className={styles.bitcoinWrapper}>
            <div
              className={styles.bitcoin}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              Bitcoin
            </div>
            <div
              className={styles.github}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              GitHub
            </div>
            <img
              className={styles.githubMarkWhite2Icon}
              alt=""
              src="/dao/githubmarkwhite-1@2x.png"
            />
            <img
              className={styles.bitcoin2Icon}
              alt=""
              src="/dao/bitcoin-1@2x.png"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MDaoPage;
