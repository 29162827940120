import React, { useState, useRef, useEffect } from 'react';
import styles from './roadMap.module.scss';
import MRoadMapPage from './mIndex';
import LoadingAnimation from '../../components/LoadingAnimation';
import VideoComponent from '../../components/VideoOnReady';

interface RoadMap {
  title?: string;
  year?: string;
  quarter?: string;
  subTitle?: string[];
}
const RoadMapPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const totalVideos = 4;
  const [loadedVideos, setLoadedVideos] = useState<number[]>([]);
  const playerRefs = useRef<(any | undefined)[]>(new Array(totalVideos));

  const handleVideoLoaded = (videoNumber: number, player: any) => {
    setLoadedVideos(prev => {
      const updatedLoadedVideos = new Set(prev);
      updatedLoadedVideos.add(videoNumber);

      playerRefs.current[videoNumber - 1] = player;

      if (updatedLoadedVideos.size === totalVideos) {
        setIsLoading(false);
        playerRefs.current.forEach(p => p?.play());
      }

      return Array.from(updatedLoadedVideos);
    });
  };

  const roadMapData: RoadMap[] = [
    {
      title: 'Hippocrat Wallet SDK open-source draft released.',
      year: '2023',
      quarter: '2Q',
    },
    {
      title:
        'Proof of Concept (PoC) project using Hippocrat Wallet SDK released.',
      year: '2023',
      quarter: '2Q',
    },
    {
      title: 'Hard fork from HUM to HPO and token distribution initiated.',
      year: '2023',
      quarter: '2Q',
    },
    {
      title: 'Launch of new Hippocrat DAO operating system.',
      year: '2023',
      quarter: '2Q',
    },
    {
      title: 'Beta version of Hippocrat Wallet SDK released.',
      year: '2023',
      quarter: '3Q',
    },
    {
      title: 'Applications using Hippocrat Wallet SDK released.',
      year: '2023',
      quarter: '3Q',
    },
    {
      title: 'Release of Lightning Network implementation.',
      year: '2023',
      quarter: '3Q',
    },
    {
      title: 'Hippocrat DAO Conference took place.',
      year: '2023',
      quarter: '4Q',
    },
    {
      title: 'Hippocrat Wallet integration reference service released.',
      year: '2023',
      quarter: '4Q',
    },
    {
      title:
        'PoC project results for Hippocrat Wallet utilization released, which includes:',
      year: '2023',
      quarter: '4Q',
      subTitle: [
        'Patient to hospital identity registration.',
        'Hospital to patient data issuance.',
        'Patient sharing data with data-using organizations.',
      ],
    },
    {
      title:
        'Deployment of standard policy and contract testnet for data transactions.',
      year: '2024',
      quarter: '1Q',
    },
    {
      title: 'Organization of a hackathon program to activate the ecosystem.',
      year: '2024',
      quarter: '1Q',
    },
    {
      title:
        'Disclosure of PoC project plan using Hippocrat Wallet SDK, in collaboration with global institutions.',
      year: '2024',
      quarter: '1Q',
    },
    {
      title: 'Release of Hippocrat Wallet SDK v1.',
      year: '2024',
      quarter: '2Q',
    },
    {
      title:
        'Deployment of standard policy and contract mainnet for data transactions.',
      year: '2024',
      quarter: '2Q',
    },
    {
      title: 'Launch of Hippocrat DAO global expansion event.',
      year: '2024',
      quarter: '3Q',
    },
    {
      title: 'Implementation of a grant program to stimulate the ecosystem.',
      year: '2024',
      quarter: '3Q',
    },
    {
      title: 'Onboarding of 5 services integrated with Hippocrat Wallet SDK.',
      year: '2024',
      quarter: '3Q',
    },
    {
      title:
        'Onboarding of 10 organizations integrated with Hippocrat Wallet SDK.',
      year: '2024',
      quarter: '3Q',
    },
    {
      title: 'Hippocrat DAO Global Conference held.',
      year: '2024',
      quarter: '4Q',
    },
    {
      title:
        'Stabilization of the decentralized autonomous operating system of all Special Interest Groups and Working Groups in the DAO.',
      year: '2024',
      quarter: '4Q',
    },
    {
      title: 'Transfer of all authority from Hippocrat Foundation to DAO.',
      year: '2024',
      quarter: '4Q',
    },
    {
      title: 'Onboarding of 10 Hippocrat Wallet integrations.',
      year: '2024',
      quarter: '4Q',
    },
    {
      title:
        'Onboarding of 20 Hippocrat Wallet integrations with organizations.',
      year: '2024',
      quarter: '4Q',
    },
    {
      title:
        'Release of PoC project deliverables for Hippocrat Wallet utilization, in collaboration with global institutions.',
      year: '2024',
      quarter: '4Q',
    },
  ];

  const scrollRef1 = useRef<HTMLDivElement>(null);
  const scrollRef2 = useRef<HTMLDivElement>(null);
  let isDown = false;
  let startX: number;
  let scrollLeft: number;

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    let el: HTMLDivElement | null = null;

    if (e.currentTarget === scrollRef1.current) {
      el = scrollRef1.current;
    } else if (e.currentTarget === scrollRef2.current) {
      el = scrollRef2.current;
    }

    if (el) {
      isDown = true;
      startX = e.pageX - el.offsetLeft;
      scrollLeft = el.scrollLeft;
      el.style.cursor = 'grabbing';
    }
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    isDown = false;
    let el: HTMLDivElement | null = null;

    if (e.currentTarget === scrollRef1.current) {
      el = scrollRef1.current;
    } else if (e.currentTarget === scrollRef2.current) {
      el = scrollRef2.current;
    }
    if (el) {
      el.style.cursor = 'grab';
    }
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    isDown = false;
    let el: HTMLDivElement | null = null;

    if (e.currentTarget === scrollRef1.current) {
      el = scrollRef1.current;
    } else if (e.currentTarget === scrollRef2.current) {
      el = scrollRef2.current;
    }
    if (el) {
      el.style.cursor = 'grab';
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDown) return;
    e.preventDefault();
    let el: HTMLDivElement | null = null;

    if (e.currentTarget === scrollRef1.current) {
      el = scrollRef1.current;
    } else if (e.currentTarget === scrollRef2.current) {
      el = scrollRef2.current;
    }
    if (el) {
      const x = e.pageX - el.offsetLeft;
      const walk = (x - startX) * 1; // 스크롤 속도 조절
      el.scrollLeft = scrollLeft - walk;
    }
  };

  return (
    <section className="layoutContainer">
      <LoadingAnimation isLoading={isLoading} />
      <div className="only1000">
        <div>
          <VideoComponent
            key={1}
            src="/roadMap/roadMap.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={1}
            className="basic-video-img"
          />
        </div>

        <div
          className="basic-text-1000"
          style={{
            marginTop: 'calc(50 * var(--vh) - 350px)',
          }}
        >
          <div className={styles.ourPioneerJourney}>Our Pioneer Journey</div>
          <div className={styles.roadMap}>
            <span>
              <span className={styles.r}>R</span>
              <span className={styles.o}>O</span>
            </span>
            <span className={styles.r}>
              <i className={styles.ad}>AD</i>
              <span> MAP</span>
            </span>
          </div>
          <b className={styles.joinTheJourneyContainer}>
            <p className={styles.joinTheJourney}>
              Join the journey of healthcare revolution with Hippocrat
            </p>
          </b>
          <div className={styles.theJourneyOf}>
            The journey of Hippocrat never concludes until we rationally address
            the challenges of conventional healthcare. We will consistently
            pioneer a patient-centric approach, propose solutions, and work
            towards making the ecosystem transparent and secure. While the
            journey is ongoing, let's see at which stage we are currently
            leading innovation together with you.
          </div>
        </div>
      </div>
      <div className="layout1000">
        <div className={styles.roadmap}>
          <div
            ref={scrollRef1}
            className={styles.roadMapScroll}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            {/* roadMapData */}
            {roadMapData.map((map, i) => (
              <div className={styles.roadmapWrapper} key={i}>
                <img src="/roadMap/mapBg.png" alt="" />
                <div>{map?.year}</div>
                <b>{map?.quarter}</b>
                <p>{map?.title}</p>
                {map?.subTitle?.map((sub, j) => <li key={j}>{sub}</li>)}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="only800">
        <div>
          <VideoComponent
            key={2}
            src="/roadMap/roadMap.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={2}
            className="basic-video-img"
          />
        </div>
        <div
          className="basic-text-800"
          style={{
            marginTop: 'calc(50 * var(--vh) - 350px)',
          }}
        >
          <div className={styles.ourPioneerJourney}>Our Pioneer Journey</div>
          <div className={styles.roadMap} style={{ lineHeight: '42px' }}>
            <span>
              <span
                className={styles.r}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                R
              </span>
              <span
                className={styles.o}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                O
              </span>
            </span>
            <span className={styles.r}>
              <i className={styles.ad}>AD</i>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                {' '}
                MAP
              </span>
            </span>
          </div>
          <div
            className={styles.theJourneyOf}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            The journey of Hippocrat never concludes until we rationally address
            the challenges of conventional healthcare. We will consistently
            pioneer a patient-centric approach, propose solutions, and work
            towards making the ecosystem transparent and secure. While the
            journey is ongoing, let's examine the stage at which we are
            currently spearheading innovation, together with you.
          </div>
          <b className={styles.joinTheJourneyContainer}>
            <p className={styles.joinTheJourney}>
              Join the journey of healthcare revolution with Hippocrat
            </p>
          </b>
        </div>
      </div>
      <div className="layout800">
        <div className={styles.roadmap800}>
          <div
            ref={scrollRef2}
            className={styles.roadMapScroll}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            {/* roadMapData */}
            {roadMapData.map((map, i) => (
              <div className={styles.roadmapWrapper} key={i}>
                <img src="/roadMap/mapBg.png" alt="" />
                <div>{map?.year}</div>
                <b>{map?.quarter}</b>
                <p>{map?.title}</p>
                {map?.subTitle?.map((sub, j) => <li key={j}>{sub}</li>)}
              </div>
            ))}
          </div>
        </div>
      </div>
      <MRoadMapPage handleVideoLoaded={handleVideoLoaded} />
    </section>
  );
};

export default RoadMapPage;
