import styles from './mIndex.module.scss';
import VideoComponent from '../../components/VideoOnReady';

interface MPageProps {
  handleVideoLoaded: (videoNumber: number, player: any) => void;
}

const MAboutUsPage: React.FC<MPageProps> = ({ handleVideoLoaded }) => {
  return (
    <>
      <div className="only500">
        <div className={styles.about500Child} />

        <div className={styles.about1000Child2}>
          <video
            width="100%"
            preload="metadata"
            muted
            loop
            playsInline
            autoPlay
          >
            <source src="/aboutUs/crat.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="layout500">
        <div className={styles.about500}>
          <div className={styles.aboutUsVideo}>
            <VideoComponent
              key={3}
              src="/aboutUs/mWearenot.mp4"
              onReady={(videoNumber, player) =>
                handleVideoLoaded(videoNumber, player)
              }
              videoNumber={3}
              className="basic-img-width-100"
            />
          </div>
          <div style={{ marginTop: '100px', position: 'relative' }}>
            <div className={styles.about1000Child1}>
              <video
                width="100%"
                preload="metadata"
                muted
                loop
                playsInline
                autoPlay
              >
                <source src="/aboutUs/hippo.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className={styles.aboutHippo}>
              <span className={styles.aboutHippoTxtContainer}>
                <span className={styles.weAreA}>
                  <span
                    style={{
                      fontWeight: 'normal',
                      fontFamily: 'var(--font-geomanist-ultra)',
                    }}
                    className={styles.weAreAHippoCh}
                  >
                    A
                  </span>
                  <span
                    style={{
                      fontWeight: 'normal',
                      fontFamily: 'var(--font-geomanist-ultra)',
                    }}
                    className={styles.b}
                  >
                    B
                  </span>
                </span>
                <i>
                  <span className={styles.o1}>O</span>
                </i>
                <span className={styles.weAreAHippoCh}>
                  <i>{`UT  `}</i>
                  <span
                    style={{
                      fontWeight: 'normal',
                      fontFamily: 'var(--font-geomanist-ultra)',
                    }}
                    className={styles.weAreA}
                  >
                    HIP
                  </span>
                </span>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                  className={styles.weAreA}
                >
                  <span className={styles.p1}>P</span>
                </span>
                <i className={styles.weAreAHippoCh}>O</i>
              </span>
            </div>
            <img className={styles.icon} alt="" src="/aboutUs/--2@2x.png" />
            <img className={styles.icon1} alt="" src="/aboutUs/--1@2x.png" />
            <div className={styles.about500Item} />
            <div className={styles.didYouKnowContainer}>
              <p
                className={styles.weAreNot}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                Did you know?
              </p>
              <p
                className={styles.weAreNot}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                The story of the hippo and the hippo bird
              </p>
            </div>
            <div
              className={styles.hippocratsIdentityDraws}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              Hippocrat's identity draws inspiration from the symbiotic bond
              between a hippopotamus, aptly named 'Hippo,' and its bird
              companion, 'Crat.' This relationship mirrors the dynamic in
              healthcare, where professionals 'Crat' forge deep connections with
              their patients 'Hippo', much like the way a bird aids a hippo by
              removing irritants. This analogy beautifully encapsulates
              Hippocrat's commitment to patient-centric care, embodying this
              principle with a refreshing warmth and a deeper, more meaningful
              sentiment.
            </div>
            <div className={styles.theSpecialHippoContainer}>
              <p className={styles.weAreNot}>
                <b>
                  The special hippo, equipped with decentralized blockchain
                  technology and data sovereignty, symbolizes a revolutionary
                  ambition to address inefficiencies in traditional healthcare
                  systems.
                </b>
                <span className={styles.weAreA}></span>
              </p>
              <p
                className={styles.weAreNot}
                style={{ fontFamily: 'var(--font-inter)' }}
              >
                <br />
                In pursuit of our vision and mission for healthcare innovation,
                we are committed to offering personalized healthcare accessible
                to anyone, anywhere, at any time.
              </p>
            </div>
            <div className={styles.about500Inner} />
            <div
              className={styles.toRestoreIndividuals}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              To restore individuals’ healthcare data sovereignty and reform
              inefficient healthcare systems through a decentralized data
              ecosystem, ultimately harnessing health data management and
              utilization to contribute to personalized healthcare and raise the
              global standard.
            </div>
            <div className={styles.mission}>
              <span
                className={styles.weAreAHippoCh}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                MISS
              </span>
              <i className={styles.ion}>ION</i>
            </div>
            <div className={styles.rectangleDiv} />
            <div
              className={styles.toForgeA}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              To forge a future where personalized healthcare is accessible to
              anyone, anywhere at anytime
            </div>
            <div className={styles.vision}>
              <span
                className={styles.weAreAHippoCh}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                VIS
              </span>
              <i className={styles.ion}>ION</i>
            </div>
            <div className={styles.about500Child1} />
            <div
              className={styles.patientCentricEquityTrans}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              Patient-Centric, Equity, Transparency, Progressive, Challenging,
              Reasonable
            </div>
            <div className={styles.coreValues}>
              <span
                className={styles.weAreAHippoCh}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`CORE `}</span>
              <i className={styles.ion}>VALUES</i>
            </div>
            <b className={styles.wePrioritizePatients}>
              We prioritize patients with transparency and fairness, utilizing a
              decentralized DAO for decision-making aligned with mutual
              ecosystem interests.
            </b>
          </div>
        </div>
      </div>
      <div className="only360">
        <div className={styles.about360Child} />
        <div className={styles.about360Child3}>
          <video
            width="100%"
            preload="metadata"
            muted
            loop
            playsInline
            autoPlay
          >
            <source src="/aboutUs/crat.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="layout360">
        <div className={styles.about360}>
          <div className={styles.aboutUsVideo}>
            <VideoComponent
              key={4}
              src="/aboutUs/mWearenot.mp4"
              onReady={(videoNumber, player) =>
                handleVideoLoaded(videoNumber, player)
              }
              videoNumber={4}
              className="basic-img-width-100"
            />
          </div>
          <div style={{ marginTop: '-100px', position: 'relative' }}>
            <div className={styles.about360Child2}>
              <video
                width="100%"
                preload="metadata"
                muted
                loop
                playsInline
                autoPlay
              >
                <source src="/aboutUs/hippo.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className={styles.aboutHippo}>
              <span className={styles.aboutHippoTxtContainer}>
                <span className={styles.weAreA}>
                  <span
                    className={styles.weAreAHippoCh}
                    style={{
                      fontWeight: 'normal',
                      fontFamily: 'var(--font-geomanist-ultra)',
                    }}
                  >
                    A
                  </span>
                  <span
                    className={styles.b}
                    style={{
                      fontWeight: 'normal',
                      fontFamily: 'var(--font-geomanist-ultra)',
                    }}
                  >
                    B
                  </span>
                </span>
                <i>
                  <span className={styles.o1}>O</span>
                </i>
                <span className={styles.weAreAHippoCh}>
                  <i>{`UT  `}</i>
                  <span
                    className={styles.weAreA}
                    style={{
                      fontWeight: 'normal',
                      fontFamily: 'var(--font-geomanist-ultra)',
                    }}
                  >
                    HIP
                  </span>
                </span>
                <span className={styles.weAreA}>
                  <span
                    style={{
                      fontWeight: 'normal',
                      fontFamily: 'var(--font-geomanist-ultra)',
                    }}
                    className={styles.p1}
                  >
                    P
                  </span>
                </span>
                <i className={styles.weAreAHippoCh}>O</i>
              </span>
            </div>
            <img className={styles.icon} alt="" src="/aboutUs/--2@2x.png" />
            <img className={styles.icon1} alt="" src="/aboutUs/--1@2x.png" />
            <div className={styles.about360Item} />
            <div className={styles.didYouKnowContainer}>
              <p
                className={styles.weAreNot}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                Did you know?
              </p>
              <p
                className={styles.weAreNot}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                The story of the hippo and the hippo bird
              </p>
            </div>
            <div
              className={styles.hippocratsIdentityDraws}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              Hippocrat's identity draws inspiration from the symbiotic bond
              between a hippopotamus, aptly named 'Hippo,' and its bird
              companion, 'Crat.' This relationship mirrors the dynamic in
              healthcare, where professionals 'Crat' forge deep connections with
              their patients 'Hippo', much like the way a bird aids a hippo by
              removing irritants. This analogy beautifully encapsulates
              Hippocrat's commitment to patient-centric care, embodying this
              principle with a refreshing warmth and a deeper, more meaningful
              sentiment.
            </div>
            <div className={styles.theSpecialHippoContainer}>
              <p className={styles.weAreNot}>
                <b>
                  The special hippo, equipped with decentralized blockchain
                  technology and data sovereignty, symbolizes a revolutionary
                  ambition to address inefficiencies in traditional healthcare
                  systems.
                </b>
                <span className={styles.weAreA}></span>
              </p>
              <p
                className={styles.weAreNot}
                style={{ fontFamily: 'var(--font-inter)' }}
              >
                <br />
                In pursuit of our vision and mission for healthcare innovation,
                we are committed to offering personalized healthcare accessible
                to anyone, anywhere, at any time.
              </p>
            </div>
            <div className={styles.about360Inner} />
            <div
              className={styles.toRestoreIndividuals}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              To restore individuals’ healthcare data sovereignty and reform
              inefficient healthcare systems through a decentralized data
              ecosystem, ultimately harnessing health data management and
              utilization to contribute to personalized healthcare and raise the
              global standard.
            </div>
            <div className={styles.mission}>
              <span
                className={styles.weAreAHippoCh}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                MISS
              </span>
              <i className={styles.ion}>ION</i>
            </div>
            <div className={styles.rectangleDiv} />
            <div
              className={styles.toForgeA}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              To forge a future where personalized healthcare is accessible to
              anyone, anywhere at anytime
            </div>
            <div className={styles.vision}>
              <span
                className={styles.weAreAHippoCh}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                VIS
              </span>
              <i className={styles.ion}>ION</i>
            </div>
            <div className={styles.about360Child1} />
            <div
              className={styles.patientCentricEquityTrans}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              Patient-Centric, Equity, Transparency, Progressive, Challenging,
              Reasonable
            </div>
            <div className={styles.coreValues}>
              <span
                className={styles.weAreAHippoCh}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`CORE `}</span>
              <i className={styles.ion}>VALUES</i>
            </div>
            <b className={styles.wePrioritizePatients}>
              We prioritize patients with transparency and fairness, utilizing a
              decentralized DAO for decision-making aligned with mutual
              ecosystem interests.
            </b>
          </div>
        </div>
      </div>
    </>
  );
};

export default MAboutUsPage;
