import styles from './mIndex.module.scss';

const MLearnp2pPage = () => {
  return (
    <>
      <div className="only500">
        <div className={styles.learn500Child} />
      </div>
      <div className="layout500">
        <div className={styles.learn500}>
          <div
            className={styles.hippocratIsNot}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Hippocrat is not the owner of the protocol. Hippocrat is a provider
            of protocol software. To achieve healthcare data protocol, Hippocrat
            is building a protocol in its design where every participant owns
            self-sovereignty, not other’s sovereignty, and Hippocrat won’t have
            any more right or power than any other participant. In this way,
            protocol can be purely peer-to-peer, which eventually achieves
            self-sovereignty.
          </div>
          <div
            className={styles.theExistenceOf}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            The existence of the third trusted party inevitably harms the
            self-sovereignty as intermediary intercepts data to be transmitted,
            thus can be censored and manipulated. To achieve self-sovereignty
            and web3 healthcare experience, it’s necessary to build P2P protocol
            to exchange data and token, which won’t ever censored or
            manipulated. P2P protocol is a software program built upon
            pre-defined rules, which means total neutrality, so there isn’t any
            room for external interception or attack made by human.
          </div>
          <b className={styles.hippocratWithP2p}>Hippocrat with P2P Protocol</b>
          <b className={styles.whyP2pProtocol}>Why P2P Protocol matter?</b>
          <div
            className={styles.protocolIsPreDefined}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Protocol is pre-defined promise to communicate from PC to PC. P2P
            protocol is targeting protocol not relying on the third trusted
            party - like Amazon, Meta or Google - but user directly.
          </div>
          <b className={styles.definitionOfP2p}>Definition of P2P Protocol</b>
          <div className={styles.p2pProtocol} style={{ lineHeight: '32px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`P2P `}</span>
            <i className={styles.protocol}>Protocol</i>
          </div>
          <div className={styles.theComingFuture}>The coming future</div>
          <div className={styles.learn500Item} />
          <div className={styles.learn500Inner} />
          <a href="/learn/hl7">
            <div className={styles.previousUseCaseContainer}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                PREV
              </span>
              <b className={styles.protocol}>{`IOUS `}</b>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                USE
              </span>
              <i className={styles.protocol}> CASE</i>
            </div>
            <b className={styles.healthLevel7}>Health level 7 : HL7</b>
            <img
              className={styles.chevronLeftIcon}
              alt=""
              src="/learn/chevron-left.png"
            />
          </a>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/learn/mp2p.png"
            style={{ pointerEvents: 'none' }}
          />
        </div>
      </div>
      <div className="only360">
        <div className={styles.learn360Child} />
      </div>
      <div className="layout360">
        <div className={styles.learn360}>
          <div className={styles.hippocratIsNot}>
            Hippocrat is not the owner of the protocol. Hippocrat is a provider
            of protocol software. To achieve healthcare data protocol, Hippocrat
            is building a protocol in its design where every participant owns
            self-sovereignty, not other’s sovereignty, and Hippocrat won’t have
            any more right or power than any other participant. In this way,
            protocol can be purely peer-to-peer, which eventually achieves
            self-sovereignty.
          </div>
          <div className={styles.theExistenceOf}>
            The existence of the third trusted party inevitably harms the
            self-sovereignty as intermediary intercepts data to be transmitted,
            thus can be censored and manipulated. To achieve self-sovereignty
            and web3 healthcare experience, it’s necessary to build P2P protocol
            to exchange data and token, which won’t ever censored or
            manipulated. P2P protocol is a software program built upon
            pre-defined rules, which means total neutrality, so there isn’t any
            room for external interception or attack made by human.
          </div>
          <b className={styles.hippocratWithP2p}>Hippocrat with P2P Protocol</b>
          <b className={styles.whyP2pProtocol}>Why P2P Protocol matter?</b>

          <div className={styles.protocolIsPreDefined}>
            Protocol is pre-defined promise to communicate from PC to PC. P2P
            protocol is targeting protocol not relying on the third trusted
            party - like Amazon, Meta or Google - but user directly.
          </div>
          <b className={styles.definitionOfP2p}>Definition of P2P Protocol</b>
          <div className={styles.p2pProtocol} style={{ lineHeight: '25px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`P2P `}</span>
            <i className={styles.protocol}>Protocol</i>
          </div>
          <div className={styles.theComingFuture}>The coming future</div>
          <div className={styles.learn360Item} />
          <a href="/learn/hl7">
            <div className={styles.previousUseCaseContainer}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                PREV
              </span>
              <b className={styles.protocol}>{`IOUS `}</b>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                USE
              </span>
              <i className={styles.protocol}> CASE</i>
            </div>
            <b className={styles.healthLevel7}>Health level 7 : HL7</b>
            <img
              className={styles.chevronLeftIcon}
              alt=""
              src="/learn/chevron-left.png"
            />
          </a>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/learn/mp2p.png"
            style={{ pointerEvents: 'none' }}
          />
        </div>
      </div>
    </>
  );
};

export default MLearnp2pPage;
