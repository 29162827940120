import styles from './mediaKit.module.scss';
import MMediaKitPage from './mIndex';

const MediaKitPage = () => {
  return (
    <section className="layoutContainer">
      <div className="only1000">
        <div className={styles.mediaKitChild} />
      </div>
      <div className="layout1000">
        <div className={styles.mediaKit}>
          <div className={styles.pressKit} style={{ lineHeight: '48px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`PRESS `}</span>
            <i className={styles.kit}>KIT</i>
          </div>
          <div
            className={styles.allYourMedia}
          >{`ALL yOUR MEDIA & PRESS NEEDS`}</div>
          <b className={styles.signitureLogo}>Signiture Logo</b>
          <div className={styles.aLogoCanContainer}>
            <p className={styles.aLogoCan}>
              A logo can be used as both a symbol and a signature, and it should
              be used without variations in its form, color, style, or
              combination with other graphic elements for consistent
              communication.
            </p>
          </div>
          <div
            className={styles.brandIdentity}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            BRAND IDENTITY
          </div>
          <div className={styles.mediaKitItem} />
          <div className={styles.mediaKitInner} />

          <div className={styles.rectangleDiv} />
          <div className={styles.mediaKitChild1} />
          <div className={styles.mediaKitChild2} />
          <b className={styles.monoLogo}>Mono Logo</b>
          <b className={styles.characterSymbolLogo}>Character Symbol Logo</b>
          <div className={styles.itCanBe}>
            It can be used in black and white, and for consistent communication,
            it should be used as is, without variations in its form, color,
            style, or combination with other graphic elements.
          </div>
          <div className={styles.aLogoCanContainer1}>
            <p className={styles.aLogoCan}>
              A logo can be used as both a symbol and a signature, and it should
              be used without variations in its form, color, style, or
              combination with other graphic elements for consistent
              communication.
            </p>
          </div>
          <div
            className={styles.download}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/signitureLogo.png'}
              download={'hippocrat_signiture_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.download1}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/monoLogo.png'}
              download={'hippocrat_mono_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.download2}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/characterLogo.png'}
              download={'hippocrat_symbol_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.download7}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
              color: 'white',
            }}
          >
            <a
              href={'/pressKit/monoLogoWhite.png'}
              download={'hippocrat_mono_logo_white.png'}
              style={{ color: 'white' }}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.download4}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/characterSymbolLogo.png'}
              download={'hippocrat_character_symbol_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.brandGuideBook}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            BRAND GUIDE BOOK
          </div>
          <div className={styles.ifYouWant}>
            If you want to check detailed information such as logos, characters,
            typography, etc., please download the brand guidebook. The brand
            guidebook will be continuously updated.
          </div>
          <img
            className={styles.icon}
            alt=""
            src="/pressKit/monoLogoWhite.png"
          />
          <img
            className={styles.icon1}
            alt=""
            src="/pressKit/characterSymbolLogo.png"
          />
          <img
            className={styles.icon2}
            alt=""
            src="/pressKit/characterLogo.png"
          />
          <img
            className={styles.icon3}
            alt=""
            src="/pressKit/signitureLogo.png"
          />
          <img className={styles.icon4} alt="" src="/pressKit/monoLogo.png" />
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/pressKit/brand.png"
          />
          <div
            className={styles.download9}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/Hippocrat_brand_guidebook_FIN.pdf'}
              download={'Hippocrat_brand_guidebook_FIN.pdf'}
            >
              DOWNLOAD
            </a>
          </div>
        </div>
      </div>
      <div className="only800">
        <div className={styles.presskit800Child} />
      </div>
      <div className="layout800">
        <div className={styles.presskit800}>
          <div className={styles.pressKit} style={{ lineHeight: '42px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`PRESS `}</span>
            <i className={styles.kit}>KIT</i>
          </div>
          <div
            className={styles.allYourMedia}
          >{`ALL yOUR MEDIA & PRESS NEEDS`}</div>
          <b className={styles.signitureLogo}>Signiture Logo</b>
          <div className={styles.aLogoCanContainer}>
            <p className={styles.aLogoCan}>
              A logo can be used as both a symbol and a signature, and it should
              be used without variations in its form, color, style, or
              combination with other graphic elements for consistent
              communication.
            </p>
          </div>
          <div
            className={styles.brandIdentity}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            BRAND IDENTITY
          </div>
          <div className={styles.presskit800Item} />
          <div className={styles.presskit800Inner} />
          <div className={styles.rectangleDiv} />
          <div className={styles.presskit800Child1} />

          <div
            className={styles.download}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/signitureLogo.png'}
              download={'hippocrat_signiture_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.download1}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/monoLogo.png'}
              download={'hippocrat_mono_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.download2}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/characterLogo.png'}
              download={'hippocrat_symbol_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.download3}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
              color: 'white',
            }}
          >
            <a
              href={'/pressKit/monoLogoWhite.png'}
              download={'hippocrat_mono_logo_white.png'}
              style={{ color: 'white' }}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.download4}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/characterSymbolLogo.png'}
              download={'hippocrat_character_symbol_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>

          <b className={styles.monoLogo}>Mono Logo</b>
          <b className={styles.characterLogo}>Character Logo</b>
          <div className={styles.itCanBe}>
            It can be used in black and white, and for consistent communication,
            it should be used as is, without variations in its form, color,
            style, or combination with other graphic elements.
          </div>
          <div className={styles.aLogoCanContainer1}>
            <p className={styles.aLogoCan}>
              A logo can be used as both a symbol and a signature, and it should
              be used without variations in its form, color, style, or
              combination with other graphic elements for consistent
              communication.
            </p>
          </div>
          <img
            className={styles.groupIcon}
            alt=""
            src="/pressKit/signitureLogo.png"
          />
          <img
            className={styles.presskit800Child2}
            alt=""
            src="/pressKit/monoLogo.png"
          />
          <img
            className={styles.icon}
            alt=""
            src="/pressKit/monoLogoWhite.png"
          />
          <img
            className={styles.presskit800Child3}
            alt=""
            src="/pressKit/characterLogo.png"
          />
          <img
            className={styles.presskit800Child4}
            alt=""
            src="/pressKit/characterSymbolLogo.png"
          />

          <div className={styles.brandguidebook}>BRAND GUIDE BOOK</div>
          <p className={styles.brandguidebooktext}>
            If you want to check detailed information such as logos, characters,
            typography, etc., please download the brand guidebook. The brand
            guidebook will be continuously updated.
          </p>
          <img
            className={styles.brandguidebookimg}
            alt=""
            src="/pressKit/brand.png"
          />
          <div
            className={styles.download5}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/Hippocrat_brand_guidebook_FIN.pdf'}
              download={'Hippocrat_brand_guidebook_FIN.pdf'}
            >
              DOWNLOAD
            </a>
          </div>
        </div>
      </div>
      <MMediaKitPage />
    </section>
  );
};

export default MediaKitPage;
