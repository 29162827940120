import React, { useState, useRef, useEffect } from 'react';
import styles from './hippo.module.scss';
import MHippoPage from './mIndex';
import LoadingAnimation from '../../components/LoadingAnimation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import VideoComponent from '../../components/VideoOnReady';

const HippoPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const totalVideos = 4;
  const [loadedVideos, setLoadedVideos] = useState<number[]>([]);
  const playerRefs = useRef<(any | undefined)[]>(new Array(totalVideos));

  const handleVideoLoaded = (videoNumber: number, player: any) => {
    setLoadedVideos(prev => {
      const updatedLoadedVideos = new Set(prev);
      updatedLoadedVideos.add(videoNumber);

      playerRefs.current[videoNumber - 1] = player;

      if (updatedLoadedVideos.size === totalVideos) {
        setIsLoading(false);
        playerRefs.current.forEach(p => p?.play());
      }

      return Array.from(updatedLoadedVideos);
    });
  };

  return (
    <section className="layoutContainer">
      <LoadingAnimation isLoading={isLoading} />
      <div className="only1000">
        <div className={styles.earthVideo}>
          <video
            width="1000"
            preload="metadata"
            muted
            loop
            playsInline
            autoPlay
          >
            <source src="/hippo/earth.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={styles.rectangleDiv} />
        <div className={styles.solutionChild1} />
        <div className={styles.solutionChild} />
      </div>
      <div className="layout1000">
        <div className={styles.solution}>
          <div>
            <VideoComponent
              key={1}
              src="/hippo/hippo.mp4"
              onReady={(videoNumber, player) =>
                handleVideoLoaded(videoNumber, player)
              }
              videoNumber={1}
              className="basic-img-width-500"
            />
          </div>
          <b className={styles.yourGatewayTo}>
            Your gateway to smarter, faster medical assistance
          </b>
          <b className={styles.personalizedHealthcareSoluti}>
            Personalized Healthcare SOlution
          </b>
          <div
            className={styles.developedByHippocrat}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Developed by Hippocrat Lab, "Hippo" aims to bridge global healthcare
            gaps through remote healthcare consultations. Hippo offers instant
            responses to health queries based on a comprehensive medical
            database and AI technology with access to real, verified medical
            professionals for personalized assistance. Enjoy seamless healthcare
            consultation at your convenience, anywhere, anytime with Hippo.
          </div>
          <b className={styles.alwaysAccessibleHealthContainer}>
            <p
              className={styles.alwaysAccessibleHealth}
            >{`Always accessible health advice. `}</p>
            <p className={styles.alwaysAccessibleHealth}>
              Ask now, know now - wellness at your fingertips
            </p>
          </b>
          <div
            className={styles.hipposTechnology}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            HIPPO’s Technology
          </div>
          <img
            className={styles.image34Icon}
            alt=""
            src="/hippo/image-34@2x.png"
          />
          <div
            className={styles.joinTheMedical}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            Join the Medical team
          </div>
          <b className={styles.hippoIsOnContainer}>
            <p className={styles.alwaysAccessibleHealth}>
              Hippo is on a mission to elevate global healthcare standards. We
              seek medical professionals committed to bridging healthcare
              disparities worldwide.
            </p>
            <p className={styles.alwaysAccessibleHealth}>
              {' '}
              Join us now and together we can make a more impactful change.
            </p>
          </b>
          <a href="mailto:support@hippocrat.io" target="_blank">
            <div className={styles.letsJoin}>LET’S JOIN!</div>
          </a>

          <div
            className={styles.accessExpertMedical}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Access expert medical advice at any time, from any location. Our
            service transcends geographical and linguistic barriers to ensure
            you receive the professional support you need, whenever you need it.
          </div>
          <div
            className={styles.accessExpertMedical1}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            You can pay using popular cryptocurrencies, providing you with a
            secure, efficient, and modern way. Experience the convenience and
            flexibility of digital currency payments.
          </div>
          <div
            className={styles.youCanPay}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Your health information is safeguarded using advanced blockchain
            technology, ensuring it's recorded securely and remains under your
            control to tailor your healthcare service. Exercise your ownership
            rights over your health data and share it at your discretion for
            additional benefits.
          </div>
          <div className={styles.remoteConsultation}>
            24/7 Remote Consultation
          </div>
          <div className={styles.remoteConsultation1}>
            Cryptocurrency Payments
          </div>
          <div className={styles.cryptocurrencyPayments}>
            Data Ownership & Security
          </div>
          <div
            className={styles.theHipposOriginal}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            The HIPPO’S ORIGINAL TECHNOLOGY : Hippocrat-wallet-sdk facilitates
            global 3rd-party health services to join the ecosystem.
          </div>
          <b className={styles.sdk}>SDK</b>
          <b className={styles.p2pStorage}>P2P storage</b>
          <div
            className={styles.decentralizedDataTransmissio}
            style={{ fontFamily: 'var(--font-inter)' }}
          >{`Decentralized Data transmission & management: Hippocrat employs P2P technology to facilitate secure data communication.`}</div>
          <a
            href="https://www.npmjs.com/package/hippocrat-wallet-sdk"
            target="_blank"
          >
            <div className={styles.readMore}>READ MORE</div>
          </a>
          <a href="https://github.com/hippocrat-dao" target="_blank">
            <div className={styles.readMore1}>READ MORE</div>
          </a>

          <div className={styles.solutionChild5} />
          <div className={styles.maskGroupIcon}>
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={0}
              pagination={{
                clickable: true,
              }}
              className="mySwiper"
            >
              <SwiperSlide>
                <img alt="" src="/hippo/app.png" style={{ width: '478px' }} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src="/hippo/app2.png" style={{ width: '478px' }} />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className={styles.maskGroupIcon1}>
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={0}
              pagination={{
                clickable: true,
              }}
              className="mySwiper"
            >
              <SwiperSlide>
                <img alt="" src="/hippo/app3.png" style={{ width: '478px' }} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src="/hippo/app4.png" style={{ width: '478px' }} />
              </SwiperSlide>
            </Swiper>
          </div>
          <img
            className={styles.maskGroupIcon1_1}
            alt=""
            src="/hippo/app5.png"
          />

          <img
            className={styles.maskGroupIcon4}
            alt=""
            src="/hippo/technology.png"
          />
          <img
            className={styles.maskGroupIcon3}
            alt=""
            src="/hippo/technology1.png"
          />
        </div>
      </div>
      <div className="only800">
        <div className={styles.earthVideo}>
          <video width="800" preload="metadata" muted loop playsInline autoPlay>
            <source src="/hippo/earth.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={styles.rectangleDiv} />
        <div className={styles.solutionChild1} />
        <div className={styles.solutionChild} />

        <div className={styles.maskGroupIcon3}>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            className="mySwiper"
          >
            <SwiperSlide>
              <img alt="" src="/hippo/app.png" style={{ width: '478px' }} />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="" src="/hippo/app2.png" style={{ width: '478px' }} />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className={styles.maskGroupIcon2}>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            className="mySwiper"
          >
            <SwiperSlide>
              <img alt="" src="/hippo/app3.png" style={{ width: '478px' }} />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="" src="/hippo/app4.png" style={{ width: '478px' }} />
            </SwiperSlide>
          </Swiper>
        </div>
        <img className={styles.maskGroupIcon1} alt="" src="/hippo/app5.png" />
      </div>

      <div className="layout800">
        <div className={styles.solution}>
          <div style={{ marginTop: 50 }}>
            <VideoComponent
              key={2}
              src="/hippo/hippo.mp4"
              onReady={(videoNumber, player) =>
                handleVideoLoaded(videoNumber, player)
              }
              videoNumber={2}
              className="basic-img-width-400"
            />
          </div>
          <b className={styles.yourGatewayTo}>
            Your gateway to smarter, faster medical assistance
          </b>
          <b className={styles.personalizedHealthcareSoluti}>
            Personalized Healthcare SOlution
          </b>
          <div
            className={styles.developedByHippocrat}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Developed by Hippocrat Lab, "Hippo" aims to bridge global healthcare
            gaps through remote healthcare consultations. Hippo offers instant
            responses to health queries based on a comprehensive medical
            database and AI technology with access to real, verified medical
            professionals for personalized assistance. Enjoy seamless healthcare
            consultation at your convenience, anywhere, anytime with Hippo.
          </div>
          <b className={styles.alwaysAccessibleHealthContainer}>
            <p
              className={styles.alwaysAccessibleHealth}
            >{`Always accessible health advice. `}</p>
            <p className={styles.alwaysAccessibleHealth}>
              Ask now, know now - wellness at your fingertips
            </p>
          </b>
          <div
            className={styles.hipposTechnology}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            HIPPO’s Technology
          </div>
          <img
            className={styles.image34Icon}
            alt=""
            src="/hippo/image-34@2x.png"
          />
          <div
            className={styles.joinTheMedical}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            Join the Medical team
          </div>
          <b className={styles.hippoIsOnContainer}>
            <p className={styles.alwaysAccessibleHealth}>
              Hippo is on a mission to elevate global healthcare standards. We
              seek medical professionals committed to bridging healthcare
              disparities worldwide.
            </p>
            <p className={styles.alwaysAccessibleHealth}>
              {' '}
              Join us now and together we can make a more impactful change.
            </p>
          </b>
          <a href="mailto:support@hippocrat.io" target="_blank">
            <div className={styles.letsJoin}>LET’S JOIN!</div>
          </a>
          <div
            className={styles.accessExpertMedical}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Access expert medical advice at any time, from any location. Our
            service transcends geographical and linguistic barriers to ensure
            you receive the professional support you need, whenever you need it.
          </div>
          <div
            className={styles.accessExpertMedical1}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            You can pay using popular cryptocurrencies, providing you with a
            secure, efficient, and modern way. Experience the convenience and
            flexibility of digital currency payments.
          </div>
          <div
            className={styles.youCanPay}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Data management system securely shares medical records stored in the
            user's wallet. Hospitals use QR codes for fast verification and
            service provision with user approval, ensuring secure data sharing
            even in privacy concerns.
          </div>
          <div className={styles.remoteConsultation}>
            24/7 Remote Consultation
          </div>
          <div className={styles.remoteConsultation1}>
            Cryptocurrency Payments
          </div>
          <div className={styles.cryptocurrencyPayments}>
            Data Ownership & Security
          </div>

          <img
            className={styles.maskGroupIcon6}
            alt=""
            src="/hippo/technology.png"
          />
          <img
            className={styles.maskGroupIcon5}
            alt=""
            src="/hippo/technology1.png"
          />

          <div
            className={styles.theHipposOriginal}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            The HIPPO’S ORIGINAL TECHNOLOGY : Hippocrat-wallet-sdk facilitates
            global 3rd-party health services to join the ecosystem.
          </div>
          <b className={styles.sdk}>SDK</b>
          <b className={styles.p2pStorage}>P2P storage</b>
          <div
            className={styles.decentralizedDataTransmissio}
            style={{ fontFamily: 'var(--font-inter)' }}
          >{`Decentralized Data transmission & management: Hippocrat employs P2P technology to facilitate secure data communication.`}</div>
          <a
            href="https://www.npmjs.com/package/hippocrat-wallet-sdk"
            target="_blank"
          >
            <div className={styles.readMore}>READ MORE</div>
          </a>
          <a href="https://github.com/hippocrat-dao" target="_blank">
            <div className={styles.readMore1}>READ MORE</div>
          </a>
        </div>
      </div>
      <MHippoPage handleVideoLoaded={handleVideoLoaded} />
    </section>
  );
};

export default HippoPage;
