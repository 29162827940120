import React, { useState, useRef, useEffect } from 'react';
import styles from './hpoToken.module.scss';
import MHpoTokenPage from './mIndex';
import LoadingAnimation from '../../components/LoadingAnimation';
import VideoComponent from '../../components/VideoOnReady';

const HpoTokenPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const totalVideos = 4;
  const [loadedVideos, setLoadedVideos] = useState<number[]>([]);
  const playerRefs = useRef<(any | undefined)[]>(new Array(totalVideos));

  const handleVideoLoaded = (videoNumber: number, player: any) => {
    setLoadedVideos(prev => {
      const updatedLoadedVideos = new Set(prev);
      updatedLoadedVideos.add(videoNumber);

      playerRefs.current[videoNumber - 1] = player;

      if (updatedLoadedVideos.size === totalVideos) {
        setIsLoading(false);
        playerRefs.current.forEach(p => p?.play());
      }

      return Array.from(updatedLoadedVideos);
    });
  };

  return (
    <section
      className="layoutContainer"
      style={{ backgroundColor: 'var(--color-black)', marginBottom: '-20px' }}
    >
      <LoadingAnimation isLoading={isLoading} />
      <div className="only1000">
        <div style={{ marginTop: '90px' }}>
          <VideoComponent
            key={1}
            src="/hpoToken/hpoToken.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={1}
            className="basic-video-img"
          />
        </div>
        <div
          className="basic-text-1000"
          style={{
            marginTop: 'calc(50 * var(--vh) - 300px)',
          }}
        >
          <div className={styles.hippocratToken}>
            <span>
              <span className={styles.hipp}>HIPP</span>
              <span className={styles.o}>O</span>
            </span>
            <i className={styles.crat}>CRAT</i>
            <span className={styles.hipp} style={{ marginLeft: '25px' }}>
              <span>TOKE</span>
              <b className={styles.n}>N</b>
            </span>
          </div>
          <b className={styles.hpoTokenFuels}>
            The HPO token drives the innovation and growth.
            <br />
            Your contributions, powered by this token, help
            <br />
            shaping our shared future.
          </b>
          <div className={styles.sustainableBenefit}>SUSTAINABLE BENEFIT</div>
        </div>
      </div>
      <div className="layout1000">
        <div className={styles.hpoToken1000}>
          <div className={styles.hpoToken1000Child} />

          <b className={styles.totalTokenSupply}>Total token supply</b>
          <div className={styles.div}>1,084,734,273,38</div>
          <div className={styles.hpoIsA}>
            HPO is a rebranded token from HUM. Following the rebranding, a hard
            fork to the <br />
            HPO token took place in the second quarter of 2023.
          </div>
          <div className={styles.annotation}>*annotation</div>
          <b className={styles.daoCommunity}>{`DAO & Community`}</b>
          <b className={styles.earlyEcosystemParticipants}>
            Early ecosystem participants
          </b>
          <b className={styles.b}>130,000,000</b>
          <b className={styles.b1}>41,325,000</b>

          <div className={styles.hpoToken1000Item} />
          <div className={styles.hpoToken1000Inner} />
          <div className={styles.tokenAllocation}>
            <span>TOKEN ALLO</span>
            <i className={styles.cation}>CATION</i>
          </div>
          <div className={styles.listingExchange}>
            <span>{`LISTING `}</span>
            <i className={styles.cation}>EXCHANGE</i>
          </div>

          <a
            href="https://upbit.com/exchange?code=CRIX.UPBIT.KRW-HPO"
            target="_blank"
          >
            <img
              className={styles.image54Icon}
              alt=""
              src="/home/image-54@2x.png"
            />
          </a>
          <a
            href="https://coinone.co.kr/exchange/trade/hpo/krw"
            target="_blank"
          >
            <img
              className={styles.image56Icon}
              alt=""
              src="/home/image-56@2x.png"
            />
          </a>
          <a href="https://www.gopax.co.kr/exchange/hpo-krw" target="_blank">
            <img
              className={styles.image55Icon}
              alt=""
              src="/home/image-55@2x.png"
            />
          </a>

          <div className={styles.rectangleDiv}>
            <video
              width="100%"
              preload="metadata"
              muted
              loop
              playsInline
              autoPlay
            >
              <source src="/hpoToken/coin_color.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div className="only800">
        <div style={{ marginTop: '90px' }}>
          <VideoComponent
            key={2}
            src="/hpoToken/hpoToken.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={2}
            className="basic-video-img"
          />
        </div>
        <div
          className="basic-text-800"
          style={{
            marginTop: 'calc(50 * var(--vh) - 270px)',
          }}
        >
          <div className={styles.sustainableBenefit}>SUSTAINABLE BENEFIT</div>
          <div className={styles.hippocratToken}>
            <span>
              <span
                className={styles.hipp}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                HIPP
              </span>
              <span
                className={styles.o}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                O
              </span>
            </span>
            <i className={styles.crat}>CRAT</i>
            <span className={styles.hipp}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                  marginLeft: '15px',
                }}
              >
                {' '}
                TOKE
              </span>
              <b className={styles.n}>N</b>
            </span>
          </div>
          <b className={styles.theHpoToken}>
            The HPO token drives the innovation and growth. Your contributions,
            powered by this token, help shaping our shared future.
          </b>
        </div>
      </div>
      <div className="layout800">
        <div className={styles.token800}>
          <div className={styles.token800Child} />
          <b className={styles.totalTokenSupply}>Total token supply</b>
          <div className={styles.div}>1,084,734,273,38</div>
          <div className={styles.hpoIsA}>
            HPO is a rebranded token from HUM. Following the rebranding, a hard
            fork to the HPO token took place in the second quarter of 2023.
          </div>
          <div className={styles.annotation}>*annotation</div>
          <b className={styles.daoCommunity}>{`DAO & Community`}</b>
          <b className={styles.earlyEcosystemParticipants}>
            Early ecosystem participants
          </b>
          <b className={styles.b}>130,000,000</b>
          <b className={styles.b1}>41,325,000</b>
          <div className={styles.token800Item} />
          <div className={styles.token800Inner} />
          <div className={styles.tokenAllocation}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              TOKEN ALLO
            </span>
            <i className={styles.n}>CATION</i>
          </div>
          <div className={styles.hippoVideo}>
            <video
              width="370"
              preload="metadata"
              muted
              loop
              playsInline
              autoPlay
            >
              <source src="/hpoToken/coin_color.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className={styles.listingExchange}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`LISTING `}</span>
            <i className={styles.n}>EXCHANGE</i>
          </div>
          <a
            href="https://upbit.com/exchange?code=CRIX.UPBIT.KRW-HPO"
            target="_blank"
          >
            <img
              className={styles.image54Icon}
              alt=""
              src="/home/image-54@2x.png"
            />
          </a>
          <a
            href="https://coinone.co.kr/exchange/trade/hpo/krw"
            target="_blank"
          >
            <img
              className={styles.image56Icon}
              alt=""
              src="/home/image-56@2x.png"
            />
          </a>
          <a href="https://www.gopax.co.kr/exchange/hpo-krw" target="_blank">
            <img
              className={styles.image55Icon}
              alt=""
              src="/home/image-55@2x.png"
            />
          </a>
        </div>
      </div>
      <MHpoTokenPage handleVideoLoaded={handleVideoLoaded} />
    </section>
  );
};

export default HpoTokenPage;
