import React, { useState, useRef, useEffect } from 'react';
import styles from './protocol.module.scss';
import MProtocolPage from './mIndex';
import LoadingAnimation from '../../components/LoadingAnimation';
import VideoComponent from '../../components/VideoOnReady';

const ProtocolPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const totalVideos = 4;
  const [loadedVideos, setLoadedVideos] = useState<number[]>([]);
  const playerRefs = useRef<(any | undefined)[]>(new Array(totalVideos));

  const handleVideoLoaded = (videoNumber: number, player: any) => {
    setLoadedVideos(prev => {
      const updatedLoadedVideos = new Set(prev);
      updatedLoadedVideos.add(videoNumber);

      playerRefs.current[videoNumber - 1] = player;

      if (updatedLoadedVideos.size === totalVideos) {
        setIsLoading(false);
        setTimeout(() => {
          playerRefs.current.forEach(p => p?.play());
        }, 700);
      }

      return Array.from(updatedLoadedVideos);
    });
  };

  return (
    <section className="layoutContainer" style={{ marginBottom: '-20px' }}>
      <LoadingAnimation isLoading={isLoading} />
      <div className="only1000">
        <div className={styles.rectangleDiv} />
        <div>
          <VideoComponent
            key={1}
            src="/protocol/protocol.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={1}
            className="basic-video-img"
          />
        </div>
        <div
          className="basic-text-1000"
          style={{
            marginTop: 'calc(50 * var(--vh) - 280px)',
          }}
        >
          <div className={styles.protocol}>
            <span>PR</span>
            <b className={styles.o}>O</b>
            <span>TO</span>
            <i className={styles.col}>COL</i>
          </div>
          <div className={styles.hippocratEcosystem}>Hippocrat Ecosystem</div>
          <b className={styles.hippocratEstablishesSustaina}>
            Hippocrat establishes sustainable collaborative protocols for
            healthcare data, built upon the Bitcoin layer.
          </b>
        </div>
      </div>
      <div className="layout1000">
        <div className={styles.protocol1000}>
          <div className={styles.protocol1000Child} />
          <b className={styles.first}>FIRST</b>
          <div className={styles.protocol1000Item} />
          <div className={styles.protocol1000Inner} />
          <b className={styles.second}>SECOND</b>
          <b className={styles.third}>THIRD</b>
          <div className={styles.healthcareDataRequires}>
            Healthcare data requires a transparent protocol for documenting
            contributions from both patients and various stakeholders before its
            utilization.
          </div>
          <div className={styles.healthcareDataNecessitates}>
            Healthcare data necessitates a protocol for ensuring trust in its
            records of utilization and distribution, operable through an open
            cooperative system open to all participants.
          </div>
          <div className={styles.healthcareDataDemands}>
            Healthcare data demands a compensation distribution protocol
            designed to foster sustainable collaboration among involved
            stakeholders.
          </div>
          <img className={styles.firstImg} alt="" src="/protocol/first.png" />
          <img className={styles.secondImg} alt="" src="/protocol/second.png" />
          <img className={styles.thirdImg} alt="" src="/protocol/third.png" />

          <img className={styles.icon} alt="" src="/protocol/dao.png" />
          <div className={styles.protocol1000Child1}>
            <img className="basic-img" alt="" src="/protocol/coinLayer.png" />
          </div>
          <div className={styles.protocol1000Child3}>
            <img className="basic-img" alt="" src="/protocol/ob8.png" />
          </div>
          <div className={styles.protocol1000Child2}>
            <img className="basic-img" alt="" src="/protocol/sun.png" />
          </div>
          <div className={styles.theUseOf}>
            The use of Decentralized Identifier (DID) and Verifiable Credentials
            (VC) technologies empowers individuals to
            <br />
            exercise self-sovereignty over their medical data, extending beyond
            mere personal identification.
          </div>
          <div className={styles.theHippocratWallet}>
            The Hippocrat Wallet SDK, built on proven technology, enables users
            to access their assets and data in the developed wallet through a
            range of applications.
          </div>
          <div className={styles.utilizingTheHighly}>
            Utilizing the highly decentralized and secure Bitcoin layer, we
            ensure the utmost security for your valuable health data.
          </div>
          <div className={styles.leveragingTheBitcoinContainer}>
            <span className={styles.leveragingTheBitcoinContainer1}>
              <p className={styles.leveraging}>{`Leveraging `}</p>
              <p className={styles.leveraging}>
                <span>the Bitcoin</span>
                <i className={styles.layer}> Layer</i>
              </p>
            </span>
          </div>
          <div className={styles.implementingDidContainer}>
            <span className={styles.leveragingTheBitcoinContainer1}>
              <span>{`Implementing `}</span>
              <i className={styles.layer}>{`DID & VC`}</i>
            </span>
          </div>
          <div className={styles.providingTheHippocratContainer}>
            <span className={styles.leveragingTheBitcoinContainer1}>
              <p className={styles.leveraging}>{`Providing the `}</p>
              <p className={styles.leveraging}>
                <span>Hippo</span>
                <i className={styles.layer}>{`crat `}</i>
                <span className={styles.wallet}>{`Wallet `}</span>
                <i className={styles.layer}>SDK</i>
              </p>
            </span>
          </div>
        </div>
      </div>
      <div className="only800">
        <div className={styles.protocol800Child1} />
        <div>
          <VideoComponent
            key={2}
            src="/protocol/protocol.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={2}
            className="basic-video-img"
          />
        </div>
        <div
          className="basic-text-800"
          style={{
            marginTop: 'calc(50 * var(--vh) - 280px)',
          }}
        >
          <div className={styles.protocol}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              PR
            </span>
            <b className={styles.o}>O</b>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              TO
            </span>
            <i className={styles.o}>COL</i>
          </div>
          <div className={styles.hippocratEcosystem}>Hippocrat Ecosystem</div>
          <b className={styles.hippocratEstablishesSustaina}>
            Hippocrat establishes sustainable collaborative protocols for
            healthcare data, built upon the Bitcoin layer.
          </b>
        </div>
      </div>
      <div className="layout800">
        <div className={styles.protocol800}>
          <div className={styles.protocol800Child} />
          <b className={styles.first}>FIRST</b>
          <div className={styles.protocol800Item} />
          <div className={styles.protocol800Item} />
          <div className={styles.rectangleDiv} />
          <b className={styles.second}>SECOND</b>
          <b className={styles.third}>THIRD</b>

          <div
            className={styles.healthcareDataRequires}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Healthcare data requires a transparent protocol for documenting
            contributions from both patients and various stakeholders prior to
            it’s utilization.
          </div>
          <div
            className={styles.healthcareDataNecessitates}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Healthcare data necessitates a protocol that ensures trust in its
            records of utilization and distribution, operable through an open
            cooperative system open to all participants.
          </div>
          <div
            className={styles.healthcareDataDemands}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Healthcare data demands a compensation distribution protocol
            designed to foster sustainable collaboration among involved
            stakeholders.
          </div>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/protocol/coinLayer.png"
          />
          <img
            className={styles.maskGroupIcon1}
            alt=""
            src="/protocol/sun.png"
          />
          <img className={styles.ob8Icon} alt="" src="/protocol/ob8.png" />
          <div
            className={styles.theUseOf}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            The use of Decentralized Identifier (DID) and Verifiable Credentials
            (VC) technologies empowers individuals to exercise self-sovereignty
            over their medical data, extending beyond mere personal
            identification.
          </div>
          <div
            className={styles.theHippocratWallet}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            The Hippocrat Wallet SDK, built on proven technology, enables users
            to access their assets and data in the developed wallet through a
            range of applications.
          </div>
          <div
            className={styles.utilizingTheHighly}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Utilizing the highly decentralized and secure Bitcoin layer, we
            ensure the utmost security for your valuable health data.
          </div>
          <div className={styles.leveragingTheBitcoinContainer}>
            <span className={styles.leveragingTheBitcoinContainer1}>
              <p
                className={styles.leveraging}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`Leveraging `}</p>
              <p className={styles.leveraging}>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  the Bitcoin
                </span>
                <i className={styles.o}> Layer</i>
              </p>
            </span>
          </div>
          <div className={styles.implementingDidContainer}>
            <span className={styles.leveragingTheBitcoinContainer1}>
              <p
                className={styles.leveraging}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`Implementing `}</p>
              <p className={styles.didVc}>
                <i>{`DID & VC`}</i>
              </p>
            </span>
          </div>
          <div className={styles.providingTheHippocratContainer}>
            <span className={styles.leveragingTheBitcoinContainer1}>
              <p
                className={styles.leveraging}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`Providing the `}</p>
              <p className={styles.leveraging}>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  Hippo
                </span>
                <i className={styles.o}>{`crat `}</i>
                <span
                  className={styles.wallet}
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >{`Wallet `}</span>
                <i className={styles.o}>SDK</i>
              </p>
            </span>
          </div>
          <img className={styles.icon} alt="" src="/protocol/dao.png" />
          <img className={styles.pilsIcon} alt="" src="/protocol/first.png" />
          <img
            className={styles.maskGroupIcon2}
            alt=""
            src="/protocol/third.png"
          />
          <img
            className={styles.maskGroupIcon3}
            alt=""
            src="/protocol/second.png"
          />
        </div>
      </div>
      <MProtocolPage handleVideoLoaded={handleVideoLoaded} />
    </section>
  );
};

export default ProtocolPage;
