import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './headerNav.module.scss';

const HeaderNav = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSubOpen, setIsSubOpen] = useState<boolean>(false);
  const [menu, setMenu] = useState<string>('ecosystem');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsSubOpen(false);
  };
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const selectMenu = (menu: string) => {
    setIsSubOpen(true);
    setMenu(menu);
  };

  const setSelectMenu = () => {
    setIsSubOpen(false);
    setMenu('ecosystem');
  };

  useEffect(() => {
    const path = location.pathname;

    if (path.startsWith('/ecosystem')) {
      setMenu('ecosystem');
    } else if (
      path.startsWith('/protocol') ||
      path.startsWith('/dao') ||
      path.startsWith('/hpoToken')
    ) {
      setMenu('ecosystem');
    } else if (
      path.startsWith('/hippodoc') ||
      path.startsWith('/sdk') ||
      path.startsWith('/p2p')
    ) {
      setMenu('solutions');
    } else if (
      path.startsWith('/roadMap') ||
      path.startsWith('/whitePaper') ||
      path.startsWith('/faq') ||
      path.startsWith('/blog') ||
      path.startsWith('/news') ||
      path.startsWith('/pressKit')
    ) {
      setMenu('resources');
    } else if (
      path.startsWith('/learn/web3') ||
      path.startsWith('/learn/healthcareData') ||
      path.startsWith('/learn/hl7') ||
      path.startsWith('/learn/p2pProtocol')
    ) {
      setMenu('learn');
    } else if (path.startsWith('/community')) {
      setMenu('community');
    } else if (path.startsWith('/aboutUs')) {
      setMenu('aboutUs');
    } else {
      setMenu('ecosystem');
    }
  }, [location]);

  const renderMenuItem = (menuItem: string, menuTitle: string) => {
    return (
      <div
        className={`${styles[menuItem]} ${
          menu === menuItem ? styles.menuSelect : ''
        }`}
        onClick={() => selectMenu(menuItem)}
      >
        {menuTitle}
      </div>
    );
  };

  return (
    <>
      <header className={styles.header}>
        <div className={styles.gnb1000}>
          <div className={styles.gnb1000Child} />
          <a href="/">
            <img
              className={styles.hippocratNewLogoWhite2Icon}
              alt=""
              src="/header/hippocratnewlogo-white-2@2x.png"
            />
          </a>
          <button onClick={toggleMenu}>
            {isOpen ? (
              <>
                <div className={styles.gnb1000XItem} />
                <img
                  className={styles.xCloseIcon}
                  alt=""
                  src="/header/xclose@2x.png"
                />
              </>
            ) : (
              <>
                <div className={styles.gnb1000Item} />
                <img
                  className={styles.menu01Icon}
                  alt=""
                  src="/header/menu01@2x.png"
                />
              </>
            )}
          </button>
        </div>
      </header>
      <div className={styles.pcDiv}>
        <div
          className={`${styles.lnb1000Bg} ${isOpen ? styles.open : ''}`}
          onClick={toggleMenu}
        />
        <div className={`${styles.lnb1000} ${isOpen ? styles.open : ''}`}>
          <div className={styles.lnb1000Child}>
            <div style={{ display: menu === 'ecosystem' ? 'block' : 'none' }}>
              <div className={styles.menuTitle}>ECOSYSTEM</div>
              <div className={styles.menuTitleDec}>
                The structure of Hippocrat Ecosystem
              </div>
              <div className={styles.leftMenuTitle}>WHAT WE BUILD</div>
              <a href="/protocol">
                <div className={styles.leftMenu1}>PROTOCOL</div>
                <div className={styles.leftMenuDec1}>
                  About Hippocrat Ecosystem
                </div>
              </a>
              <a href="/hpoToken">
                <div className={styles.leftMenu2}>HPO TOKEN</div>
                <div className={styles.leftMenuDec2}>Sustainable benefits</div>
              </a>

              <div className={styles.rightMenuTitle}>GOVERNED BY</div>
              <a href="/dao">
                <div className={styles.rightMenu1}>DAO</div>
                <div className={styles.rightMenuDec1}>
                  The coolest form of governance
                </div>
              </a>
            </div>
            <div style={{ display: menu === 'solutions' ? 'block' : 'none' }}>
              <div className={styles.menuTitle}>SOLUTIONS</div>
              <div className={styles.menuTitleDec}>How we change the world</div>
              <div className={styles.leftMenuTitle}>WHAT WE OFFER</div>
              <a href="/hippodoc">
                <div className={styles.leftMenu1}>HIPPODOC</div>
                <div className={styles.leftMenuDec1}>
                  Personailzed healthcare solution
                </div>
              </a>
              <a href="/sdk">
                <div className={styles.leftMenu2}>SDK</div>
                <div className={styles.leftMenuDec2}>
                  HPO’s original technology
                </div>
              </a>
              <a href="/p2p">
                <div className={styles.rightMenu1}>P2P</div>
                <div className={styles.rightMenuDec1}>
                  Decentralized storage system
                </div>
              </a>
            </div>
            <div style={{ display: menu === 'resources' ? 'block' : 'none' }}>
              <div className={styles.menuTitle}>RESOURCES</div>
              <div className={styles.menuTitleDec}>
                The latest WEB3 healthcare insights and
                <br />
                events Stay updated
              </div>
              <div className={styles.leftMenuTitle}>FROM HIPPOCRAT</div>
              <a href="/roadMap">
                <div className={styles.leftMenu1}>ROADMAP</div>
                <div className={styles.leftMenuDec1}>Our pioneer journey</div>
              </a>
              <a href="/whitePaper">
                <div className={styles.leftMenu2}>WHITEPAPER</div>
                <div className={styles.leftMenuDec2}>
                  Check out the Newest version
                </div>
              </a>
              <a href="/faq">
                <div className={styles.leftMenu3}>FAQ</div>
                <div className={styles.leftMenuDec3}>
                  Frequently asked questions
                </div>
              </a>
              <a href="/blog">
                <div className={styles.rightMenu1}>BLOG</div>
                <div className={styles.rightMenuDec1}>
                  A focused, Insightful view
                </div>
              </a>
              <a href="/news">
                <div className={styles.rightMenu2}>NEWS</div>
                <div className={styles.rightMenuDec2}>Announcement & Press</div>
              </a>
              <a href="/pressKit">
                <div className={styles.rightMenu3}>PRESS KIT</div>
                <div className={styles.rightMenuDec3}>
                  All your media and press needs
                </div>
              </a>
            </div>
            <div style={{ display: menu === 'learn' ? 'block' : 'none' }}>
              <div className={styles.menuTitle}>LEARN</div>
              <div className={styles.menuTitleDec}>
                How Blockchain technology could
                <br />
                revolutionize Healthcare
              </div>
              <div className={styles.leftMenuTitle}>EDUCATION AND LEARNING</div>
              <a href="/learn/web3">
                <div className={styles.leftMenu1}>BLOCKCHAIN & WEB3</div>
                <div className={styles.leftMenuDec1}>The coming future</div>
              </a>
              <a href="/learn/healthcareData">
                <div className={styles.leftMenu2}>
                  SELF-SOVEREIGN HEALTHCARE DATA
                </div>
                <div className={styles.leftMenuDec2}>
                  True ownership of health data
                </div>
              </a>
              <a href="/learn/hl7">
                <div className={styles.leftMenu3}>HEALTH LEVEL SEVEN : HL7</div>
                <div className={styles.leftMenuDec3}>
                  Global standard for health data transfer
                </div>
              </a>
              <a href="/learn/p2pProtocol">
                <div className={styles.leftMenu4}>P2P Protocol</div>
                <div className={styles.leftMenuDec4}>
                  Trends in Decentralized Storage System
                </div>
              </a>
            </div>
            <div style={{ display: menu === 'community' ? 'block' : 'none' }}>
              <div className={styles.menuTitle}>COMMUNITY</div>
              <div className={styles.menuTitleDec}>
                Talk and share opinions with similar
                <br />
                minds, built the future of healthcare together
              </div>
              <div className={styles.leftMenuTitle}>COME & JOIN US</div>
              <a href="https://discord.com/invite/hpo" target="_blank">
                <div className={styles.leftMenu1}>Discord</div>
              </a>
              <a href="https://twitter.com/hippocrat_io" target="_blank">
                <div className={styles.leftMenu2}>Twitter / X</div>
              </a>
              <a href="https://github.com/hippocrat-dao" target="_blank">
                <div className={styles.leftMenu3}>GitHub</div>
              </a>
              <a href="https://t.me/hippocrat_official" target="_blank">
                <div className={styles.leftMenu4}>Telegram</div>
              </a>
              <a
                href="https://www.instagram.com/med_tip.hippo/"
                target="_blank"
              >
                <div className={styles.rightMenu1}>Instagram</div>
              </a>
              <a href="https://medium.com/hippocrat" target="_blank">
                <div className={styles.rightMenu2}>Medium</div>
              </a>
              <a
                href="https://community.hippocrat.io/c/announcements"
                target="_blank"
              >
                <div className={styles.rightMenu3}>Circle</div>
              </a>
              <a
                href="https://www.linkedin.com/company/hippocratlabs/about/"
                target="_blank"
              >
                <div className={styles.rightMenu4}>Linked in</div>
              </a>
            </div>
            <div style={{ display: menu === 'aboutUs' ? 'block' : 'none' }}>
              <div className={styles.menuTitle}>ABOUT US</div>
              <div className={styles.menuTitleDec}>
                Mission, Vision & Core Values
              </div>
              <div className={styles.leftMenuTitle}>WHO WE ARE</div>
              <a href="/aboutUs">
                <div className={styles.leftMenu1}>ABOUT HIPPOCRAT</div>
                <div className={styles.leftMenuDec1}>Our Story</div>
              </a>
            </div>

            <div className={styles.lnb1000Item} />
            {renderMenuItem('solutions', 'Solutions')}
            {renderMenuItem('resources', 'Resources')}
            {renderMenuItem('learn', 'Learn')}
            {renderMenuItem('community', 'Community')}
            {renderMenuItem('ecosystem', 'Ecosystem')}
            {renderMenuItem('aboutUs', 'About Us')}
          </div>
        </div>
      </div>
      {isOpen && !isSubOpen && (
        <>
          <div className={styles.mobileDiv}>
            <div className={styles.mobileDepth1}>
              <div className={styles.mobileDepth1Child} />
              <a href="https://github.com/hippocrat-dao" target="_blank">
                <div className={styles.contact}>Github</div>
              </a>
              <a href="https://twitter.com/hippocrat_io" target="_blank">
                <div className={styles.twitterX}>Twitter / X</div>
              </a>
              <a href="https://discord.com/invite/hpo" target="_blank">
                <div className={styles.discord}>Discord</div>
              </a>
              <a href="https://medium.com/hippocrat" target="_blank">
                <div className={styles.medium}>Medium</div>
              </a>
              <a
                href="https://community.hippocrat.io/c/announcements"
                target="_blank"
              >
                <div className={styles.circle}>Circle</div>
              </a>
              <a href="https://t.me/hippocrat_official" target="_blank">
                <div className={styles.telegram}>Telegram</div>
              </a>
              <a
                href="https://www.linkedin.com/company/hippocratlabs/about/"
                target="_blank"
              >
                <div className={styles.linkedin}>Linkedin</div>
              </a>
              <a
                href="https://www.instagram.com/med_tip.hippo/"
                target="_blank"
              >
                <div className={styles.instagram}>Instagram</div>
              </a>
              <div onClick={() => selectMenu('ecosystem')}>
                <div className={styles.ecosystem}>Ecosystem</div>
                <img
                  className={styles.chevronDownIcon}
                  alt=""
                  src="/header/chevron-down.png"
                />
              </div>
              <div onClick={() => selectMenu('solutions')}>
                <div className={styles.solution}>Solution</div>
                <img
                  className={styles.chevronDownIcon1}
                  alt=""
                  src="/header/chevron-down.png"
                />
              </div>

              <div onClick={() => selectMenu('resources')}>
                <div className={styles.resources}>REsources</div>
                <img
                  className={styles.chevronDownIcon2}
                  alt=""
                  src="/header/chevron-down.png"
                />
              </div>
              <div onClick={() => selectMenu('learn')}>
                <div className={styles.learn}>learn</div>
                <img
                  className={styles.chevronDownIcon3}
                  alt=""
                  src="/header/chevron-down.png"
                />
              </div>
              <div onClick={() => selectMenu('aboutUs')}>
                <div className={styles.community}>about us</div>
                <img
                  className={styles.chevronDownIcon4}
                  alt=""
                  src="/header/chevron-down.png"
                />
              </div>
            </div>
          </div>
        </>
      )}
      {isOpen && isSubOpen && (
        <div className={styles.mobileDiv}>
          <div className={styles.mobileDepth2}>
            <a href="https://github.com/hippocrat-dao" target="_blank">
              <div className={styles.contact}>Github</div>
            </a>
            <a href="https://twitter.com/hippocrat_io" target="_blank">
              <div className={styles.twitterX}>Twitter / X</div>
            </a>
            <a href="https://discord.com/invite/hpo" target="_blank">
              <div className={styles.discord}>Discord</div>
            </a>
            <a href="https://medium.com/hippocrat" target="_blank">
              <div className={styles.medium}>Medium</div>
            </a>
            <a
              href="https://community.hippocrat.io/c/announcements"
              target="_blank"
            >
              <div className={styles.circle}>Circle</div>
            </a>
            <a href="https://t.me/hippocrat_official" target="_blank">
              <div className={styles.telegram}>Telegram</div>
            </a>
            <a
              href="https://www.linkedin.com/company/hippocratlabs/about/"
              target="_blank"
            >
              <div className={styles.linkedin}>Linkedin</div>
            </a>
            <a href="https://www.instagram.com/med_tip.hippo/" target="_blank">
              <div className={styles.instagram}>Instagram</div>
            </a>

            <div style={{ display: menu === 'ecosystem' ? 'block' : 'none' }}>
              <div className={styles.menuSubTitle}>Ecosystem</div>
              <a href="/protocol">
                <div className={styles.protocol}>protocol</div>
              </a>
              <a href="/hpoToken">
                <div className={styles.hpoToken}>Hpo token</div>
              </a>
              <a href="/dao">
                <div className={styles.dao}>DAO</div>
              </a>
              <div className={styles.whatWeBuild}>WHAT WE BUILD</div>
              <div className={styles.governedBy}>GOVERNED BY</div>
            </div>

            <div style={{ display: menu === 'solutions' ? 'block' : 'none' }}>
              <div className={styles.menuSubTitle}>solutions</div>
              <div className={styles.whatWeBuild}>WHAT WE OFFER</div>
              <a href="/hippodoc">
                <div className={styles.protocol}>HIPPODOC</div>
              </a>
              <a href="/sdk">
                <div className={styles.hpoToken}>sdk</div>
              </a>
              <a href="/p2p">
                <div className={styles.p2p}>p2p</div>
              </a>
            </div>

            <div style={{ display: menu === 'resources' ? 'block' : 'none' }}>
              <div className={styles.menuSubTitle}>resources</div>
              <div className={styles.whatWeBuild}>FROM HIPPOCRAT</div>
              <a href="/roadMap">
                <div className={styles.protocol}>roadmap</div>
              </a>
              <a href="/whitePaper">
                <div className={styles.hpoToken}>whitepaper</div>
              </a>
              <a href="/faq">
                <div className={styles.p2p}>faq</div>
              </a>
              <a href="/blog">
                <div className={styles.blog}>blog</div>
              </a>
              <a href="/news">
                <div className={styles.news}>news</div>
              </a>
              <a href="/pressKit">
                <div className={styles.press}>press kit</div>
              </a>
            </div>

            <div style={{ display: menu === 'learn' ? 'block' : 'none' }}>
              <div className={styles.menuSubTitle}>learn</div>
              <div className={styles.whatWeBuild}>EDUCATION AND LEARNING</div>
              <a href="/learn/web3">
                <div className={styles.protocol}>BLOCKCHAIN & WEB3</div>
              </a>
              <a href="/learn/healthcareData">
                <div className={styles.hpoToken}>
                  SELF-SOVEREIGN HEALTHCARE DATA
                </div>
              </a>
              <a href="/learn/hl7">
                <div className={styles.p2p}>HEALTH LEVEL SEVEN : HL7</div>
              </a>
              <a href="/learn/p2pProtocol">
                <div className={styles.blog}>P2P Protocol</div>
              </a>
            </div>

            <div style={{ display: menu === 'aboutUs' ? 'block' : 'none' }}>
              <div className={styles.menuSubTitle}>learn</div>
              <div className={styles.whatWeBuild}>WHO WE ARE</div>
              <a href="/aboutUs">
                <div className={styles.protocol}>ABOUT HIPPOCRAT</div>
              </a>
            </div>

            <div onClick={() => setSelectMenu()}>
              <div className={styles.back}>Back</div>
              <img
                className={styles.subChevronDownIcon}
                alt=""
                src="/header/chevron-down2.png"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderNav;
