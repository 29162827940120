import { useState, useRef, useEffect } from 'react';
import styles from './aboutUs.module.scss';
import MAboutUsPage from './mIndex';
import LoadingAnimation from '../../components/LoadingAnimation';
import VideoComponent from '../../components/VideoOnReady';

const AboutUsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const totalVideos = 4;
  const [loadedVideos, setLoadedVideos] = useState<number[]>([]);
  const playerRefs = useRef<(any | undefined)[]>(new Array(totalVideos));

  const handleVideoLoaded = (videoNumber: number, player: any) => {
    setLoadedVideos(prev => {
      const updatedLoadedVideos = new Set(prev);
      updatedLoadedVideos.add(videoNumber);

      playerRefs.current[videoNumber - 1] = player;

      if (updatedLoadedVideos.size === totalVideos) {
        setIsLoading(false);
        playerRefs.current.forEach(p => p?.play());
      }

      return Array.from(updatedLoadedVideos);
    });
  };

  return (
    <section className="layoutContainer">
      <LoadingAnimation isLoading={isLoading} />
      <div className="only1000">
        <div className={styles.about1000Child} />
        <div className={styles.about1000Child2}>
          <video
            width="100%"
            preload="metadata"
            muted
            loop
            playsInline
            autoPlay
          >
            <source src="/aboutUs/crat.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="layout1000">
        <div className={styles.about1000}>
          <div className={styles.aboutUsVideo}>
            <VideoComponent
              key={1}
              src="/aboutUs/wearenot.mp4"
              onReady={(videoNumber, player) =>
                handleVideoLoaded(videoNumber, player)
              }
              videoNumber={1}
              className="basic-img-width-100"
            />
          </div>
          <div className={styles.about1000Child1}>
            <video
              width="100%"
              preload="metadata"
              muted
              loop
              playsInline
              autoPlay
            >
              <source src="/aboutUs/hippo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className={styles.parent}>
            <img className={styles.icon} alt="" src="/aboutUs/--2@2x.png" />
            <div className={styles.aboutHippo}>
              <span className={styles.aboutHippoTxtContainer}>
                <span className={styles.weAreA}>
                  <span className={styles.a}>A</span>
                  <span className={styles.b}>B</span>
                </span>
                <i>
                  <span className={styles.o1}>O</span>
                </i>
                <span className={styles.weAreAHippoCh}>
                  <i>{`UT  `}</i>
                  <span className={styles.hip}>HIP</span>
                </span>
                <span className={styles.weAreA}>
                  <span className={styles.p1}>P</span>
                </span>
                <i className={styles.weAreAHippoCh}>O</i>
              </span>
            </div>
            <div className={styles.groupChild} />
            <img className={styles.icon1} alt="" src="/aboutUs/--1@2x.png" />
          </div>
          <div className={styles.margin50}>
            <div className={styles.about1000Item} />
            <div className={styles.about1000Inner} />
            <div className={styles.rectangleDiv} />
            <div className={styles.toCreateA}>
              To create a world where anyone can access personalized healthcare
              anywhere and anytime.
            </div>
            <div className={styles.patientCentricEquityTransContainer}>
              <p
                className={styles.weAreNot300}
              >{`Patient-Centric, Equity, `}</p>
              <p
                className={styles.weAreNot300}
              >{`Transparency, Progressive, `}</p>
              <p className={styles.weAreNot300}>Challenging, Reasonable</p>
            </div>
            <div className={styles.toEmpowerPatientsContainer}>
              <p className={styles.weAreNot300}>
                To empower patients by restoring their sovereignty over
                healthcare data,
              </p>
              <p
                className={styles.weAreNot300}
              >{`and to establish a decentralized healthcare data ecosystem. `}</p>
              <p className={styles.weAreNot300}>
                This will enhance data collection and utilization, contributing
                to personalized care.
              </p>
            </div>
            <div className={styles.mission}>
              <span className={styles.boxTitle}>MISS</span>
              <i className={styles.ion}>ION</i>
            </div>
            <div className={styles.vision}>
              <span className={styles.boxTitle}>VIS</span>
              <i className={styles.ion}>ION</i>
            </div>
            <div className={styles.coreValues}>
              <span className={styles.boxTitle}>{`CORE `}</span>
              <i className={styles.ion}>VALUES</i>
            </div>
            <b className={styles.wePrioritizePatients}>
              We prioritize patients with transparency and fairness, utilizing a
              decentralized DAO for decision-making aligned with mutual
              ecosystem interests.
            </b>
            <div className={styles.hippocratsIdentityIsContainer}>
              <p className={styles.weAreNot300}>
                Hippocrat's identity draws inspiration from the symbiotic bond
                between a hippopotamus, aptly named 'Hippo,' and its bird
                companion, 'Crat.' This relationship mirrors the dynamic in
                healthcare, where professionals 'Crat' forge deep connections
                with their patients 'Hippo', much like the way a bird aids a
                hippo by removing irritants. This analogy beautifully
                encapsulates Hippocrat's commitment to patient-centric care,
                embodying this principle with a refreshing warmth and a deeper,
                more meaningful sentiment.
              </p>
            </div>
            <div className={styles.theSpecialHippoContainer}>
              <p className={styles.weAreNot}>
                <b style={{ fontSize: '18px' }}>
                  The special hippo, armed with decentralized blockchain
                  technology and data sovereignty, embodies a revolutionary
                  intent to overcome inefficiencies in traditional healthcare
                  systems.
                </b>
              </p>
              <p className={styles.weAreNot300}>
                <br />
                In pursuit of our vision and mission for healthcare innovation,
                we are committed to offering personalized healthcare accessible
                to anyone, anywhere, at any time.
              </p>
            </div>
            <div className={styles.doYouKnow}>
              Did you know?
              <br />
              The Story of Hippo & Hippo bird
            </div>
          </div>
        </div>
      </div>
      <div className="only800">
        <div className={styles.about800Child} />
        <div className={styles.cratVideo}>
          <video
            width="100%"
            preload="metadata"
            muted
            loop
            playsInline
            autoPlay
          >
            <source src="/aboutUs/crat.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="layout800">
        <div className={styles.about800}>
          <div className={styles.aboutUsVideo}>
            <VideoComponent
              key={2}
              src="/aboutUs/wearenot.mp4"
              onReady={(videoNumber, player) =>
                handleVideoLoaded(videoNumber, player)
              }
              videoNumber={2}
              className="basic-img-width-100"
            />
          </div>
          <div className={styles.hippoVideo}>
            <video
              width="100%"
              preload="metadata"
              muted
              loop
              playsInline
              autoPlay
            >
              <source src="/aboutUs/hippo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <img className={styles.icon} alt="" src="/aboutUs/--2@2x.png" />
          <div className={styles.about800Item} />
          <img className={styles.icon1} alt="" src="/aboutUs/--1@2x.png" />
          <div className={styles.about800Inner} />
          <div className={styles.rectangleDiv} />
          <div className={styles.about800Child1} />
          <div
            className={styles.toForgeA}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            To forge a future where personalized healthcare is accessible to
            anyone, anywhere at anytime
          </div>
          <div
            className={styles.patientCentricEquityTransContainer}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            <p className={styles.weAreNot}>{`Patient-Centric, Equity, `}</p>
            <p className={styles.weAreNot}>{`Transparency, Progressive, `}</p>
            <p className={styles.weAreNot}>Challenging, Reasonable</p>
          </div>
          <div
            className={styles.toRestoreIndividuals}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            To restore individuals’ healthcare data sovereignty and reform
            inefficient healthcare systems through a decentralized data
            ecosystem, ultimately harnessing health data management and
            utilization to contribute to personalized healthcare and raise the
            global standard.
          </div>
          <div className={styles.mission}>
            <span
              className={styles.weAreAHippoCh}
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              MISS
            </span>
            <i className={styles.ion}>ION</i>
          </div>
          <div className={styles.vision}>
            <span
              className={styles.weAreAHippoCh}
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              VIS
            </span>
            <i className={styles.ion}>ION</i>
          </div>
          <div className={styles.coreValues}>
            <span
              className={styles.weAreAHippoCh}
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`CORE `}</span>
            <i className={styles.ion}>VALUES</i>
          </div>
          <b className={styles.wePrioritizePatients}>
            We prioritize patients with transparency and fairness, utilizing a
            decentralized DAO for decision-making aligned with mutual ecosystem
            interests.
          </b>
          <div
            className={styles.hippocratsIdentityDraws}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Hippocrat's identity draws inspiration from the symbiotic bond
            between a hippopotamus, aptly named 'Hippo,' and its bird companion,
            'Crat.' This relationship mirrors the dynamic in healthcare, where
            professionals 'Crat' forge deep connections with their patients
            'Hippo', much like the way a bird aids a hippo by removing
            irritants. This analogy beautifully encapsulates Hippocrat's
            commitment to patient-centric care, embodying this principle with a
            refreshing warmth and a deeper, more meaningful sentiment.
          </div>
          <div className={styles.theSpecialHippoContainer}>
            <p className={styles.weAreNot}>
              <b style={{ fontSize: '16px' }}>
                The special hippo, equipped with decentralized blockchain
                technology and data sovereignty, symbolizes a revolutionary
                ambition to address inefficiencies in traditional healthcare
                systems.
              </b>
              <span className={styles.weAreA}> </span>
            </p>
            <p
              className={styles.weAreNot}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              <br />
              In pursuit of our vision and mission for healthcare innovation, we
              are committed to offering personalized healthcare accessible to
              anyone, anywhere, at any time.
            </p>
          </div>
          <div className={styles.didYouKnowContainer}>
            <p
              className={styles.weAreNot}
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              Did you know?
            </p>
            <p
              className={styles.weAreNot}
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`The Story of Hippo & Hippo bird`}</p>
          </div>
          <div className={styles.aboutHippo}>
            <span className={styles.aboutHippoTxtContainer}>
              <span
                className={styles.weAreA}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                <span className={styles.weAreAHippoCh}>A</span>
                <span className={styles.b}>B</span>
              </span>
              <i>
                <span className={styles.o1}>O</span>
              </i>
              <span className={styles.weAreAHippoCh}>
                <i>{`UT  `}</i>
                <span
                  className={styles.weAreA}
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  HIP
                </span>
              </span>
              <span className={styles.weAreA}>
                <span
                  className={styles.p1}
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  P
                </span>
              </span>
              <i className={styles.weAreAHippoCh}>O</i>
            </span>
          </div>
        </div>
      </div>
      <MAboutUsPage handleVideoLoaded={handleVideoLoaded} />
    </section>
  );
};

export default AboutUsPage;
