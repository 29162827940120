import styles from './mIndex.module.scss';

const MP2pPage = () => {
  return (
    <>
      <div className="only500">
        <div className={styles.p2p500Wrapper} />
      </div>
      <div className="layout500" style={{ background: 'var(--color-black)' }}>
        <div className={styles.p2p500}>
          <img className={styles.maskGroupIcon} alt="" src="/p2p/web2.png" />
          <b className={styles.highSecurityWithout}>
            High security without the need for a centralized server
          </b>
          <b className={styles.efficientDataCommunication}>
            Efficient data communication for large-scale transfers
          </b>
          <b className={styles.reducedNetworkTraffic}>
            Reduced network traffic leading to enhanced efficiency
          </b>
          <div className={styles.theAdvantagesOfContainer}>
            <span className={styles.theAdvantagesOfContainer1}>
              <p
                className={styles.theAdvantagesOf}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`The Advantages of P2P `}</p>
              <p className={styles.technology}>
                <i>Technology</i>
              </p>
            </span>
          </div>
          <div className={styles.hippocratP2pStorageContainer}>
            <span className={styles.theAdvantagesOfContainer1}>
              <p
                className={styles.theAdvantagesOf}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`Hippocrat P2P `}</p>
              <p className={styles.technology}>
                <i>Storage Service</i>
              </p>
            </span>
          </div>
          <div className={styles.p2pStorage} style={{ lineHeight: '32px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`P2P `}</span>
            <i className={styles.storage}>Storage</i>
          </div>
          <div className={styles.decentralizedStorageSystem}>
            DECENTRALIZED STORAGE SYSTEM
          </div>
          <b className={styles.p2pDataTransmission}>
            P2P: Data transmission & management using technology.
            <br />
            Hippocrat utilizes P2P technology to implement secure data
            communication.
          </b>
          <div
            className={styles.boostsTheEfficiency}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Boosts the efficiency of transferring large files through direct
            connections with data subjects.
          </div>
          <b
            className={styles.highEfficiencyLargeFile}
          >{`High-efficiency large file transfer & management`}</b>
          <div
            className={styles.p2pCommunicationUsed}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            P2P communication, used in blockchain, involves direct data exchange
            between clients without intermediary servers. Utilizing technologies
            like Nostr, Holepunch, and DIDComm, it provides advantages over
            traditional client-server approaches.
          </div>
          <div className={styles.p2p500Child} />
          <div className={styles.p2p500Item} />
          <div
            className={styles.providesAReliable}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Provides a reliable real-time streaming service in use cases such as
            patient doctor interactions, leveraging data effectively.
          </div>
          <b className={styles.realTimeStreaming}>{`Real-time streaming `}</b>
          <div
            className={styles.utilizesP2pCommunication}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Utilizes P2P communication like IPFS to distribute and store data,
            enhancing data security and stability.
          </div>
          <b
            className={styles.distributedDataStorage}
          >{`Distributed data storage `}</b>
          <div className={styles.p2p500Inner}>
            <div>
              <video
                width="100%"
                preload="metadata"
                muted
                loop
                playsInline
                autoPlay
              >
                <source src="/p2p/p2p.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <img
            className={styles.maskGroupIcon1}
            alt=""
            src="/p2p/plasBlue.png"
          />
          <div
            className={styles.openSource}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            OPEN SOURCE
          </div>
          <b className={styles.github}>GitHub</b>
          <b className={styles.developedOpenlyAcrossContainer}>
            <p className={styles.theAdvantagesOf}>
              Developed openly across repositories,
            </p>
            <p className={styles.theAdvantagesOf}>
              it will be available soon. Feel free to join the development
              anytime!
            </p>
          </b>
          <img className={styles.maskGroupIcon2} alt="" src="/p2p/circle.png" />
          <img
            className={styles.maskGroupIcon3}
            alt=""
            src="/p2p/starLine.png"
          />
          <img className={styles.maskGroupIcon4} alt="" src="/p2p/inf.png" />
          <img className={styles.maskGroupIcon5} alt="" src="/p2p/star.png" />
          <img
            className={styles.maskGroupIcon6}
            alt=""
            src="/p2p/heartLine.png"
          />
          <img
            className={styles.maskGroupIcon7}
            alt=""
            src="/p2p/mask-group@2x.png"
          />
        </div>
      </div>
      <div className="only360"></div>
      <div className="layout360" style={{ background: 'var(--color-black)' }}>
        <div className={styles.p2p360}>
          <img className={styles.maskGroupIcon} alt="" src="/p2p/web2.png" />
          <img
            className={styles.maskGroupIcon1}
            alt=""
            src="/p2p/plasBlue.png"
          />

          <b className={styles.highSecurityWithout}>
            High security without the need for a centralized server
          </b>
          <b className={styles.efficientDataCommunication}>
            Efficient data communication for large-scale transfers
          </b>
          <b className={styles.reducedNetworkTraffic}>
            Reduced network traffic leading to enhanced efficiency
          </b>
          <div className={styles.theAdvantagesOfContainer}>
            <span className={styles.theAdvantagesOfContainer1}>
              <p
                className={styles.theAdvantagesOf}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`The Advantages of P2P `}</p>
              <p className={styles.technology}>
                <i>Technology</i>
              </p>
            </span>
          </div>
          <div className={styles.hippocratP2pStorageContainer}>
            <span className={styles.theAdvantagesOfContainer1}>
              <p
                className={styles.theAdvantagesOf}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`Hippocrat P2P `}</p>
              <p className={styles.technology}>
                <i>Storage Service</i>
              </p>
            </span>
          </div>
          <div className={styles.p2pStorage} style={{ lineHeight: '25px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`P2P `}</span>
            <i className={styles.storage}>Storage</i>
          </div>
          <div className={styles.decentralizedStorageSystem}>
            DECENTRALIZED STORAGE SYSTEM
          </div>
          <b className={styles.p2pDataTransmission}>
            P2P: Data transmission & management using technology.
            <br />
            Hippocrat utilizes P2P technology to implement secure data
            communication.
          </b>
          <div
            className={styles.boostsTheEfficiency}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Boosts the efficiency of transferring large files through direct
            connections with data subjects.
          </div>
          <b
            className={styles.highEfficiencyLargeFile}
          >{`High-efficiency large file transfer & management`}</b>
          <div
            className={styles.p2pCommunicationUsed}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            P2P communication, used in blockchain, involves direct data exchange
            between clients without intermediary servers. Utilizing technologies
            like Nostr, Holepunch, and DIDComm, it provides advantages over
            traditional client-server approaches.
          </div>
          <div className={styles.p2p360Child} />
          <div className={styles.p2p360Item} />
          <div
            className={styles.providesAReliable}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Provides a reliable real-time streaming service in use cases such as
            patient doctor interactions, leveraging data effectively.
          </div>
          <b className={styles.realTimeStreaming}>{`Real-time streaming `}</b>
          <div
            className={styles.utilizesP2pCommunication}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Utilizes P2P communication like IPFS to distribute and store data,
            enhancing data security and stability.
          </div>
          <b
            className={styles.distributedDataStorage}
          >{`Distributed data storage `}</b>
          <div className={styles.p2p360Inner}>
            <div>
              <video
                width="100%"
                preload="metadata"
                muted
                loop
                playsInline
                autoPlay
              >
                <source src="/p2p/p2p.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div
            className={styles.openSource}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            OPEN SOURCE
          </div>
          <b className={styles.github}>GitHub</b>
          <b className={styles.developedOpenlyAcrossContainer}>
            <p className={styles.theAdvantagesOf}>
              Developed openly across repositories,
            </p>
            <p className={styles.theAdvantagesOf}>
              it will be available soon. Feel free to join the development
              anytime!
            </p>
          </b>
          <img className={styles.maskGroupIcon2} alt="" src="/p2p/circle.png" />
          <img
            className={styles.maskGroupIcon3}
            alt=""
            src="/p2p/starLine.png"
          />
          <img className={styles.maskGroupIcon4} alt="" src="/p2p/inf.png" />
          <img className={styles.maskGroupIcon5} alt="" src="/p2p/star.png" />
          <img
            className={styles.maskGroupIcon6}
            alt=""
            src="/p2p/heartLine.png"
          />
          <img
            className={styles.maskGroupIcon7}
            alt=""
            src="/p2p/mask-group@2x.png"
          />
        </div>
      </div>
    </>
  );
};

export default MP2pPage;
