import styles from './mIndex.module.scss';

const MLearnhl7Page = () => {
  return (
    <>
      <div className="only500">
        <div className={styles.learn500Child} />
      </div>
      <div className="layout500">
        <div className={styles.learn500}>
          <div
            className={styles.hippocratActivelyUtilizes}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Hippocrat actively utilizes HL7 standards to address
            interoperability challenges in healthcare data. Hippocrat adheres to
            HL7 standards for exchanging and storing medical data. Moreover,
            Hippocrat integrates medical data into the blockchain platform,
            enhancing interoperability among diverse healthcare systems. This
            enables efficient exchange and sharing of healthcare data, fostering
            seamless collaboration among patients, hospitals, and other medical
            institutions.
          </div>
          <div className={styles.hl7HealthLevelContainer}>
            <p
              className={styles.hl7HealthLevel}
              style={{ fontFamily: 'var(--font-inter)' }}
            >{`HL7 (Health Level Seven) is a non-profit organization that develops international standards for exchanging and sharing information in the healthcare industry. HL7 standards support integration and interoperability among various healthcare information systems. `}</p>
            <p className={styles.hl7HealthLevel}>&nbsp;</p>
            <p
              className={styles.hl7HealthLevel}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              HL7 defines standardized message formats for exchanging data
              between healthcare systems and specifies the structure and coding
              methods for medical data to ensure consistency and
              interoperability.
            </p>
          </div>
          <b className={styles.hippocratsApproachToContainer}>
            <p className={styles.hl7HealthLevel}>{`Hippocrat’s `}</p>
            <p className={styles.hl7HealthLevel}>approach to HL7</p>
          </b>
          <b className={styles.howHl7AchieveContainer}>
            <p className={styles.hl7HealthLevel}>{`How HL7 achieve `}</p>
            <p className={styles.hl7HealthLevel}>interoperability?</p>
          </b>
          <div
            className={styles.interoperabilityIssuesIn}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Interoperability issues in healthcare data are significant
            challenges in the medical field. Different systems and devices store
            and transmit data in varying formats, making data exchange and
            sharing difficult. This lack of interoperability hampers efficient
            healthcare service delivery.
          </div>
          <b className={styles.issueOfInteroperability}>
            Issue of interoperability in healthcare data
          </b>
          <div
            className={styles.healthLevelSevenContainer}
            style={{ lineHeight: '32px' }}
          >
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              Health Lev
            </span>
            <b className={styles.el}>el</b>
            <span>{` `}</span>
            <i className={styles.el}>Seven</i>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              {' '}
              : HL7
            </span>
          </div>
          <div className={styles.theComingFuture}>The coming future</div>
          <div className={styles.learn500Item} />
          <div className={styles.learn500Inner} />
          <a href="/learn/p2pProtocol">
            <div className={styles.nextUseCaseContainer}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                NE
              </span>
              <b>X</b>
              <b className={styles.el}>T</b>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                {' '}
                USE
              </span>
              <i className={styles.el}> CASE</i>
            </div>
            <b className={styles.p2pProtocol}>P2P Protocol</b>
            <img
              className={styles.chevronLeftIcon}
              alt=""
              src="/learn/chevronleft@2x.png"
            />
          </a>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/learn/mhl7.png"
            style={{ pointerEvents: 'none' }}
          />
        </div>
      </div>
      <div className="only360">
        <div className={styles.learn360Child} />
        <div
          className={styles.healthLevelSevenContainer}
          style={{ lineHeight: '25px' }}
        >
          <span
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            Health Lev
          </span>
          <b className={styles.el}>el</b>
          <span
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >{` `}</span>
          <i className={styles.el}>Seven</i>
          <span
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            {' '}
            : HL7
          </span>
        </div>
      </div>
      <div className="layout360">
        <div className={styles.learn360}>
          <div className={styles.hippocratActivelyUtilizes}>
            Hippocrat actively utilizes HL7 standards to address
            interoperability challenges in healthcare data. Hippocrat adheres to
            HL7 standards for exchanging and storing medical data. Moreover,
            Hippocrat integrates medical data into the blockchain platform,
            enhancing interoperability among diverse healthcare systems. This
            enables efficient exchange and sharing of healthcare data, fostering
            seamless collaboration among patients, hospitals, and other medical
            institutions.
          </div>
          <div className={styles.hl7HealthLevelContainer}>
            <p
              className={styles.hl7HealthLevel}
            >{`HL7 (Health Level Seven) is a non-profit organization that develops international standards for exchanging and sharing information in the healthcare industry. HL7 standards support integration and interoperability among various healthcare information systems. `}</p>
            <p className={styles.hl7HealthLevel}>&nbsp;</p>
            <p className={styles.hl7HealthLevel}>
              HL7 defines standardized message formats for exchanging data
              between healthcare systems and specifies the structure and coding
              methods for medical data to ensure consistency and
              interoperability.
            </p>
          </div>
          <b className={styles.hippocratsApproachToContainer}>
            <p className={styles.hl7HealthLevel}>{`Hippocrat’s `}</p>
            <p className={styles.hl7HealthLevel}>approach to HL7</p>
          </b>
          <b className={styles.howHl7AchieveContainer}>
            <p className={styles.hl7HealthLevel}>{`How HL7 achieve `}</p>
            <p className={styles.hl7HealthLevel}>interoperability?</p>
          </b>

          <div className={styles.interoperabilityIssuesIn}>
            Interoperability issues in healthcare data are significant
            challenges in the medical field. Different systems and devices store
            and transmit data in varying formats, making data exchange and
            sharing difficult. This lack of interoperability hampers efficient
            healthcare service delivery.
          </div>
          <b className={styles.issueOfInteroperability}>
            Issue of interoperability in healthcare data
          </b>
          <div className={styles.theComingFuture}>The coming future</div>
          <div className={styles.learn360Item} />
          <a href="/learn/p2pProtocol">
            <div className={styles.nextUseCaseContainer}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                NE
              </span>
              <b>X</b>
              <b className={styles.el}>T</b>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                {' '}
                USE
              </span>
              <i className={styles.el}> CASE</i>
            </div>
            <b className={styles.p2pProtocol}>P2P Protocol</b>
            <img
              className={styles.chevronLeftIcon}
              alt=""
              src="/learn/chevronleft@2x.png"
            />
          </a>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/learn/mhl7.png"
            style={{ pointerEvents: 'none' }}
          />
        </div>
      </div>
    </>
  );
};

export default MLearnhl7Page;
