import styles from './mIndex.module.scss';

const MMediaKitPage = () => {
  return (
    <>
      <div className="only500">
        <div className={styles.presskit500Child} />
      </div>
      <div className="layout500">
        <div className={styles.presskit500}>
          <div className={styles.pressKit}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`PRESS `}</span>
            <i className={styles.kit}>KIT</i>
          </div>
          <div
            className={styles.allYourMedia}
          >{`ALL yOUR MEDIA & PRESS NEEDS`}</div>
          <div
            className={styles.brandIdentity}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            BRAND IDENTITY
          </div>
          <b className={styles.signitureLogo}>Signiture Logo</b>
          <b className={styles.monoLogo}>Mono Logo</b>
          <b className={styles.characterLogo}>Character Logo</b>
          <div className={styles.aLogoCan}>
            A logo can be used as both a symbol and a signature, and it should
            be used without variations in its form, color, style, or combination
            with other graphic elements for consistent communication.
          </div>
          <div className={styles.itCanBe}>
            It can be used in black and white, and for consistent communication,
            it should be used as is, without variations in its form, color,
            style, or combination with other graphic elements.
          </div>
          <div className={styles.itCanBe1}>
            It can be used in black and white, and for consistent communication,
            it should be used as is, without variations in its form, color,
            style, or combination with other graphic elements.
          </div>
          <div className={styles.presskit500Item} />
          <div className={styles.presskit500Inner} />
          <div className={styles.rectangleDiv} />
          <div className={styles.presskit500Child1} />
          <div
            className={styles.download}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/signitureLogo.png'}
              download={'hippocrat_signiture_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.download1}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/monoLogo.png'}
              download={'hippocrat_mono_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.download2}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/characterLogo.png'}
              download={'hippocrat_symbol_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.download3}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
              color: 'white',
            }}
          >
            <a
              href={'/pressKit/monoLogoWhite.png'}
              download={'hippocrat_mono_logo_white.png'}
              style={{ color: 'white' }}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.download4}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/characterSymbolLogo.png'}
              download={'hippocrat_character_symbol_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.brandGuideBook}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            BRAND GUIDE BOOK
          </div>
          <div className={styles.ifYouWant}>
            If you want to check detailed information such as logos, characters,
            typography, etc., please download the brand guidebook. The brand
            guidebook will be continuously updated.
          </div>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/pressKit/brand.png"
          />
          <div
            className={styles.download6}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/Hippocrat_brand_guidebook_FIN.pdf'}
              download={'Hippocrat_brand_guidebook_FIN.pdf'}
            >
              DOWNLOAD
            </a>
          </div>
          <img
            className={styles.groupIcon}
            alt=""
            src="/pressKit/monoLogoWhite.png"
          />
          <img
            className={styles.presskit500Child2}
            alt=""
            src="/pressKit/signitureLogo.png"
          />
          <img
            className={styles.presskit500Child3}
            alt=""
            src="/pressKit/characterSymbolLogo.png"
          />
          <img
            className={styles.presskit500Child4}
            alt=""
            src="/pressKit/characterLogo.png"
          />
          <img
            className={styles.presskit500Child5}
            alt=""
            src="/pressKit/monoLogo.png"
          />
        </div>
      </div>
      <div className="only360">
        <div className={styles.presskit360Child} />
      </div>
      <div className="layout360">
        <div className={styles.presskit360}>
          <div className={styles.exploreTheWorld}>
            Explore the world of Web3 blockchain in healthcare data through more
            news about Hippo! Check out the latest updates.
          </div>
          <div className={styles.pressKit} style={{ lineHeight: '25px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`PRESS `}</span>
            <i className={styles.kit}>KIT</i>
          </div>
          <div
            className={styles.allYourMedia}
          >{`ALL yOUR MEDIA & PRESS NEEDS`}</div>
          <div
            className={styles.brandIdentity}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            BRAND IDENTITY
          </div>
          <b className={styles.signitureLogo}>Signiture Logo</b>
          <b className={styles.monoLogo}>Mono Logo</b>
          <b className={styles.characterLogo}>Character Logo</b>
          <div className={styles.aLogoCanContainer}>
            <p className={styles.aLogoCan}>
              A logo can be used as both a symbol and a signature, and it should
              be used without variations in its form, color, style, or
              combination with other graphic elements for consistent
              communication.
            </p>
          </div>
          <div className={styles.itCanBe}>
            It can be used in black and white, and for consistent communication,
            it should be used as is, without variations in its form, color,
            style, or combination with other graphic elements.
          </div>
          <div className={styles.itCanBe1}>
            It can be used in black and white, and for consistent communication,
            it should be used as is, without variations in its form, color,
            style, or combination with other graphic elements.
          </div>
          <div className={styles.presskit360Item} />
          <div className={styles.presskit360Inner} />
          <div className={styles.rectangleDiv} />
          <div className={styles.presskit360Child1} />
          <div className={styles.presskit360Child2} />
          <div className={styles.download}>
            <a
              href={'/pressKit/signitureLogo.png'}
              download={'hippocrat_signiture_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>
          <div className={styles.download1}>
            <a
              href={'/pressKit/monoLogo.png'}
              download={'hippocrat_mono_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>
          <div className={styles.download2}>
            <a
              href={'/pressKit/monoLogoWhite.png'}
              download={'hippocrat_mono_logo_white.png'}
              style={{ color: 'white' }}
            >
              DOWNLOAD
            </a>
          </div>
          <div className={styles.download3}>
            <a
              href={'/pressKit/characterLogo.png'}
              download={'hippocrat_symbol_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.download4}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <a
              href={'/pressKit/characterSymbolLogo.png'}
              download={'hippocrat_character_symbol_logo.png'}
            >
              DOWNLOAD
            </a>
          </div>
          <div
            className={styles.brandGuideBook}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            BRAND GUIDE BOOK
          </div>
          <div className={styles.ifYouWant}>
            If you want to check detailed information such as logos, characters,
            typography, etc., please download the brand guidebook. The brand
            guidebook will be continuously updated.
          </div>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/pressKit/brand.png"
          />
          <div className={styles.download5}>
            <a
              href={'/pressKit/Hippocrat_brand_guidebook_FIN.pdf'}
              download={'Hippocrat_brand_guidebook_FIN.pdf'}
            >
              DOWNLOAD
            </a>
          </div>
          <img
            className={styles.groupIcon}
            alt=""
            src="/pressKit/signitureLogo.png"
          />
          <img
            className={styles.presskit360Child3}
            alt=""
            src="/pressKit/monoLogo.png"
          />
          <img
            className={styles.presskit360Child4}
            alt=""
            src="/pressKit/monoLogoWhite.png"
          />
          <img
            className={styles.presskit360Child5}
            alt=""
            src="/pressKit/characterLogo.png"
          />
          <img
            className={styles.presskit360Child6}
            alt=""
            src="/pressKit/characterSymbolLogo.png"
          />
        </div>
      </div>
    </>
  );
};

export default MMediaKitPage;
