import styles from './mIndex.module.scss';
import VideoComponent from '../../components/VideoOnReady';

interface MPageProps {
  handleVideoLoaded: (videoNumber: number, player: any) => void;
}

const MHpoTokenPage: React.FC<MPageProps> = ({ handleVideoLoaded }) => {
  return (
    <>
      <div className="only500">
        <div style={{ marginTop: '90px' }}>
          <VideoComponent
            key={3}
            src="/hpoToken/mHpoToken.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={3}
            className="basic-video-img"
          />
        </div>
        <div
          className="basic-text-500"
          style={{
            marginTop: 'calc(50 * var(--vh) - 300px)',
          }}
        >
          <div className={styles.sustainableBenefit}>SUSTAINABLE BENEFIT</div>
          <div className={styles.hippocratToken} style={{ lineHeight: '32px' }}>
            <span className={styles.hippocratTokenTxtContainer}>
              <span>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                  className={styles.hipp}
                >
                  HIPP
                </span>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                  className={styles.o}
                >
                  O
                </span>
              </span>
              <i className={styles.crat}>CRAT</i>
              <span className={styles.hipp}>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                    marginLeft: '15px',
                  }}
                >
                  {' '}
                  TOKE
                </span>
                <b className={styles.n}>N</b>
              </span>
            </span>
          </div>
          <b className={styles.theHpoToken}>
            The HPO token drives the innovation and growth. Your contributions,
            powered by this token, help shaping our shared future.
          </b>
        </div>
      </div>
      <div className="layout500">
        <div className={styles.token500}>
          <div className={styles.token500Child} />
          <div className={styles.token500Item}>
            <video
              width="100%"
              preload="metadata"
              muted
              loop
              playsInline
              autoPlay
            >
              <source src="/hpoToken/coin_color.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <b className={styles.totalTokenSupply}>Total token supply</b>
          <div className={styles.div}>1,084,734,273,38</div>
          <div className={styles.hpoIsA}>
            HPO is a rebranded token from HUM. Following the rebranding, a hard
            fork to the HPO token took place in the second quarter of 2023.
          </div>
          <div className={styles.annotation}>*annotation</div>
          <b className={styles.daoCommunity}>{`DAO & Community`}</b>
          <b className={styles.earlyEcosystemParticipants}>
            Early ecosystem participants
          </b>
          <b className={styles.b}>130,000,000</b>
          <b className={styles.b1}>41,325,000</b>
          <div className={styles.token500Inner} />
          <div className={styles.rectangleDiv} />
          <div className={styles.tokenAllocation}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              TOKEN ALLO
            </span>
            <i className={styles.n}>CATION</i>
          </div>
          <div className={styles.listingExchange}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`LISTING `}</span>
            <i className={styles.n}>EXCHANGE</i>
          </div>
          <a
            href="https://upbit.com/exchange?code=CRIX.UPBIT.KRW-HPO"
            target="_blank"
          >
            <img
              className={styles.image54Icon}
              alt=""
              src="/home/image-54@2x.png"
            />
          </a>
          <a
            href="https://coinone.co.kr/exchange/trade/hpo/krw"
            target="_blank"
          >
            <img
              className={styles.image56Icon}
              alt=""
              src="/home/image-56@2x.png"
            />
          </a>
          <a href="https://www.gopax.co.kr/exchange/hpo-krw" target="_blank">
            <img
              className={styles.image55Icon}
              alt=""
              src="/home/image-55@2x.png"
            />
          </a>
          <div className={styles.deriveAccountAddressContainer}>
            <span>{`Derive account, address, child of the address `}</span>
            <span className={styles.followingBip3244}>
              (following bip32, 44, 84)
            </span>
          </div>
        </div>
      </div>
      <div className="only360">
        <div style={{ marginTop: '90px' }}>
          <VideoComponent
            key={4}
            src="/hpoToken/mHpoToken.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={4}
            className="basic-video-img"
          />
        </div>
        <div
          className="basic-text-360"
          style={{
            marginTop: 'calc(50 * var(--vh) - 280px)',
          }}
        >
          <div className={styles.sustainableBenefit}>SUSTAINABLE BENEFIT</div>
          <div className={styles.hippocratToken} style={{ lineHeight: '25px' }}>
            <span className={styles.hippocratTokenTxtContainer}>
              <span>
                <span
                  className={styles.hipp}
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  HIPP
                </span>
                <span
                  className={styles.o}
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  O
                </span>
              </span>
              <i className={styles.crat}>CRAT</i>
              <span className={styles.hipp}>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                    marginLeft: '15px',
                  }}
                >
                  {' '}
                  TOKE
                </span>
                <b className={styles.n}>N</b>
              </span>
            </span>
          </div>
          <b className={styles.theHpoToken}>
            The HPO token drives the innovation and growth. Your contributions,
            powered by this token, help shaping our shared future.
          </b>
        </div>
      </div>
      <div className="layout360">
        <div className={styles.token360}>
          <div className={styles.token360Child} />
          <div className={styles.token360Item}>
            <video
              width="100%"
              preload="metadata"
              muted
              loop
              playsInline
              autoPlay
            >
              <source src="/hpoToken/coin_color.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <b className={styles.totalTokenSupply}>Total token supply</b>
          <div className={styles.div}>1,084,734,273,38</div>
          <div className={styles.hpoIsA}>
            HPO is a rebranded token from HUM. Following the rebranding, a hard
            fork to the HPO token took place in the second quarter of 2023.
          </div>
          <div className={styles.annotation}>*annotation</div>
          <b className={styles.daoCommunity}>{`DAO & Community`}</b>
          <b className={styles.earlyEcosystemParticipants}>
            Early ecosystem participants
          </b>
          <b className={styles.b}>130,000,000</b>
          <b className={styles.b1}>41,325,000</b>
          <div className={styles.token360Inner} />
          <div className={styles.rectangleDiv} />
          <div className={styles.tokenAllocation}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              TOKEN ALLO
            </span>
            <i className={styles.n}>CATION</i>
          </div>
          <div className={styles.listingExchange}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`LISTING `}</span>
            <i className={styles.n}>EXCHANGE</i>
          </div>
          <a
            href="https://upbit.com/exchange?code=CRIX.UPBIT.KRW-HPO"
            target="_blank"
          >
            <img
              className={styles.image54Icon}
              alt=""
              src="/home/image-54@2x.png"
            />
          </a>
          <a
            href="https://coinone.co.kr/exchange/trade/hpo/krw"
            target="_blank"
          >
            <img
              className={styles.image56Icon}
              alt=""
              src="/home/image-56@2x.png"
            />
          </a>
          <a href="https://www.gopax.co.kr/exchange/hpo-krw" target="_blank">
            <img
              className={styles.image55Icon}
              alt=""
              src="/home/image-55@2x.png"
            />
          </a>
          <div className={styles.deriveAccountAddressContainer}>
            <span>{`Derive account, address, child of the address `}</span>
            <span className={styles.followingBip3244}>
              (following bip32, 44, 84)
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MHpoTokenPage;
