import React, { useEffect } from 'react';
import { HippoRouterProvider } from './providers/RouterProvider';
import './global.scss';
import './assets/icon.scss';

const App = () => {
  useEffect(() => {
    const setVhVariable = (): void => {
      const vh: number = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', setVhVariable);

    setVhVariable();

    return (): void => {
      window.removeEventListener('resize', setVhVariable);
    };
  }, []);

  return <HippoRouterProvider />;
};

export default App;
