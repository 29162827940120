import styles from './learn.module.scss';
import MLearnHealthcareDataPage from './mIndex';

const LearnHealthcareDataPage = () => {
  return (
    <section className="layoutContainer">
      <div className="only1000">
        <div className={styles.learn01Child} />
      </div>
      <div className="layout1000">
        <div className={styles.learn01}>
          <div className={styles.itsUnrealisticForContainer}>
            <p
              className={styles.itsUnrealisticFor}
            >{`It’s unrealistic for end user to manage his own database for healthcare data. What mattes is that the data does not belong to anyone but patient. To achieve this, Hippocrat is utilizing decentralized storage - which does not belong to anyone - and Decentralized Identity(DID) for each patient so that the data can be only accessible when the patient grants it. `}</p>
            <p className={styles.itsUnrealisticFor}>&nbsp;</p>
            <p className={styles.itsUnrealisticFor}>
              Hippocrat provides public open source to create Hippocrat DID and
              tools to authenticate and encrypt which will achieve
              self-sovereignty without imposing any burden to end user.
            </p>
          </div>
          <div className={styles.itHasBeenContainer}>
            <p
              className={styles.itsUnrealisticFor}
            >{`It has been a practice that trusted third party takes all the sovereignty of patient’s healthcare data. It has been an obstacle for every stakeholder in healthcare industry. On the patient’s side, patient cannot own and share his or her data to get better medical experience or even make revenue. `}</p>
            <p className={styles.itsUnrealisticFor}>&nbsp;</p>
            <p className={styles.itsUnrealisticFor}>
              On the hospital’s side, it is harder to diagnose properly as it
              cannot easily access to patient’s data stored in the other trusted
              third party. On the pharmaceutical company’s side, it is nearly
              impossible to integrate patient’s data fragmented across multiple
              entities. Hippocrat is to revolutionize this situation by
              integrating healthcare data into one interoperable blockchain
              platform.
            </p>
          </div>
          <b className={styles.howToAchieveContainer}>
            <p className={styles.itsUnrealisticFor700}>{`How to achieve `}</p>
            <p className={styles.itsUnrealisticFor700}>
              self-sovereignty in healthcare data
            </p>
          </b>
          <b className={styles.whyHealthcareData}>Why Healthcare data?</b>
          <div className={styles.visitingHospitalAnd}>
            Visiting hospital and getting clinic service generates your personal
            healthcare data automatically. However, it’s so complicated to
            access our data or history in healthcare field, or even not allowed
            when you are looking for. It’s as absurd as you are not allowed to
            enter our own home - your healthcare data is legitimate property
            like your house. Owner must fully own the right of which belongs to
            him or her, which is self-sovereignty by definition.
          </div>
          <b className={styles.whatIsSelfSovereigntyContainer}>
            <p className={styles.itsUnrealisticFor700}>{`What is `}</p>
            <p className={styles.itsUnrealisticFor700}>self-sovereignty?</p>
          </b>
          <div className={styles.selfSovereignHealthcareDataContainer}>
            <span>Se</span>
            <b className={styles.lf700}>lf</b>
            <span>{`-Sovereign `}</span>
            <i className={styles.lf}>Healthcare</i>
            <span>{` `}</span>
            <i className={styles.lf}>Data</i>
          </div>
          <div className={styles.theComingFuture}>The coming future</div>
          <div className={styles.learn01Item}>
            <img
              alt=""
              src="/learn/healthcareData.png"
              style={{ pointerEvents: 'none' }}
            />
          </div>
          <div className={styles.learn01Inner} />
          <a href="/learn/hl7">
            <div className={styles.nextUseCaseContainer}>
              <span>NE</span>
              <b>X</b>
              <b className={styles.lf}>T</b>
              <span> USE</span>
              <i className={styles.lf}> CASE</i>
            </div>
            <b className={styles.healthLevelSeven}>Health Level Seven: HL7</b>
            <img
              className={styles.chevronLeftIcon}
              alt=""
              src="/learn/chevronleft@2x.png"
            />
          </a>
        </div>
      </div>
      <div className="only800">
        <div className={styles.learn800Child} />
      </div>
      <div className="layout800">
        <div className={styles.learn800}>
          <div className={styles.itsUnrealisticForContainer}>
            <p
              className={styles.itsUnrealisticFor}
              style={{ fontFamily: 'var(--font-inter)' }}
            >{`It’s unrealistic for end user to manage his own database for healthcare data. What mattes is that the data does not belong to anyone but patient. To achieve this, Hippocrat is utilizing decentralized storage - which does not belong to anyone - and Decentralized Identity(DID) for each patient so that the data can be only accessible when the patient grants it. `}</p>
            <p className={styles.itsUnrealisticFor}>&nbsp;</p>
            <p
              className={styles.itsUnrealisticFor}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              Hippocrat provides public open source to create Hippocrat DID and
              tools to authenticate and encrypt which will achieve
              self-sovereignty without imposing any burden to end user.
            </p>
          </div>
          <div className={styles.itHasBeenContainer}>
            <p
              className={styles.itsUnrealisticFor}
              style={{ fontFamily: 'var(--font-inter)' }}
            >{`It has been a practice that trusted third party takes all the sovereignty of patient’s healthcare data. It has been an obstacle for every stakeholder in healthcare industry. On the patient’s side, patient cannot own and share his or her data to get better medical experience or even make revenue. `}</p>
            <p className={styles.itsUnrealisticFor}>&nbsp;</p>
            <p
              className={styles.itsUnrealisticFor}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              On the hospital’s side, it is harder to diagnose properly as it
              cannot easily access to patient’s data stored in the other trusted
              third party. On the pharmaceutical company’s side, it is nearly
              impossible to integrate patient’s data fragmented across multiple
              entities. Hippocrat is to revolutionize this situation by
              integrating healthcare data into one interoperable blockchain
              platform.
            </p>
          </div>
          <b className={styles.howToAchieveContainer}>
            <p className={styles.itsUnrealisticFor}>{`How to achieve `}</p>
            <p className={styles.itsUnrealisticFor}>
              self-sovereignty in healthcare data
            </p>
          </b>
          <b className={styles.whyHealthcareData}>Why Healthcare data?</b>
          <div
            className={styles.visitingHospitalAnd}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Visiting hospital and getting clinic service generates your personal
            healthcare data automatically. However, it’s so complicated to
            access our data or history in healthcare field, or even not allowed
            when you are looking for. It’s as absurd as you are not allowed to
            enter our own home - your healthcare data is legitimate property
            like your house. Owner must fully own the right of which belongs to
            him or her, which is self-sovereignty by definition.
          </div>
          <b className={styles.whatIsSelfSovereigntyContainer}>
            <p className={styles.itsUnrealisticFor}>{`What is `}</p>
            <p className={styles.itsUnrealisticFor}>self-sovereignty?</p>
          </b>
          <div
            className={styles.selfSovereignHealthcareDataContainer}
            style={{ lineHeight: '42px' }}
          >
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              Se
            </span>
            <b className={styles.lf}>lf</b>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`-Sovereign `}</span>
            <i className={styles.lf}>Healthcare</i>
            <span>{` `}</span>
            <i className={styles.lf}>Data</i>
          </div>
          <div className={styles.theComingFuture}>The coming future</div>
          <div className={styles.learn800Item} />
          <a href="/learn/hl7">
            <div className={styles.nextUseCaseContainer}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                NE
              </span>
              <b>X</b>
              <b className={styles.lf}>T</b>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                {' '}
                USE
              </span>
              <i className={styles.lf}> CASE</i>
            </div>
            <b className={styles.selfSovereignHealthcareData}>
              Health Level Seven: HL7
            </b>
            <img
              className={styles.chevronLeftIcon}
              alt=""
              src="/learn/chevronleft@2x.png"
            />
          </a>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/learn/healthcareData.png"
            style={{ pointerEvents: 'none' }}
          />
        </div>
      </div>
      <MLearnHealthcareDataPage />
    </section>
  );
};

export default LearnHealthcareDataPage;
