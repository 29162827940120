import React, { ReactNode } from 'react';

interface AccordionProps {
  title: string;
  children: ReactNode;
  isOpen: boolean;
  onClick: () => void;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  isOpen,
  onClick,
}) => {
  return (
    <div className="accordion-faq">
      <b className="accordion-title" onClick={onClick}>
        {title}{' '}
        {isOpen ? (
          <img className="accordion-arrow" alt="" src="/faq/chevron-up.png" />
        ) : (
          <img className="accordion-arrow" alt="" src="/faq/chevron-down.png" />
        )}
      </b>
      {isOpen && <div>{children}</div>}
    </div>
  );
};

export default Accordion;
