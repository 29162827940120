import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';

declare global {
  interface Window {
    MSStream: any;
  }
}

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window?.MSStream;

interface VideoComponentProps {
  src: string;
  onReady: (videoNumber: number, player: any) => void;
  videoNumber: number;
  className?: string;
}

const VideoComponent: React.FC<VideoComponentProps> = ({
  src,
  onReady,
  videoNumber,
  className,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    let player: Player;
    const existingPlayer = videojs.getPlayer(videoElement);

    if (existingPlayer) {
      player = existingPlayer;
    } else {
      player = videojs(videoElement, {
        preload: isIOS ? 'metadata' : 'auto',
        playsInline: true,
        muted: true,
        loop: true,
        autoPlay: isIOS ? true : false,
      });
    }
    player.on('loadedmetadata', () => {
      onReady(videoNumber, player);
    });
  }, [src]);

  return (
    <div>
      <video
        ref={videoRef}
        className={className}
        preload={isIOS ? 'metadata' : 'auto'}
        playsInline
        muted
        loop
      >
        <source className={className} src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoComponent;
