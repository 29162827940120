import styles from './mIndex.module.scss';

const MSdkPage = () => {
  return (
    <>
      <div className="only500">
        <div className={styles.sdk500Child} />
      </div>
      <div className="layout500">
        <div className={styles.sdk500}>
          <div className={styles.sdk500Item}>
            <div>
              <video
                width="303"
                preload="metadata"
                muted
                loop
                playsInline
                autoPlay
              >
                <source src="/sdk/sdk.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div
            className={styles.itSupportsThe}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            It supports the generation of Health Passport Organization (HPO)
            Decentralized Identifiers (DID) for each user, which is officially
            registered for use in healthcare services. It also enables the
            encryption, decryption, and transmission of data, allowing users to
            have sovereignty over their data.
          </div>
          <div
            className={styles.itFacilitatesThe}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            It facilitates the storage of HPO tokens within the service and
            supports transfers through various layers (Ethereum, Polygon,
            Bitcoin Taro).
          </div>
          <div className={styles.sdkTechnology} style={{ lineHeight: '32px' }}>
            <span className={styles.sdkTechnologyTxtContainer}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`SDK `}</span>
              <i className={styles.technology}>Technology</i>
            </span>
          </div>
          <div className={styles.hipposOriginalTechnology}>
            HIPPO’S ORIGINAL TECHNOLOGY
          </div>
          <b className={styles.theHippocratWalletSdkFacil}>
            The Hippocrat-wallet-sdk facilitates global 3rd-party health
            services to join the ecosystem.
          </b>
          <b className={styles.hpoDidContainer}>
            <span className={styles.sdkTechnologyTxtContainer}>
              <p
                className={styles.hpoDid}
              >{`HPO DID & Encryption Decryption, `}</p>
              <p className={styles.hpoDid}>Transmission of Data</p>
            </span>
          </b>
          <b className={styles.hpoTokenStorageContainer}>
            <span className={styles.sdkTechnologyTxtContainer}>
              <p className={styles.hpoDid}>{`HPO Token Storage &`}</p>
              <p className={styles.hpoDid}>Transfer Across Multiple Layers</p>
            </span>
          </b>
          <div className={styles.detailSpec}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`DETAIL `}</span>
            <b className={styles.technology}>S</b>
            <i className={styles.pec}>PEC</i>
          </div>
          <div className={styles.generateEncryptDecryptContainer}>
            <span>{`Generate, encrypt, decrypt mnemonic `}</span>
            <span className={styles.eciesAesCtrWith}>(following bip39)</span>
          </div>
          <div className={styles.makeTransactionsOn}>
            Make transactions on Ethereum to transfer Hippocrat tokens
          </div>
          <div className={styles.makeTransactionsOn1}>
            Make transactions on Bitcoin for transferring Bitcoin or writing
            data on Bitcoin
          </div>
          <div className={styles.createHippocratDid}>
            Create Hippocrat DID, sign with DID, and verify the signature with
            DID
          </div>
          <div className={styles.encryptDecryptContainer}>
            <span>{`Encrypt & decrypt data following Elliptic Curve Integrated Encryption Scheme `}</span>
            <span className={styles.eciesAesCtrWith}>
              (ECIES) (AES-CTR with ECDH derived key)
            </span>
          </div>
          <div className={styles.sdk500Inner} />
          <div className={styles.lineDiv} />
          <div className={styles.lineDiv2} />
          <div className={styles.tbd}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              TB
            </span>
            <i className={styles.technology}>D</i>
          </div>
          <div className={styles.sdk500Child1} />
          <div
            className={styles.supportForSaving}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Support for saving DIDs on ION, allowing users to choose the chain.
          </div>
          <div
            className={styles.provideTheFunctionality}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Provide the functionality to store and view data on the P2P storage
            operated by Hippocrat, enabling users to manage data easily and
            securely.
          </div>
          <b className={styles.p2pStorage}>P2P storage</b>
          <b className={styles.transferChain}>ION</b>
          <div
            className={styles.variousChainSupport}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Various chain support is planned for the transfer of HPO tokens,
            offering opportunities for a broader range of services to
            participate in our service.
          </div>
          <b className={styles.ion}>Transfer Chain</b>
          <div
            className={styles.openSource}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            OPEN SOURCE
          </div>
          <div className={styles.sdk500Child2} />
          <div
            className={styles.anyoneGloballyCan}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Anyone globally can participate in the development. It is being
            developed in the form of open source in the repository.
          </div>
          <b className={styles.github}>GitHub</b>
          <b className={styles.npm}>NPM</b>
          <div
            className={styles.youCanEasily}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            You can easily download and use it from NPM, importing it into
            healthcare-related application services with support for both web
            browsers and applications.
          </div>
          <div className={styles.image55} />

          <a href="https://github.com/hippocrat-dao" target="_blank">
            <div className={styles.letsGo}>LET’S GO</div>
          </a>
          <a
            href="https://www.npmjs.com/package/hippocrat-wallet-sdk"
            target="_blank"
          >
            <div className={styles.letsGo1}>LET’S GO</div>
          </a>

          <div className={styles.deriveAccountAddressContainer}>
            <span>{`Derive account, address, child of the address `}</span>
            <span className={styles.eciesAesCtrWith}>
              (following bip32, 44, 84)
            </span>
          </div>
          <img className={styles.maskGroupIcon} alt="" src="/sdk/mWeb4.png" />
          <img
            className={styles.maskGroupIcon1}
            alt=""
            src="/sdk/mask-group@2x.png"
          />
          <img
            className={styles.maskGroupIcon2}
            alt=""
            src="/sdk/mBanner2.png"
          />
          <img className={styles.maskGroupIcon3} alt="" src="/sdk/cube.png" />
          <img className={styles.maskGroupIcon4} alt="" src="/sdk/wind.png" />
        </div>
      </div>
      <div className="only360">
        <div className={styles.sdk360Child} />
      </div>
      <div className="layout360">
        <div className={styles.sdk360}>
          <img className={styles.cubeIcon} alt="" src="/sdk/cube.png" />
          <div className={styles.sdk360Item}>
            <div className={styles.sdk500Item}>
              <video
                width="303"
                preload="metadata"
                muted
                loop
                playsInline
                autoPlay
              >
                <source src="/sdk/sdk.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div
            className={styles.itSupportsThe}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            It supports the generation of Health Passport Organization (HPO)
            Decentralized Identifiers (DID) for each user, which is officially
            registered for use in healthcare services. It also enables the
            encryption, decryption, and transmission of data, allowing users to
            have sovereignty over their data.
          </div>
          <div
            className={styles.itFacilitatesThe}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            It facilitates the storage of HPO tokens within the service and
            supports transfers through various layers (Ethereum, Polygon,
            Bitcoin Taro).
          </div>
          <div className={styles.sdkTechnology} style={{ lineHeight: '25px' }}>
            <span className={styles.sdkTechnologyTxtContainer}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`SDK `}</span>
              <i className={styles.technology}>Technology</i>
            </span>
          </div>
          <div className={styles.hipposOriginalTechnology}>
            HIPPO’S ORIGINAL TECHNOLOGY
          </div>
          <b className={styles.theHippocratWalletSdkFacil}>
            The Hippocrat-wallet-sdk facilitates global 3rd-party health
            services to join the ecosystem.
          </b>
          <b className={styles.hpoDidContainer}>
            <span className={styles.sdkTechnologyTxtContainer}>
              <p
                className={styles.hpoDid}
              >{`HPO DID & Encryption Decryption, `}</p>
              <p className={styles.hpoDid}>Transmission of Data</p>
            </span>
          </b>
          <b className={styles.hpoTokenStorageContainer}>
            <span className={styles.sdkTechnologyTxtContainer}>
              <p className={styles.hpoDid}>{`HPO Token Storage &`}</p>
              <p className={styles.hpoDid}>Transfer Across Multiple Layers</p>
            </span>
          </b>
          <div className={styles.detailSpec}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`DETAIL `}</span>
            <b className={styles.technology}>S</b>
            <i className={styles.pec}>PEC</i>
          </div>
          <div className={styles.generateEncryptDecryptContainer}>
            <span>{`Generate, encrypt, decrypt mnemonic `}</span>
            <span className={styles.eciesAesCtrWith}>(following bip39)</span>
          </div>
          <div className={styles.makeTransactionsOn}>
            Make transactions on Ethereum to transfer Hippocrat tokens
          </div>
          <div className={styles.makeTransactionsOn1}>
            Make transactions on Bitcoin for transferring Bitcoin or writing
            data on Bitcoin
          </div>
          <div className={styles.createHippocratDid}>
            Create Hippocrat DID, sign with DID, and verify the signature with
            DID
          </div>
          <div className={styles.encryptDecryptContainer}>
            <span>{`Encrypt & decrypt data following Elliptic Curve Integrated Encryption Scheme `}</span>
            <span className={styles.eciesAesCtrWith}>
              (ECIES) (AES-CTR with ECDH derived key)
            </span>
          </div>
          <div className={styles.sdk360Inner} />
          <div className={styles.lineDiv} />
          <div className={styles.lineDiv2} />
          <div className={styles.tbd}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              TB
            </span>
            <i className={styles.technology}>D</i>
          </div>
          <div className={styles.sdk360Child1} />
          <div
            className={styles.supportForSaving}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Support for saving DIDs on ION, allowing users to choose the chain.
          </div>
          <div
            className={styles.provideTheFunctionality}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Provide the functionality to store and view data on the P2P storage
            operated by Hippocrat, enabling users to manage data easily and
            securely.
          </div>
          <b className={styles.p2pStorage}>P2P storage</b>
          <b className={styles.transferChain}>ION</b>
          <div
            className={styles.variousChainSupport}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Various chain support is planned for the transfer of HPO tokens,
            offering opportunities for a broader range of services to
            participate in our service.
          </div>
          <b className={styles.ion}>Transfer Chain</b>
          <div
            className={styles.openSource}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            OPEN SOURCE
          </div>
          <div className={styles.sdk360Child2} />
          <div
            className={styles.anyoneGloballyCan}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Anyone globally can participate in the development. It is being
            developed in the form of open source in the repository.
          </div>
          <b className={styles.github}>GitHub</b>
          <b className={styles.npm}>NPM</b>
          <div
            className={styles.youCanEasily}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            You can easily download and use it from NPM, importing it into
            healthcare-related application services with support for both web
            browsers and applications.
          </div>

          <a href="https://github.com/hippocrat-dao" target="_blank">
            <div className={styles.letsGo}>LET’S GO</div>
          </a>
          <a
            href="https://www.npmjs.com/package/hippocrat-wallet-sdk"
            target="_blank"
          >
            <div className={styles.letsGo1}>LET’S GO</div>
          </a>
          <div className={styles.deriveAccountAddressContainer}>
            <span>{`Derive account, address, child of the address `}</span>
            <span className={styles.eciesAesCtrWith}>
              (following bip32, 44, 84)
            </span>
          </div>
          <img className={styles.web4} alt="" src="/sdk/mWeb4.png" />
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/sdk/mask-group@2x.png"
          />
          <img
            className={styles.maskGroupIcon1}
            alt=""
            src="/sdk/mBanner2.png"
          />
          <img className={styles.maskGroupIcon2} alt="" src="/sdk/wind.png" />
          {/* <img className={styles.cubeIcon1} alt="" src="/cube@2x.png" /> */}
        </div>
      </div>
    </>
  );
};

export default MSdkPage;
