import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import styles from './mIndex.module.scss';
import VideoComponent from '../../components/VideoOnReady';

interface MPageProps {
  handleVideoLoaded: (videoNumber: number, player: any) => void;
}

const MHippoPage: React.FC<MPageProps> = ({ handleVideoLoaded }) => {
  return (
    <>
      <div className="only500">
        <div className={styles.earthVideo}>
          <video width="500" preload="metadata" muted loop playsInline autoPlay>
            <source src="/hippo/earth.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={styles.solutionInner} />
        <div className={styles.solutionChild} />
        <div className={styles.solutionItem} />
      </div>
      <div className="layout500">
        <div className={styles.solution}>
          <div style={{ marginTop: '-50px' }}>
            <VideoComponent
              key={3}
              src="/hippo/hippo.mp4"
              onReady={(videoNumber, player) =>
                handleVideoLoaded(videoNumber, player)
              }
              videoNumber={3}
              className="basic-img-width-500"
            />
          </div>
          <img
            className={styles.adobestock46112667723Icon}
            alt=""
            src="/hippo/mApp4.png"
          />
          <b className={styles.yourGatewayTo}>
            Your gateway to smarter, faster medical assistance
          </b>
          <b className={styles.personalizedHealthcareSoluti}>
            Personalized Healthcare SOlution
          </b>
          <div
            className={styles.developedByHippocrat}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Developed by Hippocrat Lab, "Hippo" aims to bridge global healthcare
            gaps through remote healthcare consultations. Hippo offers instant
            responses to health queries based on a comprehensive medical
            database and AI technology with access to real, verified medical
            professionals for personalized assistance. Enjoy seamless healthcare
            consultation at your convenience, anywhere, anytime with Hippo.
          </div>
          <b className={styles.alwaysAccessibleHealthContainer}>
            <p
              className={styles.alwaysAccessibleHealth}
            >{`Always accessible health advice. `}</p>
            <p className={styles.alwaysAccessibleHealth}>
              Ask now, know now - wellness at your fingertips
            </p>
          </b>
          <div
            className={styles.hipposTechnology}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            HIPPO’s Technology
          </div>

          <img
            className={styles.image34Icon}
            alt=""
            src="/hippo/image-34@2x.png"
          />
          <div className={styles.joinTheMedicalContainer}>
            <p
              className={styles.alwaysAccessibleHealth}
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >{`Join the `}</p>
            <p
              className={styles.alwaysAccessibleHealth}
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              Medical team
            </p>
          </div>
          <b className={styles.hippoIsOnContainer}>
            <p className={styles.alwaysAccessibleHealth}>
              Hippo is on a mission to elevate global healthcare standards. We
              seek medical professionals committed to bridging healthcare
              disparities worldwide.
            </p>
            <p className={styles.alwaysAccessibleHealth}>
              {' '}
              Join us now and together we can make a more impactful change.
            </p>
          </b>
          <a href="mailto:support@hippocrat.io" target="_blank">
            <div className={styles.letsJoin}>LET’S JOIN!</div>
          </a>
          <div
            className={styles.youCanPay}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            You can pay using popular cryptocurrencies, providing you with a
            secure, efficient, and modern way. Experience the convenience and
            flexibility of digital currency payments.
          </div>
          <div
            className={styles.dataManagementSystem}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Data management system securely shares medical records stored in the
            user's wallet. Hospitals use QR codes for fast verification and
            service provision with user approval, ensuring secure data sharing
            even in privacy concerns.
          </div>
          <div className={styles.cryptocurrencyPayments}>
            Cryptocurrency Payments
          </div>
          <div
            className={styles.medicalDataSovereignty}
          >{`Medical data sovereignty  & security`}</div>
          <div
            className={styles.theHipposOriginal}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            The HIPPO’S ORIGINAL TECHNOLOGY : Hippocrat-wallet-sdk facilitates
            global 3rd-party health services to join the ecosystem.
          </div>
          <b className={styles.sdk}>SDK</b>
          <b className={styles.p2pStorage}>P2P storage</b>
          <div
            className={styles.decentralizedDataTransmissio}
            style={{ fontFamily: 'var(--font-inter)' }}
          >{`Decentralized Data transmission & management: Hippocrat employs P2P technology to facilitate secure data communication.`}</div>
          <a
            href="https://www.npmjs.com/package/hippocrat-wallet-sdk"
            target="_blank"
          >
            <div className={styles.readMore}>READ MORE</div>
          </a>
          <a href="https://github.com/hippocrat-dao" target="_blank">
            <div className={styles.readMore1}>READ MORE</div>
          </a>
          <div className={styles.solutionChild3} />
          <div
            className={styles.accessExpertMedical}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Access expert medical advice at any time, from any location. Our
            service transcends geographical and linguistic barriers to ensure
            you receive the professional support you need, whenever you need it.
          </div>
          <div className={styles.remoteConsultation}>
            24/7 Remote Consultation
          </div>
          <img
            className={styles.adobestock46112667731Icon}
            alt=""
            src="/hippo/mApp.png"
          />
          <img
            className={styles.adobestock46112667724Icon}
            alt=""
            src="/hippo/mApp2.png"
          />
          <img
            className={styles.maskGroupIcon_1}
            alt=""
            src="/hippo/technology.png"
          />
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/hippo/technology1.png"
          />
        </div>
      </div>
      <div className="only360">
        <div className={styles.earthVideo}>
          <video width="500" preload="metadata" muted loop playsInline autoPlay>
            <source src="/hippo/earth.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={styles.solution360Inner} />
        <div className={styles.solution360Child} />
        <div className={styles.solution360Item} />
        <div>
          <Swiper
            slidesPerView={2}
            spaceBetween={150}
            centeredSlides={true}
            pagination={{
              clickable: true,
            }}
            className={styles.mySwiper1}
          >
            <SwiperSlide>
              <img
                alt=""
                src="/hippo/mApp.png"
                className={styles.mySwiperImg}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                alt=""
                src="/hippo/mApp1.png"
                className={styles.mySwiperImg}
              />
            </SwiperSlide>
          </Swiper>
        </div>

        <div>
          <Swiper
            slidesPerView={2}
            spaceBetween={150}
            centeredSlides={true}
            pagination={{
              clickable: true,
            }}
            className={styles.mySwiper2}
          >
            <SwiperSlide>
              <img
                alt=""
                src="/hippo/mApp2.png"
                className={styles.mySwiperImg}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                alt=""
                src="/hippo/mApp3.png"
                className={styles.mySwiperImg}
              />
            </SwiperSlide>
          </Swiper>
        </div>

        <img
          className={styles.adobestock46112667724Icon3}
          alt=""
          src="/hippo/mApp4.png"
        />
      </div>
      <div className="layout360">
        <div className={styles.solution360}>
          <div>
            <VideoComponent
              key={4}
              src="/hippo/hippo.mp4"
              onReady={(videoNumber, player) =>
                handleVideoLoaded(videoNumber, player)
              }
              videoNumber={4}
              className="basic-img-width-100"
            />
          </div>
          <b className={styles.yourGatewayTo}>
            Your gateway to smarter, faster medical assistance
          </b>
          <b className={styles.personalizedHealthcareSoluti}>
            Personalized Healthcare SOlution
          </b>
          <div
            className={styles.developedByHippocrat}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Developed by Hippocrat Lab, "Hippo" aims to bridge global healthcare
            gaps through remote healthcare consultations. Hippo offers instant
            responses to health queries based on a comprehensive medical
            database and AI technology with access to real, verified medical
            professionals for personalized assistance. Enjoy seamless healthcare
            consultation at your convenience, anywhere, anytime with Hippo.
          </div>
          <b className={styles.alwaysAccessibleHealthContainer}>
            <p
              className={styles.alwaysAccessibleHealth}
            >{`Always accessible health advice. `}</p>
            <p className={styles.alwaysAccessibleHealth}>
              Ask now, know now - wellness at your fingertips
            </p>
          </b>
          <div
            className={styles.hipposTechnology}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            HIPPO’s Technology
          </div>

          <img
            className={styles.image34Icon}
            alt=""
            src="/hippo/image-34@2x.png"
          />
          <div className={styles.joinTheMedicalContainer}>
            <p className={styles.alwaysAccessibleHealth}>{`Join the `}</p>
            <p className={styles.alwaysAccessibleHealth}>Medical team</p>
          </div>
          <b className={styles.hippoIsOnContainer}>
            <p className={styles.alwaysAccessibleHealth}>
              Hippo is on a mission to elevate global healthcare standards. We
              seek medical professionals committed to bridging healthcare
              disparities worldwide.
            </p>
            <p className={styles.alwaysAccessibleHealth}>
              {' '}
              Join us now and together we can make a more impactful change.
            </p>
          </b>
          <a href="mailto:support@hippocrat.io" target="_blank">
            <div className={styles.letsJoin}>LET’S JOIN!</div>
          </a>
          <div
            className={styles.youCanPay}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            You can pay using popular cryptocurrencies, providing you with a
            secure, efficient, and modern way. Experience the convenience and
            flexibility of digital currency payments.
          </div>
          <div
            className={styles.dataManagementSystem}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Data management system securely shares medical records stored in the
            user's wallet. Hospitals use QR codes for fast verification and
            service provision with user approval, ensuring secure data sharing
            even in privacy concerns.
          </div>
          <div className={styles.cryptocurrencyPayments}>
            Cryptocurrency Payments
          </div>
          <div className={styles.medicalDataSovereigntyContainer}>
            <p
              className={styles.alwaysAccessibleHealth}
            >{`Medical data sovereignty  `}</p>
            <p className={styles.alwaysAccessibleHealth}>{`& security`}</p>
          </div>
          <div
            className={styles.theHipposOriginal}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            The HIPPO’S ORIGINAL TECHNOLOGY : Hippocrat-wallet-sdk facilitates
            global 3rd-party health services to join the ecosystem.
          </div>
          <b className={styles.sdk}>SDK</b>
          <b className={styles.p2pStorage}>P2P storage</b>
          <div
            className={styles.decentralizedDataTransmissio}
            style={{ fontFamily: 'var(--font-inter)' }}
          >{`Decentralized Data transmission & management: Hippocrat employs P2P technology to facilitate secure data communication.`}</div>
          <a
            href="https://www.npmjs.com/package/hippocrat-wallet-sdk"
            target="_blank"
          >
            <div className={styles.readMore}>READ MORE</div>
          </a>
          <a href="https://github.com/hippocrat-dao" target="_blank">
            <div className={styles.readMore1}>READ MORE</div>
          </a>
          <div
            className={styles.accessExpertMedical}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Access expert medical advice at any time, from any location. Our
            service transcends geographical and linguistic barriers to ensure
            you receive the professional support you need, whenever you need it.
          </div>
          <div className={styles.remoteConsultation}>
            24/7 Remote Consultation
          </div>

          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/hippo/technology1.png"
          />
          <img
            className={styles.maskGroupIcon1}
            alt=""
            src="/hippo/technology.png"
          />
        </div>
      </div>
    </>
  );
};

export default MHippoPage;
