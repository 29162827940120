import React, { useState, useRef, useEffect } from 'react';
import styles from './dao.module.scss';
import MDaoPage from './mIndex';
import LoadingAnimation from '../../components/LoadingAnimation';
import VideoComponent from '../../components/VideoOnReady';

const DaoPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const totalVideos = 4;
  const [loadedVideos, setLoadedVideos] = useState<number[]>([]);
  const playerRefs = useRef<(any | undefined)[]>(new Array(totalVideos));

  const handleVideoLoaded = (videoNumber: number, player: any) => {
    setLoadedVideos(prev => {
      const updatedLoadedVideos = new Set(prev);
      updatedLoadedVideos.add(videoNumber);

      playerRefs.current[videoNumber - 1] = player;

      if (updatedLoadedVideos.size === totalVideos) {
        setIsLoading(false);
        playerRefs.current.forEach(p => p?.play());
      }

      return Array.from(updatedLoadedVideos);
    });
  };

  return (
    <section className="layoutContainer">
      <LoadingAnimation isLoading={isLoading} />
      <div className="only1000">
        <div style={{ marginTop: '90px' }}>
          <VideoComponent
            key={1}
            src="/dao/dao.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={1}
            className="basic-video-img"
          />
          <div
            style={{
              paddingBottom: '30px',
              borderBottomRightRadius: '30px',
              borderBottomLeftRadius: '30px',
              backgroundColor: 'var(--color-black)',
            }}
          />
        </div>
        <div
          className="basic-text-1000"
          style={{
            marginTop: 'calc(50 * var(--vh) - 300px)',
            color: 'var(--color-white)',
          }}
        >
          <div className={styles.dao1} style={{ lineHeight: '48px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              DA
            </span>
            <i className={styles.o}>O</i>
          </div>
          <div className={styles.theCoolestForm}>
            The COolest form of governance
          </div>
          <b className={styles.hippocratDaoStrivesContainer}>
            <p
              className={styles.theGovernance}
            >{`Hippocrat DAO strives for a decentralized structure and autonomous organizations to foster `}</p>
            <p className={styles.theGovernance}>the ecosystem's expansion.</p>
          </b>
        </div>
      </div>
      <div className="layout1000">
        <div className={styles.dao1000}>
          <div className={styles.theGovernanceProcessContainer}>
            <span className={styles.theGovernanceProcessContainer1}>
              <p className={styles.theGovernance}>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  THE GOVERNAN
                </span>
                <i
                  style={{
                    fontWeight: 400,
                    fontFamily: 'var(--font-bodoni-72)',
                  }}
                >{`CE `}</i>
              </p>
              <p className={styles.process}>
                <i>PROCESS</i>
              </p>
            </span>
          </div>
          <div className={styles.dao1000Item} />
          <div className={styles.dao1000Inner} />
          <div className={styles.rectangleDiv} />
          <img
            className={styles.vectorIcon}
            alt=""
            src="/dao/vector-1@2x.png"
          />
          <img
            className={styles.dao1000Child1}
            alt=""
            src="/dao/vector-1@2x.png"
          />
          <div className={styles.dao1000Child2} />
          <div className={styles.toVerifyTheContainer}>
            <p className={styles.theGovernance}>
              To verify the self-sustainability of ecosystems and address any
              vulnerabilities.
            </p>
            <p className={styles.theGovernance}>&nbsp;</p>
            <p className={styles.theGovernance}>&nbsp;</p>
            <p className={styles.theGovernance}>&nbsp;</p>
          </div>
          <div className={styles.toSecureRegionalContainer}>
            <p className={styles.theGovernance}>
              To secure regional user pools and build ecosystem infrastructure.
            </p>
            <p className={styles.theGovernance}>&nbsp;</p>
          </div>
          <div className={styles.exOneYearContainer}>
            <ul className={styles.exRecruitingDaoParticipati}>
              <li>
                Ex) One year following Phase 1, assess if the ecosystem can
                operate voluntarily without intervention from any centralized
                group within the DAO.
              </li>
            </ul>
          </div>
          <div className={styles.phase1Parent}>
            <b className={styles.phase1}>Phase 1</b>
            <div className={styles.groupChild} />
          </div>
          <div className={styles.phase2Parent}>
            <b className={styles.phase1}>Phase 2</b>
            <div className={styles.groupChild} />
          </div>
          <div className={styles.phase3Parent}>
            <b className={styles.phase1}>Phase 3</b>
            <div className={styles.groupChild} />
          </div>
          <div className={styles.exRecruitingDaoContainer}>
            <ul className={styles.exRecruitingDaoParticipati}>
              <li>
                Ex) Recruiting DAO participation members, establishing
                partnerships and investments for ecosystem infrastructure, and
                developing regional doctor and patient user pools.
              </li>
            </ul>
          </div>
          <div className={styles.keironStance}>Keiron Stance</div>
          <div className={styles.asclepiosStance}>Asclepios Stance</div>
          <div className={styles.hippocratesStance}>Hippocrates Stance</div>
          <div className={styles.dao1000Child3} />
          <div className={styles.dao1000Child4} />
          <div className={styles.dao1000Child5} />
          <div className={styles.dao1000Child6} />
          <div className={styles.dao1000Child7} />
          <div
            className={styles.dao}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            DAO
          </div>
          <div className={styles.phase1Group}>
            <b className={styles.phase1}>Phase 1</b>
            <div className={styles.groupChild} />
          </div>
          <div
            className={styles.governanceProtocol}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            Governance Protocol
          </div>
          <div className={styles.dao1000Child8} />
          <div className={styles.dao1000Child9} />
          <div className={styles.dao1000Child10} />
          <img
            className={styles.polygonIcon}
            alt=""
            src="/dao/polygon-10.svg"
          />
          <img
            className={styles.dao1000Child11}
            alt=""
            src="/dao/polygon-11.svg"
          />
          <div className={styles.phase2Group}>
            <b className={styles.phase1}>Phase 2</b>
            <div className={styles.groupChild} />
          </div>
          <div
            className={styles.softwareProtocol}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            Software Protocol
          </div>
          <div className={styles.execute}>Execute</div>
          <div
            className={styles.github}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            GitHub
          </div>
          <div
            className={styles.bitcoin}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Bitcoin
          </div>
          <div className={styles.phase3Group}>
            <b className={styles.phase1}>Phase 3</b>
            <div className={styles.groupChild} />
          </div>
          <div className={styles.toDisableUsageContainer}>
            <p className={styles.theGovernance}>
              To disable usage of centralized tokens, enforce adjustments in the
              user pool, and enable emergency use of DAO funds.
            </p>
            <p className={styles.theGovernance}>&nbsp;</p>
            <p className={styles.theGovernance}>&nbsp;</p>
          </div>
          <div className={styles.exThisFinalContainer}>
            <ul className={styles.exRecruitingDaoParticipati}>
              <li>
                Ex) This final stage of DAO development is activated when there
                are clear signs of protocol attacks or substantial global
                regulatory and geopolitical risks, such as the outbreak of war.
              </li>
            </ul>
          </div>

          <img
            className={styles.githubMarkWhite1Icon}
            alt=""
            src="/dao/githubmarkwhite-1@2x.png"
          />
          <img
            className={styles.bitcoin1Icon}
            alt=""
            src="/dao/bitcoin-1@2x.png"
          />
          <img
            className={styles.editdaoIcon}
            alt=""
            src="/dao/image-72@2x.png"
          />
          <div
            className={styles.techWg}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Tech WG
          </div>
          <div
            className={styles.policySig}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Policy SIG
          </div>
          <div
            className={styles.appsWg}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Apps WG
          </div>
          <div
            className={styles.compilanceSig}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Compilance SIG
          </div>
          <div
            className={styles.treasuryWg}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Treasury WG
          </div>
          <img
            className={styles.editdaoIcon1}
            alt=""
            src="/dao/image-73@2x.png"
          />
          <div className={styles.informalDiscussion}>
            <span
              className={styles.informalDiscussionTxtContainer}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              <p className={styles.theGovernance}>Informal</p>
              <p className={styles.theGovernance}>discussion</p>
            </span>
          </div>
          <div
            className={styles.roughConsensus}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            <span className={styles.informalDiscussionTxtContainer}>
              <p className={styles.theGovernance}>Rough</p>
              <p className={styles.theGovernance}>consensus</p>
            </span>
          </div>
          <div
            className={styles.execute1}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Execute
          </div>
        </div>
      </div>

      <div className="only800">
        <div style={{ marginTop: '90px' }}>
          <VideoComponent
            key={2}
            src="/dao/dao.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={2}
            className="basic-video-img"
          />
          <div
            style={{
              paddingBottom: '30px',
              borderBottomRightRadius: '30px',
              borderBottomLeftRadius: '30px',
              backgroundColor: 'var(--color-black)',
            }}
          />
        </div>
        <div
          className="basic-text-800"
          style={{
            marginTop: 'calc(50 * var(--vh) - 290px)',
            color: 'var(--color-white)',
          }}
        >
          <div className={styles.dao} style={{ lineHeight: '42px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              DA
            </span>
            <i className={styles.o}>O</i>
          </div>
          <div className={styles.theCoolestForm}>
            The COolest form of governance
          </div>
          <b className={styles.hippocratDaoStrives}>
            Hippocrat DAO strives for a decentralized structure and autonomous
            organizations to foster the ecosystem's expansion.
          </b>
        </div>
      </div>
      <div className="layout800">
        <div className={styles.dao800}>
          <div className={styles.theGovernanceProcessContainer}>
            <span className={styles.theGovernanceProcessContainer1}>
              <p className={styles.theGovernance}>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  THE GOVERNAN
                </span>
                <i style={{ fontFamily: 'var(--font-bodoni-72)' }}>{`CE `}</i>
              </p>
              <p className={styles.process}>
                <i>PROCESS</i>
              </p>
            </span>
          </div>
          <div className={styles.dao800Item} />
          <div className={styles.dao800Inner} />
          <div className={styles.rectangleDiv} />
          <div className={styles.toSecureRegionalContainer}>
            <p className={styles.theGovernance}>
              To secure regional user pools and build ecosystem infrastructure.
            </p>
            <p className={styles.theGovernance}>&nbsp;</p>
          </div>
          <div className={styles.toVerifyTheContainer}>
            <p className={styles.theGovernance}>
              To verify the self-sustainability of ecosystems and address any
              vulnerabilities.
            </p>
            <p className={styles.theGovernance}>&nbsp;</p>
            <p className={styles.theGovernance}>&nbsp;</p>
            <p className={styles.theGovernance}>&nbsp;</p>
          </div>
          <div className={styles.toDisableUsageContainer}>
            <p className={styles.theGovernance}>
              To disable usage of centralized tokens, enforce adjustments in the
              user pool, and enable emergency use of DAO funds.
            </p>
            <p className={styles.theGovernance}>&nbsp;</p>
            <p className={styles.theGovernance}>&nbsp;</p>
          </div>
          <div className={styles.phase1Parent}>
            <b className={styles.phase1}>Phase 1</b>
            <div className={styles.groupChild} />
          </div>
          <div className={styles.phase2Parent}>
            <b className={styles.phase1}>Phase 2</b>
            <div className={styles.groupChild} />
          </div>
          <div className={styles.phase3Parent}>
            <b className={styles.phase1}>Phase 3</b>
            <div className={styles.groupChild} />
          </div>
          <div className={styles.exRecruitingDaoContainer}>
            <ul className={styles.exRecruitingDaoParticipati}>
              <li>
                Ex) Recruiting DAO participation members, establishing
                partnerships and investments for ecosystem infrastructure, and
                developing regional doctor and patient user pools.
              </li>
            </ul>
          </div>
          <div className={styles.exOneYearContainer}>
            <ul className={styles.exRecruitingDaoParticipati}>
              <li>
                Ex) One year following Phase 1, assess if the ecosystem can
                operate voluntarily without intervention from any centralized
                group within the DAO.
              </li>
            </ul>
          </div>
          <div className={styles.exRecruitingDaoContainer1}>
            <ul className={styles.exRecruitingDaoParticipati}>
              <li>
                Ex) Recruiting DAO participation members, establishing
                partnerships and investments for ecosystem infrastructure, and
                developing regional doctor and patient user pools.
              </li>
            </ul>
          </div>
          <div className={styles.asclepiosStance}>Asclepios Stance</div>
          <div className={styles.keironStance}>Keiron Stance</div>
          <div className={styles.hippocratesStance}>
            <p className={styles.theGovernance}>{`Hippocrates `}</p>
            <p className={styles.theGovernance}>Stance</p>
          </div>
          <div className={styles.dao800Child1} />
          <div className={styles.dao800Child2} />
          <div className={styles.dao800Child3} />
          <div className={styles.dao800Child4} />
          <div className={styles.dao800Child5} />
          <div className={styles.dao800Child6} />
          <div
            className={styles.dao1}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            DAO
          </div>
          <div className={styles.phase1Group}>
            <b className={styles.phase1}>Phase 1</b>
            <div className={styles.groupChild} />
          </div>
          <div
            className={styles.governanceProtocol}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <p className={styles.theGovernance}>{`Governance `}</p>
            <p className={styles.theGovernance}>Protocol</p>
          </div>
          <div className={styles.dao800Child7} />
          <div className={styles.dao800Child8} />
          <div className={styles.dao800Child9} />
          <div className={styles.dao800Child10} />
          <div
            className={styles.bitcoin}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Bitcoin
          </div>
          <div
            className={styles.github}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            GitHub
          </div>
          <img
            className={styles.polygonIcon}
            alt=""
            src="/dao/polygon-10.svg"
          />
          <img
            className={styles.dao800Child11}
            alt=""
            src="/dao/polygon-11.svg"
          />
          <div className={styles.phase2Group}>
            <b className={styles.phase1}>Phase 2</b>
            <div className={styles.groupChild} />
          </div>
          <div
            className={styles.softwareProtocol}
            style={{
              fontWeight: 'normal',
              fontFamily: 'var(--font-geomanist-ultra)',
            }}
          >
            <p className={styles.theGovernance}>{`Software `}</p>
            <p className={styles.theGovernance}>Protocol</p>
          </div>
          <div className={styles.phase3Group}>
            <b className={styles.phase1}>Phase 3</b>
            <div className={styles.groupChild} />
          </div>
          <img
            className={styles.editdaoIcon}
            alt=""
            src="/dao/image-73@2x.png"
          />
          <div className={styles.informalDiscussion}>
            <span
              className={styles.informalDiscussionTxtContainer}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              <p className={styles.theGovernance}>Informal</p>
              <p className={styles.theGovernance}>discussion</p>
            </span>
          </div>
          <div className={styles.roughConsensus}>
            <span
              className={styles.informalDiscussionTxtContainer}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              <p className={styles.theGovernance}>Rough</p>
              <p className={styles.theGovernance}>consensus</p>
            </span>
          </div>
          <div
            className={styles.execute}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Execute
          </div>
          <img
            className={styles.editdaoIcon1}
            alt=""
            src="/dao/image-72@2x.png"
          />
          <img
            className={styles.vectorIcon}
            alt=""
            src="/dao/vector-1@2x.png"
          />
          <img
            className={styles.dao800Child12}
            alt=""
            src="/dao/vector-1@2x.png"
          />
          <div
            className={styles.treasuryWg}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Treasury WG
          </div>
          <div
            className={styles.techWg}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Tech WG
          </div>
          <div
            className={styles.policySig}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Policy SIG
          </div>
          <div
            className={styles.appsWg}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Apps WG
          </div>
          <div
            className={styles.compilanceSig}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Compilance SIG
          </div>
          <img
            className={styles.bitcoin2Icon}
            alt=""
            src="/dao/bitcoin-1@2x.png"
          />
          <img
            className={styles.githubMarkWhite2Icon}
            alt=""
            src="/dao/githubmarkwhite-1@2x.png"
          />
        </div>
      </div>
      <MDaoPage handleVideoLoaded={handleVideoLoaded} />
    </section>
  );
};

export default DaoPage;
