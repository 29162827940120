import React, { useState, useEffect, useRef } from 'react';
import styles from './blog.module.scss';

interface Article {
  title?: string;
  description?: string;
  image?: string;
  date?: string;
  url?: string;
  tag?: string[];
}

interface Tops {
  [key: number]: string;
}

const BlogPage = () => {
  // add main article here
  const mainArticle: Article = {
    title: 'Hippodoc Closed Beta Test',
    description: `The first Telemedicine Dapp in WEB3`,
    date: 'MAR 20, 2024',
    image:
      'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*xo7lUyLv9yOHVdV5-gA06g.jpeg',
    url: 'https://medium.com/hippocrat/hippodoc-closed-beta-test-ffed6cd7b32b',
  };
  // add blog articles here
  const articles: Article[] = [
    {
      title: '“Hippocrat” (HPO) to be Listed on “Korbit”',
      image:
        'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*Z8UyblZNznNjuwqa7NRmnQ.jpeg',
      url: 'https://medium.com/hippocrat/hippocrat-hpo-to-be-listed-on-korbit-c8f6955e0847',
      date: 'JUN 14, 2024',
    },
    {
      title:
        'Hippocrat Implements DID Technology for SALUS CARE Corp., Korea’s Largest Health Checkup Prognosis Service Provider',
      image:
        'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*t-64yk9BRWt8I6SjIEqYqQ.jpeg',
      url: 'https://medium.com/hippocrat/hippocrat-implements-did-technology-for-salus-care-corp-a37e4b9e8582',
      date: 'JUN 12, 2024',
    },
    {
      title: '[Research Update #2] A Closer Look at Key Regions and Groups',
      image:
        'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*9cy_kmgLoWZeYVW2GXY01Q.jpeg',
      url: 'https://medium.com/hippocrat/research-update-2-a-closer-look-at-key-regions-and-groups-9ef01e974df6',
      date: 'MAY 20, 2024',
    },
    {
      title:
        '[Research Update #1] Identifying Potential Populations And Regions For Effective Telemedicine Interventions',
      image:
        'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*6oJ76zva9zDVeY2cWIMRaw.jpeg',
      url: 'https://medium.com/hippocrat/research-update-1-identifying-potential-populations-and-regions-for-effective-telemedicine-82aa9061684a',
      date: 'APR 29, 2024',
    },
    {
      title:
        'Hippocrat Labs Presents Blockchain-Based Telemedicine App ‘Hippodoc’ at Korean Musculoskeletal Medicine Symposium',
      image:
        'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*U8lmMqYvlKRm0T1w7xKmXg.png',
      url: 'https://medium.com/hippocrat/hippocrat-labs-presents-blockchain-based-telemedicine-app-hippodoc-at-korean-musculoskeletal-75ae33da196e',
      date: 'APR 15, 2024',
    },
    {
      title:
        'Hippocrat and ResearchHub Join Forces to Tackle Global Healthcare Disparities through DeSci',
      image:
        'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*4kBv7-W4nop4ozA2hv4AQw.jpeg',
      url: 'https://medium.com/hippocrat/hippocrat-and-researchhub-join-forces-to-tackle-global-healthcare-disparities-through-desci-0f2377d749fc',
      date: 'APR 05, 2024',
    },
    {
      title: 'Hippodoc Closed Beta Test',
      image:
        'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*xo7lUyLv9yOHVdV5-gA06g.jpeg',
      url: 'https://medium.com/hippocrat/hippodoc-closed-beta-test-ffed6cd7b32b',
      date: 'MAR 20, 2024',
    },
    {
      title:
        'Hippocrat DAO Series: “HOW DAO” — The Operational System of Hippocrat DAO',
      image:
        'https://miro.medium.com/v2/resize:fit:4800/format:webp/0*6s3xAagQiLV1KVEO',
      url: 'https://medium.com/hippocrat/hippocrat-dao-series-how-dao-the-operational-system-of-hippocrat-dao-55cd5e7ef0d7',
      date: 'DEC 15, 2023',
    },
    {
      title: 'Hippo Branding Ep.02',
      image:
        'https://app.circle.so/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBCSjhvNmdFPSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--515862719b58aea9a4c0e991f07d09197f4ea4f3/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDRG9MWm05eWJXRjBTU0lJY0c1bkJqb0dSVlE2RkhKbGMybDZaVjkwYjE5c2FXMXBkRnNITUdrQ09BUTZDbk5oZG1WeWV3WTZDbk4wY21sd1ZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--cfda350175ba87e768b4e96e935a8171fc679bec/image.png',
      url: 'https://community.hippocrat.io/c/news/hippo-branding-ep-02',
      date: 'DEC 07, 2023',
    },
    {
      title:
        'Hippocrat DAO Series: "What DAO" - Finding PMF with a Community-First Approach',
      image:
        'https://miro.medium.com/v2/resize:fit:4800/format:webp/0*RfPI-KD_m4avEbwJ',
      url: 'https://medium.com/hippocrat/hippocrat-dao-series-what-dao-finding-pmf-with-a-community-first-approach-edb51b3256cd',
      date: 'DEC 04, 2023',
    },
    {
      title: 'Self-Sovereign Healthcare Data',
      image:
        'https://miro.medium.com/v2/resize:fit:4800/format:webp/0*_EM3fd2szQ3HakFG',
      url: 'https://medium.com/hippocrat/self-sovereign-healthcare-data-59db754b252a',
      date: 'DEC 01, 2023',
    },
    {
      title: 'Hippo Branding Ep.01',
      image:
        'https://app.circle.so/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBCRC9vNFFFPSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--0ddb504fbd0401f8497cbe8732161f22b5cb211d/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDRG9MWm05eWJXRjBTU0lJY0c1bkJqb0dSVlE2RkhKbGMybDZaVjkwYjE5c2FXMXBkRnNITUdrQ09BUTZDbk5oZG1WeWV3WTZDbk4wY21sd1ZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--cfda350175ba87e768b4e96e935a8171fc679bec/image.png',
      url: 'https://community.hippocrat.io/c/news/hippo-branding-ep-01',
      date: 'NOV 30, 2023',
    },
    {
      title: 'Hippocrat DAO Series: "Why DAO: 3 Reasons for Adopting DAO"',
      image:
        'https://miro.medium.com/v2/resize:fit:602/1*sWRa3IIICbHwZ-1BA8UIhw.png',
      url: 'https://medium.com/hippocrat/why-dao-3-reasons-for-adopting-dao-380e2a075542',
      date: 'NOV 27, 2023',
    },
    {
      title: 'Why does Hippocrat use blockchain?',
      image:
        'https://miro.medium.com/v2/resize:fit:4800/format:webp/0*mYffC08xYwwPtDo2',
      url: 'https://medium.com/hippocrat/why-does-hippocrat-use-blockchain-42131bfbf212',
      date: 'OCT 27, 2023',
    },
    {
      title: 'HPO Team Letter #2',
      image:
        'https://app.circle.so/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBCSTlJcWdFPSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--78eb8bfe69ec50cc33da9f07cdf01a9fdb934369/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDRG9MWm05eWJXRjBTU0lJY0c1bkJqb0dSVlE2RkhKbGMybDZaVjkwYjE5c2FXMXBkRnNITUdrQ09BUTZDbk5oZG1WeWV3WTZDbk4wY21sd1ZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--cfda350175ba87e768b4e96e935a8171fc679bec/Q4%20LETTER2.png',
      url: 'https://community.hippocrat.io/c/news/q4-letter-hpo-master-plan-has-been-announced',
      date: 'OCT 11, 2023',
    },
    {
      title: 'HPO Team Letter #1',
      image: '/banner.png',
      url: 'https://community.hippocrat.io/c/news/hpo-team-letter-1',
      date: 'AUG 25, 2023',
    },
  ];

  const [currentPage, setCurrentPage] = useState<number>(1);
  const articlesPerPage = 6;
  const totalArticles = articles.length;

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(
    indexOfFirstArticle,
    indexOfLastArticle,
  );

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalArticles / articlesPerPage); i++) {
    pageNumbers.push(i);
  }

  const [tops, setMarginTops] = useState<Tops>({});
  const refs = useRef<React.RefObject<HTMLDivElement>[]>(
    currentArticles.map(() => React.createRef()),
  );
  const [tops2, setMarginTops2] = useState<Tops>({});
  const refs2 = useRef<React.RefObject<HTMLDivElement>[]>(
    currentArticles.map(() => React.createRef()),
  );
  const [tops3, setMarginTops3] = useState<Tops>({});
  const refs3 = useRef<React.RefObject<HTMLDivElement>[]>(
    currentArticles.map(() => React.createRef()),
  );
  const [tops4, setMarginTops4] = useState<Tops>({});
  const refs4 = useRef<React.RefObject<HTMLDivElement>[]>(
    currentArticles.map(() => React.createRef()),
  );

  useEffect(() => {
    const updatedMarginTops: Tops = {};

    let isChanged = false;

    refs.current.forEach((ref, index) => {
      if (ref.current) {
        const height = ref.current.clientHeight;
        const newMarginTop = height > 45 ? '-25px' : '0px';
        updatedMarginTops[index] = newMarginTop;
        if (tops[index] !== newMarginTop) {
          isChanged = true;
        }
      }
    });

    if (isChanged) {
      setMarginTops(updatedMarginTops);
    }

    const updatedMarginTops2: Tops = {};

    let isChanged2 = false;

    refs2.current.forEach((ref, index) => {
      if (ref.current) {
        const height2 = ref.current.clientHeight;
        const newMarginTop2 = height2 > 45 ? '-25px' : '0px';
        updatedMarginTops2[index] = newMarginTop2;
        if (tops2[index] !== newMarginTop2) {
          isChanged2 = true;
        }
      }
    });

    if (isChanged2) {
      setMarginTops2(updatedMarginTops2);
    }

    const updatedMarginTops3: Tops = {};

    let isChanged3 = false;

    refs3.current.forEach((ref, index) => {
      if (ref.current) {
        const height3 = ref.current.clientHeight;
        const newMarginTop3 = height3 > 45 ? '-25px' : '0px';
        updatedMarginTops3[index] = newMarginTop3;
        if (tops3[index] !== newMarginTop3) {
          isChanged3 = true;
        }
      }
    });

    if (isChanged3) {
      setMarginTops3(updatedMarginTops3);
    }

    const updatedMarginTops4: Tops = {};

    let isChanged4 = false;

    refs3.current.forEach((ref, index) => {
      if (ref.current) {
        const height4 = ref.current.clientHeight;
        const newMarginTop4 = height4 > 45 ? '-25px' : '0px';
        updatedMarginTops4[index] = newMarginTop4;
        if (tops4[index] !== newMarginTop4) {
          isChanged4 = true;
        }
      }
    });

    if (isChanged4) {
      setMarginTops4(updatedMarginTops4);
    }
  }, [currentArticles]);

  return (
    <section className="layoutContainer">
      <div className="only1000">
        <div className={styles.blogChild} />
      </div>
      <div className="layout1000">
        <div className={styles.blog}>
          <div className={styles.blog1}>
            <span>BL</span>
            <i className={styles.og}>OG</i>
          </div>
          <div className={styles.aFocusedInsightful}>
            A focused, insightful view
          </div>
          {/* main image */}
          <div className={styles.blogItem}>
            <img
              className="basic-blog-main-img"
              alt=""
              src={mainArticle?.image}
            />
          </div>
          <b className={styles.blogTitle}>
            <p className={styles.nowToSpot}>{mainArticle?.title}</p>
          </b>
          <b className={styles.exploreTheWorld}>{mainArticle?.description}</b>
          <div className={styles.blogDate}>{mainArticle?.date}</div>
          <a href={mainArticle?.url} target="_blank" rel="noreferrer">
            <div className={styles.readMore}>READ MORE</div>
          </a>

          {/* article */}
          {currentArticles.map((article, i) => (
            <a key={i} href={article?.url} target="_blank" rel="noreferrer">
              <div className={styles?.[`blogImg${i}`]}>
                <img className="basic-blog-img" alt="" src={article?.image} />
              </div>

              <b className={styles?.[`blogTitle${i}`]}>
                <p className={styles.nowToSpot}>{article?.title}</p>
              </b>
              <div className={styles?.[`blogDate${i}`]}>{article?.date}</div>
              <div
                ref={refs.current[i]}
                className={styles?.[`blogTagBox${i}`]}
                style={{ marginTop: tops[i] }}
              >
                {article?.tag?.map((tag, index) => (
                  <div key={index} className={styles.blogTag}>
                    {tag}
                  </div>
                ))}
              </div>
            </a>
          ))}

          {/* pagination */}
          <div
            className={`${styles.parent} ${
              currentArticles?.length < 4 ? styles.parentLow : ''
            }`}
          >
            {pageNumbers.map(number => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={`${currentPage === number ? styles.active : ''}`}
              >
                {number}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="only800">
        <div className={styles.blog800Child} />
      </div>
      <div className="layout800">
        <div className={styles.blog800}>
          <div className={styles.blog} style={{ lineHeight: '42px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              BL
            </span>
            <i className={styles.og}>OG</i>
          </div>
          <div className={styles.aFocusedInsightful}>
            A focused, insightful view
          </div>
          {/* main image */}
          <div className={styles.blog800Item}>
            <img
              className="basic-blog-main-img"
              alt=""
              src="/blog/banner.png"
            />
          </div>
          <b className={styles.nowToSpotContainer}>
            <p className={styles.nowToSpot}>{mainArticle?.title}</p>
          </b>
          <b className={styles.exploreTheWorld}>{mainArticle?.description}</b>
          <div className={styles.nov172023}>{mainArticle?.date}</div>
          <a href={mainArticle?.url} target="_blank" rel="noreferrer">
            <div className={styles.readMore}>READ MORE</div>
          </a>

          {/* article */}
          {currentArticles.map((article, i) => (
            <a key={i} href={article?.url} target="_blank" rel="noreferrer">
              <div className={styles?.[`blogImg${i}`]}>
                <img className="basic-blog-img" alt="" src={article?.image} />
              </div>
              <b className={styles?.[`blogTitle${i}`]}>
                <p className={styles.nowToSpot}>{article?.title}</p>
              </b>
              <div className={styles?.[`blogDate${i}`]}>{article?.date}</div>
              <div
                ref={refs2.current[i]}
                className={styles?.[`blogTagBox${i}`]}
                style={{ marginTop: tops2[i] }}
              >
                {article?.tag?.map((tag, index) => (
                  <div key={index} className={styles.blogTag}>
                    {tag}
                  </div>
                ))}
              </div>
            </a>
          ))}

          {/* pagination */}
          <div
            className={`${styles.parent} ${
              currentArticles?.length < 4 ? styles.parentLow : ''
            }`}
          >
            {pageNumbers.map(number => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={`${currentPage === number ? styles.active : ''}`}
              >
                {number}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="only500">
        <div className={styles.blog500Inner} />
      </div>
      <div className="layout500">
        <div className={styles.blog500}>
          <b className={styles.exploreTheWorld}>{mainArticle?.description}</b>
          <b className={styles.nowToSpotContainer}>
            <p className={styles.nowToSpot}>{mainArticle?.title}</p>
          </b>
          <div className={styles.blog} style={{ lineHeight: '32px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              BL
            </span>
            <i className={styles.og}>OG</i>
          </div>
          <div className={styles.aFocusedInsightful}>
            A focused, insightful view
          </div>
          <div className={styles.nov172023}>{mainArticle?.date}</div>
          <div className={styles.rectangleDiv}>
            <img
              className="basic-blog-main-img"
              alt=""
              src="/blog/banner.png"
            />
          </div>
          <a href={mainArticle?.url} target="_blank" rel="noreferrer">
            <div className={styles.readMore}>READ MORE</div>
          </a>

          <div className={styles.newsWrapper}>
            {/* article */}
            {currentArticles.map((article, i) => (
              <a key={i} href={article?.url} target="_blank" rel="noreferrer">
                <div className={styles.newsImg}>
                  <img className="basic-blog-img" alt="" src={article?.image} />
                </div>
                <div
                  ref={refs3.current[i]}
                  className={styles.blogTagBox}
                  style={{ marginTop: tops3[i] }}
                >
                  {article?.tag?.map((tag, index) => (
                    <div key={index} className={styles.blogTag}>
                      {tag}
                    </div>
                  ))}
                </div>

                <div className={styles.newsDate}>{article?.date}</div>
                <div className={styles.newsTitle}>{article?.title}</div>
              </a>
            ))}
          </div>

          {/* pagination */}
          <div className={styles.parent}>
            {pageNumbers.map(number => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={`${currentPage === number ? styles.active : ''}`}
              >
                {number}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="only360">
        <div className={styles.blog360Inner} />
      </div>
      <div className="layout360">
        <div className={styles.blog360}>
          <b className={styles.exploreTheWorld}>{mainArticle?.description}</b>
          <b className={styles.nowToSpotContainer}>
            <p className={styles.nowToSpot}>{mainArticle?.title}</p>
          </b>
          <div className={styles.blog} style={{ lineHeight: '25px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              BL
            </span>
            <i className={styles.og}>OG</i>
          </div>
          <div className={styles.aFocusedInsightful}>
            A focused, insightful view
          </div>
          <div className={styles.nov172023}>{mainArticle?.date}</div>
          <div className={styles.rectangleDiv}>
            <img
              className="basic-blog-main-img"
              alt=""
              src="/blog/banner.png"
            />
          </div>
          <a href={mainArticle?.url} target="_blank" rel="noreferrer">
            <div className={styles.readMore}>READ MORE</div>
          </a>

          <div className={styles.newsWrapper}>
            {/* article */}
            {currentArticles.map((article, i) => (
              <a key={i} href={article?.url} target="_blank" rel="noreferrer">
                <div className={styles.newsImg}>
                  <img className="basic-blog-img" alt="" src={article?.image} />
                </div>
                <div
                  ref={refs4.current[i]}
                  className={styles.blogTagBox}
                  style={{ marginTop: tops4[i] }}
                >
                  {article?.tag?.map((tag, index) => (
                    <div key={index} className={styles.blogTag}>
                      {tag}
                    </div>
                  ))}
                </div>

                <div className={styles.newsDate}>{article?.date}</div>
                <div className={styles.newsTitle}>{article?.title}</div>
              </a>
            ))}
          </div>

          {/* pagination */}
          <div className={styles.parent}>
            {pageNumbers.map(number => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={`${currentPage === number ? styles.active : ''}`}
              >
                {number}
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogPage;
