import styles from './mIndex.module.scss';

const MWhitePaperPage = () => {
  return (
    <>
      <div className="only500"></div>
      <div className="layout500">
        <div className={styles.whitepaper500}>
          <div className={styles.relatedStories}>
            <span className={styles.relatedSt}>RELATED ST</span>
            <i className={styles.ories}>ORIES</i>
          </div>
          <b className={styles.pleaseCheckThe}>
            Please check the contents of Hippocrat's latest whitepaper to gain a
            more detailed understanding of the direction we are moving towards.
          </b>
          <b className={styles.ver10}>
            <p className={styles.blankLine}>Ver 1.0</p>
            <p className={styles.blankLine}>&nbsp;</p>
          </b>
          <div className={styles.whitePaper} style={{ lineHeight: '32px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              WHI
            </span>
            <b className={styles.ories}>T</b>
            <i className={styles.ories}>{`E  `}</i>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              PAPER
            </span>
          </div>
          <div className={styles.aFocusedInsightful}>
            A focused, insightful view
          </div>
          <a href="https://whitepaper.hippocrat.io/" target="_blank">
            <div className={styles.readMore}>READ MORE</div>
          </a>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/whitePaper/banner.png"
          />
        </div>
      </div>
      <div className="only360"></div>
      <div className="layout360">
        {' '}
        <div className={styles.whitepaper360}>
          <div className={styles.relatedStories}>
            <span className={styles.relatedSt}>RELATED ST</span>
            <i className={styles.ories}>ORIES</i>
          </div>
          <b className={styles.pleaseCheckThe}>
            Please check the contents of Hippocrat's latest whitepaper to gain a
            more detailed understanding of the direction we are moving towards.
          </b>
          <b className={styles.ver10}>
            <p className={styles.blankLine}>Ver 1.0</p>
            <p className={styles.blankLine}>&nbsp;</p>
          </b>
          <div className={styles.whitePaper} style={{ lineHeight: '25px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              WHI
            </span>
            <b className={styles.ories}>T</b>
            <i className={styles.ories}>{`E  `}</i>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              PAPER
            </span>
          </div>
          <div className={styles.aFocusedInsightful}>
            A focused, insightful view
          </div>
          <div className={styles.whitepaper360Child} />
          <a href="https://whitepaper.hippocrat.io/" target="_blank">
            <div className={styles.readMore}>READ MORE</div>
          </a>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/whitePaper/banner.png"
          />
        </div>
      </div>
    </>
  );
};

export default MWhitePaperPage;
