import { Outlet } from 'react-router-dom';
import HeaderNav from '../../components/HeaderNav';
import FooterNav from '../../components/FooterNav';

const DefaultLayout = () => {
  return (
    <section className="layout">
      <HeaderNav />
      <Outlet />
      <FooterNav />
    </section>
  );
};

export default DefaultLayout;
