import styles from './whitePaper.module.scss';
import MWhitePaperPage from './mIndex';

const WhitePaperPage = () => {
  return (
    <section className="layoutContainer">
      <div className="layout1000">
        <div className={styles.whitepaper}>
          <b className={styles.pleaseCheckThe}>
            Please check the contents of Hippocrat's latest whitepaper to gain a
            more detailed understanding of the direction we are moving towards.
          </b>
          <b className={styles.ver10}>
            <p className={styles.ver101}>Ver 1.0</p>
          </b>
          <div className={styles.whitePaper}>
            <span>WHI</span>
            <b className={styles.t}>T</b>
            <i className={styles.e}>{`E  `}</i>
            <span>PAPER</span>
          </div>
          <div className={styles.checkOutThe}>Check out the Newest version</div>
          <div className={styles.whitepaperChild}>
            <img alt="" src="/whitePaper/banner.png" />
          </div>
          <a href="https://whitepaper.hippocrat.io/" target="_blank">
            <div className={styles.readMore}>READ MORE</div>
          </a>
        </div>
      </div>
      <div className="layout800">
        <div className={styles.whitepaper800}>
          <b className={styles.pleaseCheckThe}>
            Please check the contents of Hippocrat's latest whitepaper to gain a
            more detailed understanding of the direction we are moving towards.
          </b>
          <b className={styles.ver10}>
            <p className={styles.ver101}>Ver 1.0</p>
          </b>
          <div className={styles.whitePaper} style={{ lineHeight: '42px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              WHI
            </span>
            <b className={styles.t}>T</b>
            <i className={styles.t}>{`E  `}</i>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              PAPER
            </span>
          </div>
          <div className={styles.checkOutThe}>Check out the Newest version</div>
          <a href="https://whitepaper.hippocrat.io/" target="_blank">
            <div className={styles.readMore}>READ MORE</div>
          </a>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/whitePaper/banner.png"
          />
        </div>
      </div>
      <MWhitePaperPage />
    </section>
  );
};

export default WhitePaperPage;
