import styles from './mIndex.module.scss';

const MLearnWeb3Page = () => {
  return (
    <>
      <div className="only500">
        <div className={styles.learn500Child} />
      </div>
      <div className="layout500">
        <div className={styles.learn500}>
          <div className={styles.hippocratIsTheContainer}>
            <p
              className={styles.hippocratIsThe}
              style={{ fontFamily: 'var(--font-inter)' }}
            >{`Hippocrat is the healthcare data project leveraging blockchain and web3 technology. `}</p>
            <p
              className={styles.hippocratIsThe}
              style={{ fontFamily: 'var(--font-inter)' }}
            >{`Patient can be incentivized with Hippocrat token for sharing his or her healthcare data. On the other hand, hospital can access to private healthcare data securely by paying Hippocrat to data owner. `}</p>
            <p className={styles.hippocratIsThe}>&nbsp;</p>
            <p
              className={styles.hippocratIsThe}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              Hippocrat DAO provides blockchain technology to exchange
              healthcare data in an efficient, interoperable and
              privacy-preserving way. Hippocrat Token provides incentive
              mechanism to sustain its ecosystem.
            </p>
          </div>
          <div className={styles.web10Container}>
            <p
              className={styles.hippocratIsThe}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              WEB 1.0 - 1990s and early 2000s, when user passively read static
              web page which does not provide interaction with the service
              provider.
            </p>
            <p className={styles.hippocratIsThe}>&nbsp;</p>
            <p
              className={styles.hippocratIsThe}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              WEB 2.0 - 2005 and so far, user becomes active creator of
              services(like Facebook, Youtube and Twitter) but still all the
              profit and data belong to the service provider.
            </p>
            <p className={styles.hippocratIsThe}>&nbsp;</p>
            <p
              className={styles.hippocratIsThe}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              WEB 3.0 - user gets incentivized for his contribution, owns the
              stake for governance via DAO and his own private data via
              distributed storage.
            </p>
          </div>
          <b className={styles.whyHippocrat}>Why Hippocrat?</b>
          <b className={styles.whatIsWeb3}>What is WEB3?</b>

          <div
            className={styles.forMostOf}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            For most of people, blockchain is the notion that is barely known
            even though the word itself is very popular. It might be because
            blockchain is a software mainly used by engineer, not by end user.
            However, installing blockchain software is not any different with
            installing application in your phone. It’s public open source free
            to be used by anyone, with decent amount of incentive(where
            cryptocurrency comes).
          </div>
          <div
            className={styles.inThisWay}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            In this way, blockchain is born to be decentralized, make trustless
            trust achievable and incentivize to open permissionless network.
            Being more decentralized means being more reliable. Bitcoin and
            Ethereum are among the most popular blockchain.
          </div>
          <div
            className={styles.blockchainIsA}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Blockchain is a software which runs server to debit and credit
            ledger and cryptographically prove and synchronize ledger with other
            server - you know, it’s useless if money cannot be sent to others -
            which runs same software.
          </div>
          <b className={styles.whatIsBlockchain}>What is blockchain?</b>
          <div
            className={styles.blockchainWeb3Container}
            style={{ lineHeight: '32px' }}
          >
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              Block
            </span>
            <i className={styles.chain}>{`chain &`}</i>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              {' '}
              W
            </span>
            <b className={styles.chain}>EB3</b>
          </div>
          <div className={styles.theComingFuture}>The coming future</div>
          <div className={styles.learn500Item} />
          <div className={styles.learn500Inner} />
          <a href="/learn/healthcareData">
            <div className={styles.nextUseCaseContainer}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                NE
              </span>
              <b>X</b>
              <b className={styles.chain}>T</b>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                {' '}
                USE
              </span>
              <i className={styles.chain}> CASE</i>
            </div>
            <b className={styles.selfSovereignHealthcareData}>
              Self-Sovereign Healthcare Data
            </b>
            <img
              className={styles.chevronLeftIcon}
              alt=""
              src="/learn/chevronleft@2x.png"
            />
          </a>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/learn/mweb3.png"
            style={{ pointerEvents: 'none' }}
          />
        </div>
      </div>
      <div className="only360">
        <div className={styles.learn360Child} />
      </div>
      <div className="layout360">
        <div className={styles.learn360}>
          <div className={styles.hippocratIsTheContainer}>
            <p
              className={styles.hippocratIsThe}
            >{`Hippocrat is the healthcare data project leveraging blockchain and web3 technology. `}</p>
            <p
              className={styles.hippocratIsThe}
            >{`Patient can be incentivized with Hippocrat token for sharing his or her healthcare data. On the other hand, hospital can access to private healthcare data securely by paying Hippocrat to data owner. `}</p>
            <p className={styles.hippocratIsThe}>&nbsp;</p>
            <p className={styles.hippocratIsThe}>
              Hippocrat DAO provides blockchain technology to exchange
              healthcare data in an efficient, interoperable and
              privacy-preserving way. Hippocrat Token provides incentive
              mechanism to sustain its ecosystem.
            </p>
          </div>
          <div className={styles.web10Container}>
            <p className={styles.hippocratIsThe}>
              WEB 1.0 - 1990s and early 2000s, when user passively read static
              web page which does not provide interaction with the service
              provider.
            </p>
            <p className={styles.hippocratIsThe}>&nbsp;</p>
            <p className={styles.hippocratIsThe}>
              WEB 2.0 - 2005 and so far, user becomes active creator of
              services(like Facebook, Youtube and Twitter) but still all the
              profit and data belong to the service provider.
            </p>
            <p className={styles.hippocratIsThe}>&nbsp;</p>
            <p className={styles.hippocratIsThe}>
              WEB 3.0 - user gets incentivized for his contribution, owns the
              stake for governance via DAO and his own private data via
              distributed storage.
            </p>
          </div>
          <b className={styles.whyHippocrat}>Why Hippocrat?</b>
          <b className={styles.whatIsWeb3}>What is WEB3?</b>

          <div className={styles.forMostOf}>
            For most of people, blockchain is the notion that is barely known
            even though the word itself is very popular. It might be because
            blockchain is a software mainly used by engineer, not by end user.
            However, installing blockchain software is not any different with
            installing application in your phone. It’s public open source free
            to be used by anyone, with decent amount of incentive(where
            cryptocurrency comes).
          </div>
          <div className={styles.inThisWay}>
            In this way, blockchain is born to be decentralized, make trustless
            trust achievable and incentivize to open permissionless network.
            Being more decentralized means being more reliable. Bitcoin and
            Ethereum are among the most popular blockchain.
          </div>
          <div className={styles.blockchainIsA}>
            Blockchain is a software which runs server to debit and credit
            ledger and cryptographically prove and synchronize ledger with other
            server - you know, it’s useless if money cannot be sent to others -
            which runs same software.
          </div>
          <b className={styles.whatIsBlockchain}>What is blockchain?</b>
          <div
            className={styles.blockchainWeb3Container}
            style={{ lineHeight: '25px' }}
          >
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              Block
            </span>
            <i className={styles.chain}>{`chain &`}</i>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              {' '}
              W
            </span>
            <b className={styles.chain}>EB3</b>
          </div>
          <div className={styles.theComingFuture}>The coming future</div>
          <div className={styles.learn360Item} />
          <a href="/learn/healthcareData">
            <div className={styles.nextUseCaseContainer}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                NE
              </span>
              <b>X</b>
              <b className={styles.chain}>T</b>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                {' '}
                USE
              </span>
              <i className={styles.chain}> CASE</i>
            </div>
            <b className={styles.selfSovereignHealthcareData}>
              Self-Sovereign <br />
              Healthcare Data
            </b>
            <img
              className={styles.chevronLeftIcon}
              alt=""
              src="/learn/chevronleft@2x.png"
            />
          </a>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/learn/mweb3.png"
            style={{ pointerEvents: 'none' }}
          />
        </div>
      </div>
    </>
  );
};

export default MLearnWeb3Page;
