import styles from './mIndex.module.scss';
import VideoComponent from '../../components/VideoOnReady';

interface MPageProps {
  handleVideoLoaded: (videoNumber: number, player: any) => void;
}

const MProtocolPage: React.FC<MPageProps> = ({ handleVideoLoaded }) => {
  return (
    <>
      <div className="only500">
        <img className={styles.daoImg} alt="" src="/protocol/mDao.png" />
        <div className={styles.rectangleDiv} />
        <div>
          <VideoComponent
            key={3}
            src="/protocol/mProtocol.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={3}
            className="basic-video-img"
          />
        </div>
        <div
          className="basic-text-500"
          style={{
            marginTop: 'calc(50 * var(--vh) - 260px)',
          }}
        >
          <div className={styles.protocol} style={{ lineHeight: '32px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              PR
            </span>
            <b className={styles.o}>O</b>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              TO
            </span>
            <i className={styles.o}>COL</i>
          </div>
          <div className={styles.hippocratEcosystem}>Hippocrat Ecosystem</div>
          <b className={styles.hippocratEstablishesSustaina}>
            Hippocrat establishes sustainable collaborative protocols for
            healthcare data, built upon the Bitcoin layer.
          </b>
        </div>
      </div>
      <div className="layout500">
        <div className={styles.protocol500}>
          <div className={styles.protocol500Child} />
          <div className={styles.protocol500Item} />
          <div className={styles.protocol500Inner} />
          <b className={styles.first}>FIRST</b>
          <b className={styles.second}>SECOND</b>
          <b className={styles.third}>THIRD</b>

          <div
            className={styles.healthcareDataRequires}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Healthcare data requires a transparent protocol for documenting
            contributions from both patients and various stakeholders prior to
            it’s utilization.
          </div>
          <div
            className={styles.healthcareDataNecessitates}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Healthcare data necessitates a protocol that ensures trust in its
            records of utilization and distribution, operable through an open
            cooperative system open to all participants.
          </div>
          <div
            className={styles.healthcareDataDemands}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Healthcare data demands a compensation distribution protocol
            designed to foster sustainable collaboration among involved
            stakeholders.
          </div>
          <img className={styles.image17Icon} alt="" src="/image-17@2x.png" />
          <img className={styles.image18Icon} alt="" src="/image-18@2x.png" />
          <div
            className={styles.theUseOf}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            The use of Decentralized Identifier (DID) and Verifiable Credentials
            (VC) technologies empowers individuals to exercise self-sovereignty
            over their medical data, extending beyond mere personal
            identification.
          </div>
          <div
            className={styles.theHippocratWallet}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            The Hippocrat Wallet SDK, built on proven technology, enables users
            to access their assets and data in the developed wallet through a
            range of applications.
          </div>
          <div
            className={styles.utilizingTheHighly}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Utilizing the highly decentralized and secure Bitcoin layer, we
            ensure the utmost security for your valuable health data.
          </div>
          <div className={styles.leveragingTheBitcoinContainer}>
            <span className={styles.leveragingTheBitcoinContainer1}>
              <p
                className={styles.leveraging}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`Leveraging `}</p>
              <p className={styles.leveraging}>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  the Bitcoin
                </span>
                <i className={styles.o}> Layer</i>
              </p>
            </span>
          </div>
          <div className={styles.implementingDidContainer}>
            <span className={styles.leveragingTheBitcoinContainer1}>
              <p
                className={styles.leveraging}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`Implementing `}</p>
              <p className={styles.didVc}>
                <i>{`DID & VC`}</i>
              </p>
            </span>
          </div>
          <div className={styles.providingTheHippocratContainer}>
            <span className={styles.leveragingTheBitcoinContainer1}>
              <p
                className={styles.leveraging}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`Providing the `}</p>
              <p className={styles.leveraging}>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  Hippo
                </span>
                <i className={styles.o}>{`crat `}</i>
                <span
                  className={styles.wallet}
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >{`Wallet `}</span>
                <i className={styles.o}>SDK</i>
              </p>
            </span>
          </div>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/protocol/first.png"
          />
          <img
            className={styles.maskGroupIcon1}
            alt=""
            src="/protocol/second.png"
          />
          <img
            className={styles.maskGroupIcon2}
            alt=""
            src="/protocol/third.png"
          />

          <img
            className={styles.maskGroupIcon3}
            alt=""
            src="/protocol/coinLayer.png"
          />
          <img
            className={styles.maskGroupIcon6}
            alt=""
            src="/protocol/ob8.png"
          />
          <img
            className={styles.maskGroupIcon4}
            alt=""
            src="/protocol/sun.png"
          />
        </div>
      </div>
      <div className="only360">
        <img className={styles.daoImg} alt="" src="/protocol/mDao.png" />
        <div className={styles.rectangleDiv} />
        <div className={styles.protocol360Child} />
        <div>
          <VideoComponent
            key={4}
            src="/protocol/mProtocol.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={4}
            className="basic-video-img"
          />
        </div>
        <div
          className="basic-text-360"
          style={{
            marginTop: 'calc(50 * var(--vh) - 260px)',
            textAlign: 'center',
          }}
        >
          <div className={styles.protocol}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              PR
            </span>
            <b className={styles.o}>O</b>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              TO
            </span>
            <i className={styles.o}>COL</i>
          </div>
          <div className={styles.hippocratEcosystem}>Hippocrat Ecosystem</div>
          <b className={styles.hippocratEstablishesSustaina}>
            Hippocrat establishes sustainable collaborative protocols for
            healthcare data, built upon the Bitcoin layer.
          </b>
        </div>
      </div>
      <div className="layout360">
        <div className={styles.protocol360}>
          <b className={styles.first}>FIRST</b>
          <b className={styles.second}>SECOND</b>
          <b className={styles.third}>THIRD</b>

          <div
            className={styles.healthcareDataRequires}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Healthcare data requires a transparent protocol for documenting
            contributions from both patients and various stakeholders prior to
            it’s utilization.
          </div>
          <div
            className={styles.healthcareDataNecessitates}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Healthcare data necessitates a protocol that ensures trust in its
            records of utilization and distribution, operable through an open
            cooperative system open to all participants.
          </div>
          <div
            className={styles.healthcareDataDemands}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Healthcare data demands a compensation distribution protocol
            designed to foster sustainable collaboration among involved
            stakeholders.
          </div>
          <img className={styles.image17Icon} alt="" src="/image-17@2x.png" />
          <img className={styles.image18Icon} alt="" src="/image-18@2x.png" />
          <div
            className={styles.theUseOf}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            The use of Decentralized Identifier (DID) and Verifiable Credentials
            (VC) technologies empowers individuals to exercise self-sovereignty
            over their medical data, extending beyond mere personal
            identification.
          </div>
          <div
            className={styles.theHippocratWallet}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            The Hippocrat Wallet SDK, built on proven technology, enables users
            to access their assets and data in the developed wallet through a
            range of applications.
          </div>
          <div
            className={styles.utilizingTheHighly}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Utilizing the highly decentralized and secure Bitcoin layer, we
            ensure the utmost security for your valuable health data.
          </div>
          <div className={styles.leveragingTheBitcoinContainer}>
            <span className={styles.leveragingTheBitcoinContainer1}>
              <p
                className={styles.leveraging}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`Leveraging `}</p>
              <p className={styles.leveraging}>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  the Bitcoin
                </span>
                <i className={styles.o}> Layer</i>
              </p>
            </span>
          </div>
          <div className={styles.implementingDidContainer}>
            <span className={styles.leveragingTheBitcoinContainer1}>
              <p
                className={styles.leveraging}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`Implementing `}</p>
              <p className={styles.didVc}>
                <i>{`DID & VC`}</i>
              </p>
            </span>
          </div>
          <div className={styles.providingTheHippocratContainer}>
            <span className={styles.leveragingTheBitcoinContainer1}>
              <p
                className={styles.leveraging}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`Providing the `}</p>
              <p className={styles.leveraging}>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  Hippo
                </span>
                <i className={styles.o}>{`crat `}</i>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                  className={styles.wallet}
                >{`Wallet `}</span>
                <i className={styles.o}>SDK</i>
              </p>
            </span>
          </div>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/protocol/first.png"
          />
          <img
            className={styles.maskGroupIcon1}
            alt=""
            src="/protocol/second.png"
          />
          <img
            className={styles.maskGroupIcon2}
            alt=""
            src="/protocol/third.png"
          />

          <img
            className={styles.maskGroupIcon3}
            alt=""
            src="/protocol/sun.png"
          />
          <img
            className={styles.maskGroupIcon6}
            alt=""
            src="/protocol/ob8.png"
          />
          <img
            className={styles.maskGroupIcon4}
            alt=""
            src="/protocol/coinLayer.png"
          />
        </div>
      </div>
    </>
  );
};

export default MProtocolPage;
