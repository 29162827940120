import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './loadingAnimation.module.scss';

type LoadingAnimationProps = {
  isLoading: boolean;
};

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({ isLoading }) => {
  const [isExiting, setIsExiting] = useState(false);
  useEffect(() => {
    if (!isLoading) {
      setIsExiting(true);
    }
  }, [isLoading]);

  return (
    <AnimatePresence>
      {!isExiting && (
        <motion.div
          className={styles.loadingContainer}
          initial={{ opacity: 1 }}
          exit={{
            opacity: 1,
            transition: { duration: 3, when: 'afterChildren' },
          }}
        >
          <motion.div
            className={styles.middleCircle}
            initial={{ scale: 1 }}
            exit={{ scale: 0, transition: { duration: 0.5, delay: 0.3 } }}
          >
            <motion.div
              className={styles.innerCircle}
              initial={{ scale: 1 }}
              exit={{ scale: 0, transition: { duration: 0.3, delay: 0.3 } }}
            >
              <motion.img
                src="/pressKit/monoLogoWhite.png"
                alt="Loading"
                className={styles.logo}
                initial={{ opacity: 0.5 }}
                animate={{ opacity: 1 }}
                transition={{
                  repeat: Infinity,
                  repeatType: 'reverse',
                  duration: 0.5,
                }}
              />
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LoadingAnimation;
