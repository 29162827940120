import React, { useState } from 'react';
import styles from './mIndex.module.scss';
import Accordion from '../../components/Accordion';

const MFaqPage = () => {
  const [openAccordion, setOpenAccordion] = useState<string | null>(null);

  const handleAccordionClick = (title: string) => {
    if (openAccordion === title) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(title);
    }
  };

  return (
    <>
      <div className="only500"></div>
      <div className="layout500">
        <div className={styles.faq500}>
          <div className={styles.faq} style={{ lineHeight: '32px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              FA
            </span>
            <i className={styles.q}>Q</i>
          </div>
          <div className={styles.frequentlyAskedQuestions}>
            Frequently asked questions
          </div>

          <div className={styles.faqListWrap}>
            <div className={styles.faqList}>
              <Accordion
                title="What is Hippocrat?"
                isOpen={openAccordion === 'accordion1'}
                onClick={() => handleAccordionClick('accordion1')}
              >
                <p>{`Hippocrat is a Cooperative Protocol for health data collection and utilization, aiming to enhance healthcare data’s sovereignty, openness, and decentralized usage. Named after the ancient Greek physician Hippocrates, Hippocrat seeks to empower individuals and healthcare providers to seamlessly collaborate, ensuring data privacy, building trust, and facilitating innovation in healthcare through personalized services and data-driven decisions.`}</p>
              </Accordion>
              <Accordion
                title="What can i do with hippocrat?"
                isOpen={openAccordion === 'accordion2'}
                onClick={() => handleAccordionClick('accordion2')}
              >
                <p>
                  Patients
                  <br />
                  - Securely manage your own healthcare data and digital assets
                  collected from varios institutions
                  <br />
                  <br />
                  Medical Institutions
                  <br />
                  - Make proofs of your contributions on generation and
                  cleansing one’s data even after it taken out form your
                  institution
                  <br />
                  <br />
                  Healthcare services
                  <br />- Gain informed consent from data subjects and provide
                  incentives to them so that you can efficiently get and utilize
                  their data
                </p>
              </Accordion>
              <Accordion
                title="When HPO token is needed?"
                isOpen={openAccordion === 'accordion3'}
                onClick={() => handleAccordionClick('accordion3')}
              >
                <p>{`HPO token is utilized as an incentive in Hippocrat ecosystem. It is used as fair and transparent reward for each participant whenever data is stored, managed, and utilized, contributing to the construction of a sustainable ecosystem.`}</p>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <div className="only360"></div>
      <div className="layout360">
        <div className={styles.faq500}>
          <div className={styles.faq} style={{ lineHeight: '25px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              FA
            </span>
            <i className={styles.q}>Q</i>
          </div>
          <div className={styles.frequentlyAskedQuestions}>
            Frequently asked questions
          </div>

          <div className={styles.faqListWrap}>
            <div className={styles.faqList}>
              <Accordion
                title="What is Hippocrat?"
                isOpen={openAccordion === 'accordion1'}
                onClick={() => handleAccordionClick('accordion1')}
              >
                <p>{`Hippocrat is a Cooperative Protocol for health data collection and utilization, aiming to enhance healthcare data’s sovereignty, openness, and decentralized usage. Named after the ancient Greek physician Hippocrates, Hippocrat seeks to empower individuals and healthcare providers to seamlessly collaborate, ensuring data privacy, building trust, and facilitating innovation in healthcare through personalized services and data-driven decisions.`}</p>
              </Accordion>
              <Accordion
                title="What can i do with hippocrat?"
                isOpen={openAccordion === 'accordion2'}
                onClick={() => handleAccordionClick('accordion2')}
              >
                <p>
                  Patients
                  <br />
                  - Securely manage your own healthcare data and digital assets
                  collected from varios institutions
                  <br />
                  <br />
                  Medical Institutions
                  <br />
                  - Make proofs of your contributions on generation and
                  cleansing one’s data even after it taken out form your
                  institution
                  <br />
                  <br />
                  Healthcare services
                  <br />- Gain informed consent from data subjects and provide
                  incentives to them so that you can efficiently get and utilize
                  their data
                </p>
              </Accordion>
              <Accordion
                title="When HPO token is needed?"
                isOpen={openAccordion === 'accordion3'}
                onClick={() => handleAccordionClick('accordion3')}
              >
                <p>{`HPO token is utilized as an incentive in Hippocrat ecosystem. It is used as fair and transparent reward for each participant whenever data is stored, managed, and utilized, contributing to the construction of a sustainable ecosystem.`}</p>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MFaqPage;
