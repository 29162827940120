import styles from './mIndex.module.scss';
import VideoComponent from '../../components/VideoOnReady';

interface RoadMap {
  title?: string;
  year?: string;
  quarter?: string;
  subTitle?: string[];
}

interface MPageProps {
  handleVideoLoaded: (videoNumber: number, player: any) => void;
}

const MRoadMapPage: React.FC<MPageProps> = ({ handleVideoLoaded }) => {
  const roadMapData: RoadMap[] = [
    {
      title: 'Hippocrat Wallet SDK open-source draft released.',
      year: '2023',
      quarter: '2Q',
    },
    {
      title:
        'Proof of Concept (PoC) project using Hippocrat Wallet SDK released.',
      year: '2023',
      quarter: '2Q',
    },
    {
      title: 'Hard fork from HUM to HPO and token distribution initiated.',
      year: '2023',
      quarter: '2Q',
    },
    {
      title: 'Launch of new Hippocrat DAO operating system.',
      year: '2023',
      quarter: '2Q',
    },
    {
      title: 'Beta version of Hippocrat Wallet SDK released.',
      year: '2023',
      quarter: '3Q',
    },
    {
      title: 'Applications using Hippocrat Wallet SDK released.',
      year: '2023',
      quarter: '3Q',
    },
    {
      title: 'Release of Lightning Network implementation.',
      year: '2023',
      quarter: '3Q',
    },
    {
      title: 'Hippocrat DAO Conference took place.',
      year: '2023',
      quarter: '4Q',
    },
    {
      title: 'Hippocrat Wallet integration reference service released.',
      year: '2023',
      quarter: '4Q',
    },
    {
      title:
        'PoC project results for Hippocrat Wallet utilization released, which includes:',
      year: '2023',
      quarter: '4Q',
      subTitle: [
        'Patient to hospital identity registration.',
        'Hospital to patient data issuance.',
        'Patient sharing data with data-using organizations.',
      ],
    },
    {
      title:
        'Deployment of standard policy and contract testnet for data transactions.',
      year: '2024',
      quarter: '1Q',
    },
    {
      title: 'Organization of a hackathon program to activate the ecosystem.',
      year: '2024',
      quarter: '1Q',
    },
    {
      title:
        'Disclosure of PoC project plan using Hippocrat Wallet SDK, in collaboration with global institutions.',
      year: '2024',
      quarter: '1Q',
    },
    {
      title: 'Release of Hippocrat Wallet SDK v1.',
      year: '2024',
      quarter: '2Q',
    },
    {
      title:
        'Deployment of standard policy and contract mainnet for data transactions.',
      year: '2024',
      quarter: '2Q',
    },
    {
      title: 'Launch of Hippocrat DAO global expansion event.',
      year: '2024',
      quarter: '3Q',
    },
    {
      title: 'Implementation of a grant program to stimulate the ecosystem.',
      year: '2024',
      quarter: '3Q',
    },
    {
      title: 'Onboarding of 5 services integrated with Hippocrat Wallet SDK.',
      year: '2024',
      quarter: '3Q',
    },
    {
      title:
        'Onboarding of 10 organizations integrated with Hippocrat Wallet SDK.',
      year: '2024',
      quarter: '3Q',
    },
    {
      title: 'Hippocrat DAO Global Conference held.',
      year: '2024',
      quarter: '4Q',
    },
    {
      title:
        'Stabilization of the decentralized autonomous operating system of all Special Interest Groups and Working Groups in the DAO.',
      year: '2024',
      quarter: '4Q',
    },
    {
      title: 'Transfer of all authority from Hippocrat Foundation to DAO.',
      year: '2024',
      quarter: '4Q',
    },
    {
      title: 'Onboarding of 10 Hippocrat Wallet integrations.',
      year: '2024',
      quarter: '4Q',
    },
    {
      title:
        'Onboarding of 20 Hippocrat Wallet integrations with organizations.',
      year: '2024',
      quarter: '4Q',
    },
    {
      title:
        'Release of PoC project deliverables for Hippocrat Wallet utilization, in collaboration with global institutions.',
      year: '2024',
      quarter: '4Q',
    },
  ];

  return (
    <>
      <div className="only500">
        <div>
          <VideoComponent
            key={3}
            src="/roadMap/mRoadMap.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={3}
            className="basic-video-img"
          />
        </div>
        <div
          className="basic-text-500"
          style={{
            marginTop: 'calc(50 * var(--vh) - 350px)',
          }}
        >
          <div className={styles.ourPioneerJourney}>Our Pioneer Journey</div>
          <div className={styles.roadMap} style={{ lineHeight: '32px' }}>
            <span>
              <span
                className={styles.r}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                R
              </span>
              <span
                className={styles.o}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                O
              </span>
            </span>
            <span className={styles.r}>
              <i className={styles.ad}>AD</i>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                {' '}
                MAP
              </span>
            </span>
          </div>
          <div
            className={styles.theJourneyOf}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            The journey of Hippocrat never concludes until we rationally address
            the challenges of conventional healthcare. We will consistently
            pioneer a patient-centric approach, propose solutions, and work
            towards making the ecosystem transparent and secure. While the
            journey is ongoing, let's examine the stage at which we are
            currently spearheading innovation, together with you.
          </div>
          <b className={styles.joinTheJourneyContainer}>
            <p className={styles.joinTheJourney}>
              Join the journey of healthcare revolution with Hippocrat
            </p>
          </b>
        </div>
      </div>
      <div className="layout500">
        <div className={styles.roadmap500}>
          <div className={styles.roadmapWrapper}>
            {/* roadMapData */}
            {roadMapData.map((map, i) => (
              <div className={styles.roadmapBox} key={i}>
                <img src="/roadMap/mapBg.png" alt="" />
                <div>{map?.year}</div>
                <b>{map?.quarter}</b>
                <p>{map?.title}</p>
                {map?.subTitle?.map((sub, j) => <li key={j}>{sub}</li>)}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="only360">
        <div>
          <VideoComponent
            key={4}
            src="/roadMap/mRoadMap.mp4"
            onReady={(videoNumber, player) =>
              handleVideoLoaded(videoNumber, player)
            }
            videoNumber={4}
            className="basic-video-img"
          />
        </div>
        <div
          className="basic-text-360"
          style={{
            marginTop: 'calc(50 * var(--vh) - 350px)',
          }}
        >
          <div className={styles.ourPioneerJourney}>Our Pioneer Journey</div>
          <div
            className={styles.roadMap}
            style={{ lineHeight: '32px', fontSize: '35px' }}
          >
            <span>
              <span
                className={styles.r}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                R
              </span>
              <span
                className={styles.o}
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                O
              </span>
            </span>
            <span className={styles.r}>
              <i className={styles.ad}>AD</i>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                {' '}
                MAP
              </span>
            </span>
          </div>
          <div
            className={styles.theJourneyOf}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            The journey of Hippocrat never concludes until we rationally address
            the challenges of conventional healthcare. We will consistently
            pioneer a patient-centric approach, propose solutions, and work
            towards making the ecosystem transparent and secure. While the
            journey is ongoing, let's examine the stage at which we are
            currently spearheading innovation, together with you.
          </div>
          <b className={styles.joinTheJourneyContainer}>
            <p className={styles.joinTheJourney}>
              Join the journey of healthcare revolution with Hippocrat
            </p>
          </b>
        </div>
      </div>
      <div className="layout360">
        <div className={styles.roadmap500}>
          <div className={styles.roadmapWrapper}>
            {/* roadMapData */}
            {roadMapData.map((map, i) => (
              <div className={styles.roadmapBox} key={i}>
                <img src="/roadMap/mapBg.png" alt="" />
                <div>{map?.year}</div>
                <b>{map?.quarter}</b>
                <p>{map?.title}</p>
                {map?.subTitle?.map((sub, j) => <li key={j}>{sub}</li>)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MRoadMapPage;
