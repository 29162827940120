import { useState } from 'react';
import styles from './news.module.scss';

interface Article {
  title?: string;
  image?: string;
  date?: string;
  url?: string;
}

const NewsPage = () => {
  // add articles here
  const articles: Article[] = [
    {
      title:
        'SolveCare Korea and Hippocrat Forge a Strategic Partnership to Transform Healthcare Data Management Using Blockchain Technology',
      image:
        'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*O5LbvtMbt6txOCCcmsXoRw.jpeg',
      url: 'https://medium.com/hippocrat/solvcare-korea-and-hippocrat-forge-a-strategic-partnership-to-transform-healthcare-data-management-b1e9390b84fb',
      date: 'MAR 28, 2024',
    },
    {
      title:
        'Hippocrat and Saluscare forge strategic business agreement to expand ‘HPO token’ use',
      image:
        'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*0Xvt3kr926vi1B8QFstcNQ.jpeg',
      url: 'https://medium.com/hippocrat/hippocrat-and-saluscare-forge-strategic-business-agreement-to-expand-hpo-token-use-02cd118d9a53',
      date: 'Mar 7, 2024',
    },
    {
      title:
        'Workshop on DeSci & Healthcare Innovation Hosted by Hippocrat at St. Mary’s Hospital',
      image:
        'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*N5-yzqViSQ1f_1tRLdNZFQ.jpeg',
      url: 'https://medium.com/hippocrat/workshop-on-desci-virtual-healthcare-innovation-hosted-by-hippocrat-at-st-marys-hospital-b898781f9ae8',
      date: 'Mar 6, 2024',
    },
    {
      title: 'Hippocrat & Conflux Network’s Strategic Partnership Announcement',
      image:
        'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*HXXj2bzi85PaTTYJDdRGuw.jpeg',
      url: 'https://medium.com/hippocrat/hippocrat-conflux-networks-strategic-partnership-announcement-1fbd85825b1a',
      date: 'Feb 29, 2024',
    },
    {
      title: 'Hippocrat Brand Guide 1.0 Version Released!',
      image:
        'https://app.circle.so/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBCR0F6Q1FJPSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--3ba1265e3f4e9d90b4d1462d1c45a2a31fcff110/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDRG9MWm05eWJXRjBTU0lJY0c1bkJqb0dSVlE2RkhKbGMybDZaVjkwYjE5c2FXMXBkRnNITUdrQ09BUTZDbk5oZG1WeWV3WTZDbk4wY21sd1ZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--cfda350175ba87e768b4e96e935a8171fc679bec/01%20(2).png',
      url: 'https://community.hippocrat.io/c/news/hippocrat-brand-guide-1-0-version-released',
      date: 'JAN 8, 2024',
    },
    {
      title: 'TBW 2023 Wrap up',
      image:
        'https://app.circle.so/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBCR21JQWdJPSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--00c604bf7e81f2b55819f2ab262ee76e5daa7570/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDRG9MWm05eWJXRjBTU0lJY0c1bkJqb0dSVlE2RkhKbGMybDZaVjkwYjE5c2FXMXBkRnNITUdrQ09BUTZDbk5oZG1WeWV3WTZDbk4wY21sd1ZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--cfda350175ba87e768b4e96e935a8171fc679bec/Screen%20Shot%202023-12-24%20at%208.11.00%20PM.png',
      url: 'https://community.hippocrat.io/c/news/tbw-2023-wrap-up',
      date: 'DEC 3, 2023',
    },
    {
      title: 'Hippocrat and PANONY Announce Strategic Partnership!',
      image: 'https://miro.medium.com/v2/resize:fit:640/0*lo5FDm2nSuFh8ny4',
      url: 'https://medium.com/hippocrat/hippocrat-and-panony-announce-strategic-partnership-911dc91af074',
      date: 'DEC 5, 2023',
    },
    {
      title: 'Hippocrat X zkMe Partnership',
      image:
        'https://app.circle.so/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBCR2l1eEFFPSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--03a36bd9a3e7f28debdc20ea3087c6168d37987d/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDRG9MWm05eWJXRjBTU0lJYW5CbkJqb0dSVlE2RkhKbGMybDZaVjkwYjE5c2FXMXBkRnNITUdrQ09BUTZDbk5oZG1WeWV3WTZDbk4wY21sd1ZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--f8bccb7301f19e5a736f208862c40c1e1510dbb1/ZKME.jpg',
      url: 'https://community.hippocrat.io/c/news/hippocrat-x-zkme-partnership-c6cc3a',
      date: 'NOV 02, 2023',
    },
  ];

  const [currentPage, setCurrentPage] = useState<number>(1);
  const articlesPerPage = 6;
  const totalArticles = articles.length;

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(
    indexOfFirstArticle,
    indexOfLastArticle,
  );

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalArticles / articlesPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <section className="layoutContainer">
      <div className="only1000">
        <div className={styles.newsChild} />
      </div>
      <div className="layout1000">
        <div className={styles.news}>
          <div className={styles.news1}>
            <span>NE</span>
            <i className={styles.ws}>WS</i>
          </div>
          <div
            className={styles.announcementPress}
          >{`ANNOUNCEMENT & PRESS`}</div>
          <div className={styles.exploreTheWorld}>
            Explore the world of Web3 blockchain in healthcare data through more
            news about Hippo! Check out the latest updates.
          </div>

          {/* article */}
          {currentArticles.map((article, index) => (
            <a key={index} href={article?.url} target="_blank" rel="noreferrer">
              <div className={styles?.[`newsImg${index}`]}>
                <img className="basic-news-img" alt="" src={article?.image} />
              </div>
              <b className={styles?.[`newsTitle${index}`]}>
                <p className={styles.nowToSpot}>{article?.title}</p>
              </b>
              <div className={styles?.[`newsDate${index}`]}>
                {article?.date}
              </div>
            </a>
          ))}

          {/* pagination */}
          <div
            className={`${styles.parent} ${
              currentArticles?.length < 4 ? styles.parentLow : ''
            }`}
          >
            {pageNumbers.map(number => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={`${currentPage === number ? styles.active : ''}`}
              >
                {number}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="only800">
        <div className={styles.news800Child} />
      </div>
      <div className="layout800">
        <div className={styles.news800}>
          <div className={styles.news}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              NE
            </span>
            <i className={styles.ws}>WS</i>
          </div>
          <div
            className={styles.announcementPress}
          >{`ANNOUNCEMENT & PRESS`}</div>
          <div
            className={styles.exploreTheWorld}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Explore the world of Web3 blockchain in healthcare data through more
            news about Hippo! Check out the latest updates.
          </div>

          {/* article */}
          {currentArticles.map((article, index) => (
            <a key={index} href={article?.url} target="_blank" rel="noreferrer">
              <div className={styles?.[`newsImg${index}`]}>
                <img className="basic-news-img" alt="" src={article?.image} />
              </div>
              <b className={styles?.[`newsTitle${index}`]}>
                <p className={styles.nowToSpot}>{article?.title}</p>
              </b>
              <div className={styles?.[`newsDate${index}`]}>
                {article?.date}
              </div>
            </a>
          ))}

          {/* pagination */}
          <div
            className={`${styles.parent} ${
              currentArticles?.length < 4 ? styles.parentLow : ''
            }`}
          >
            {pageNumbers.map(number => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={`${currentPage === number ? styles.active : ''}`}
              >
                {number}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="only500">
        <div className={styles.news500Child} />
      </div>
      <div className="layout500">
        <div className={styles.news500}>
          <div className={styles.news} style={{ lineHeight: '32px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              NE
            </span>
            <i className={styles.ws}>WS</i>
          </div>
          <div
            className={styles.announcementPress}
          >{`ANNOUNCEMENT & PRESS`}</div>
          <div
            className={styles.exploreTheWorld}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Explore the world of Web3 blockchain in healthcare data through more
            news about Hippo! Check out the latest updates.
          </div>

          {/* article */}
          <div className={styles.newsWrapper}>
            {currentArticles.map((article, index) => (
              <a
                key={index}
                href={article?.url}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.newsImg}>
                  <img className="basic-news-img" alt="" src={article?.image} />
                </div>
                <div className={styles.newsDate}>{article?.date}</div>
                <div className={styles.newsTitle}>{article?.title}</div>
              </a>
            ))}
          </div>

          {/* pagination */}
          <div className={styles.parent}>
            {pageNumbers.map(number => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={`${currentPage === number ? styles.active : ''}`}
              >
                {number}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="only360">
        <div className={styles.news360Child} />
      </div>
      <div className="layout360">
        <div className={styles.news360}>
          <div className={styles.news} style={{ lineHeight: '25px' }}>
            <span
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              NE
            </span>
            <i className={styles.ws}>WS</i>
          </div>
          <div
            className={styles.announcementPress}
          >{`ANNOUNCEMENT & PRESS`}</div>
          <div
            className={styles.exploreTheWorld}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Explore the world of Web3 blockchain in healthcare data through more
            news about Hippo! Check out the latest updates.
          </div>

          {/* article */}
          <div className={styles.newsWrapper}>
            {currentArticles.map((article, index) => (
              <a
                key={index}
                href={article?.url}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.newsImg}>
                  <img className="basic-news-img" alt="" src={article?.image} />
                </div>
                <div className={styles.newsDate}>{article?.date}</div>
                <div className={styles.newsTitle}>{article?.title}</div>
              </a>
            ))}
          </div>

          {/* pagination */}
          <div className={styles.parent}>
            {pageNumbers.map(number => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={`${currentPage === number ? styles.active : ''}`}
              >
                {number}
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsPage;
