import styles from './mIndex.module.scss';

const MLearnHealthcareDataPage = () => {
  return (
    <>
      <div className="only500">
        <div className={styles.learn500Child} />
      </div>
      <div className="layout500">
        <div className={styles.learn500}>
          <div className={styles.hippocratIsTheContainer}>
            <p
              className={styles.hippocratIsThe}
              style={{ fontFamily: 'var(--font-inter)' }}
            >{`Hippocrat is the healthcare data project leveraging blockchain and web3 technology. `}</p>
            <p
              className={styles.hippocratIsThe}
              style={{ fontFamily: 'var(--font-inter)' }}
            >{`Patient can be incentivized with Hippocrat token for sharing his or her healthcare data. On the other hand, hospital can access to private healthcare data securely by paying Hippocrat to data owner. `}</p>
            <p className={styles.hippocratIsThe}>&nbsp;</p>
            <p
              className={styles.hippocratIsThe}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              Hippocrat DAO provides blockchain technology to exchange
              healthcare data in an efficient, interoperable and
              privacy-preserving way. Hippocrat Token provides incentive
              mechanism to sustain its ecosystem.
            </p>
          </div>
          <div className={styles.itHasBeenContainer}>
            <p
              className={styles.hippocratIsThe}
              style={{ fontFamily: 'var(--font-inter)' }}
            >{`It has been a practice that trusted third party takes all the sovereignty of patient’s healthcare data. It has been an obstacle for every stakeholder in healthcare industry. On the patient’s side, patient cannot own and share his or her data to get better medical experience or even make revenue. `}</p>
            <p className={styles.hippocratIsThe}>&nbsp;</p>
            <p
              className={styles.hippocratIsThe}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              On the hospital’s side, it is harder to diagnose properly as it
              cannot easily access to patient’s data stored in the other trusted
              third party. On the pharmaceutical company’s side, it is nearly
              impossible to integrate patient’s data fragmented across multiple
              entities. Hippocrat is to revolutionize this situation by
              integrating healthcare data into one interoperable blockchain
              platform.
            </p>
          </div>
          <b className={styles.howToAchieveContainer}>
            <p className={styles.hippocratIsThe}>{`How to achieve `}</p>
            <p className={styles.hippocratIsThe}>{`self-sovereignty in `}</p>
            <p className={styles.hippocratIsThe}>healthcare data</p>
          </b>
          <b className={styles.whyHealthcareData}>Why Healthcare data?</b>

          <div
            className={styles.visitingHospitalAnd}
            style={{ fontFamily: 'var(--font-inter)' }}
          >
            Visiting hospital and getting clinic service generates your personal
            healthcare data automatically. However, it’s so complicated to
            access our data or history in healthcare field, or even not allowed
            when you are looking for. It’s as absurd as you are not allowed to
            enter our own home - your healthcare data is legitimate property
            like your house. Owner must fully own the right of which belongs to
            him or her, which is self-sovereignty by definition.
          </div>
          <b className={styles.whatIsSelfSovereigntyContainer}>
            <p className={styles.hippocratIsThe}>{`What is `}</p>
            <p className={styles.hippocratIsThe}>self-sovereignty?</p>
          </b>
          <div className={styles.selfSovereignHealthcareDatContainer}>
            <p className={styles.hippocratIsThe} style={{ lineHeight: '32px' }}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
                className={styles.se}
              >
                Se
              </span>
              <b className={styles.lf}>lf</b>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`-Sovereign `}</span>
            </p>
            <p className={styles.healthcareData1}>
              <i>Healthcare</i>
              <span className={styles.se}>{` `}</span>
              <i className={styles.lf}>Data</i>
            </p>
          </div>
          <div className={styles.theComingFuture}>The coming future</div>
          <div className={styles.learn500Item}>
            <img
              className={styles.learn500Img}
              alt=""
              src="/learn/mhealthcareData.png"
              style={{ pointerEvents: 'none' }}
            />
          </div>
          <div className={styles.learn500Inner} />
          <a href="/learn/hl7">
            <div className={styles.nextUseCaseContainer}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                NE
              </span>
              <b>X</b>
              <b className={styles.lf}>T</b>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                {' '}
                USE
              </span>
              <i className={styles.lf}> CASE</i>
            </div>
            <b className={styles.healthLevel7}>Health level 7 : HL7</b>
            <img
              className={styles.chevronLeftIcon}
              alt=""
              src="/learn/chevronleft@2x.png"
            />
          </a>
        </div>
      </div>
      <div className="only360">
        <div className={styles.learn360Child} />
      </div>
      <div className="layout360">
        <div className={styles.learn360}>
          <div className={styles.hippocratIsTheContainer}>
            <p
              className={styles.hippocratIsThe}
            >{`Hippocrat is the healthcare data project leveraging blockchain and web3 technology. `}</p>
            <p
              className={styles.hippocratIsThe}
            >{`Patient can be incentivized with Hippocrat token for sharing his or her healthcare data. On the other hand, hospital can access to private healthcare data securely by paying Hippocrat to data owner. `}</p>
            <p className={styles.hippocratIsThe}>&nbsp;</p>
            <p className={styles.hippocratIsThe}>
              Hippocrat DAO provides blockchain technology to exchange
              healthcare data in an efficient, interoperable and
              privacy-preserving way. Hippocrat Token provides incentive
              mechanism to sustain its ecosystem.
            </p>
          </div>
          <div className={styles.itHasBeenContainer}>
            <p
              className={styles.hippocratIsThe}
            >{`It has been a practice that trusted third party takes all the sovereignty of patient’s healthcare data. It has been an obstacle for every stakeholder in healthcare industry. On the patient’s side, patient cannot own and share his or her data to get better medical experience or even make revenue. `}</p>
            <p className={styles.hippocratIsThe}>&nbsp;</p>
            <p className={styles.hippocratIsThe}>
              On the hospital’s side, it is harder to diagnose properly as it
              cannot easily access to patient’s data stored in the other trusted
              third party. On the pharmaceutical company’s side, it is nearly
              impossible to integrate patient’s data fragmented across multiple
              entities. Hippocrat is to revolutionize this situation by
              integrating healthcare data into one interoperable blockchain
              platform.
            </p>
          </div>
          <b className={styles.howToAchieve}>
            How to achieve self-sovereignty in healthcare data
          </b>
          <b className={styles.whyHealthcareData}>Why Healthcare data?</b>

          <div className={styles.visitingHospitalAnd}>
            Visiting hospital and getting clinic service generates your personal
            healthcare data automatically. However, it’s so complicated to
            access our data or history in healthcare field, or even not allowed
            when you are looking for. It’s as absurd as you are not allowed to
            enter our own home - your healthcare data is legitimate property
            like your house. Owner must fully own the right of which belongs to
            him or her, which is self-sovereignty by definition.
          </div>
          <b className={styles.whatIsSelfSovereigntyContainer}>
            <p className={styles.hippocratIsThe}>{`What is `}</p>
            <p className={styles.hippocratIsThe}>self-sovereignty?</p>
          </b>
          <div className={styles.selfSovereignHealthcareDatContainer}>
            <p className={styles.hippocratIsThe} style={{ lineHeight: '25px' }}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
                className={styles.se}
              >
                Se
              </span>
              <b className={styles.lf}>lf</b>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`-Sovereign `}</span>
            </p>
            <p className={styles.healthcareData}>
              <i>Healthcare</i>
              <span className={styles.se}>{` `}</span>
              <i className={styles.lf}>Data</i>
            </p>
          </div>
          <div className={styles.theComingFuture}>The coming future</div>
          <div className={styles.learn360Item} />
          <a href="/learn/hl7">
            <div className={styles.nextUseCaseContainer}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                NE
              </span>
              <b>X</b>
              <b className={styles.lf}>T</b>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                {' '}
                USE
              </span>
              <i className={styles.lf}> CASE</i>
            </div>
            <b className={styles.healthLevel7}>Health level 7 : HL7</b>
            <img
              className={styles.chevronLeftIcon}
              alt=""
              src="/learn/chevronleft@2x.png"
            />
          </a>
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="/learn/mhealthcareData.png"
            style={{ pointerEvents: 'none' }}
          />
        </div>
      </div>
    </>
  );
};

export default MLearnHealthcareDataPage;
